import BreadCrumb from "@/components/BreadCrumb";
import LiveList from "@/components/LiveList";
import { useLocation } from "react-router-dom";
function AllLive() {
  const {
    state: { showObj },
  } = useLocation();
  console.log(showObj,9999)
  return (
    <div className="information-content-outwrap">
      <BreadCrumb />
      {/* 内容 */}
      <div className="content">
        <LiveList {...showObj}></LiveList>
      </div>
    </div>
  );
}
export default AllLive;