import React, { useState, useEffect } from "react";
import { handleBread } from "@/utils/bread";
import { useNavigate } from "react-router-dom";
import userStore from "@/store/user";
function ActivityGoodsList(props: any) {
  const { info, orderId, activityId } = props;
  const navigate = useNavigate();
  const goToDetail = (item: any) => {
    userStore.setSupplierId(item.supplierId || "");
    item.supplierId
      ? handleBread({
          title: `${item.name}`,
          href: `/courseDetail?id=${item.commodityId}&supplierId=${item.supplierId}&orderId=${orderId}&activityId=${activityId}`,
        })
      : handleBread({
          title: `${item.name}`,
          href: `/courseDetail?id=${item.commodityId}&orderId=${orderId}&activityId=${activityId}`,
        });

    item.supplierId
      ? navigate(
          `/courseDetail?id=${item.commodityId}&supplierId=${item.supplierId}&orderId=${orderId}&activityId=${activityId}`
        )
      : navigate(
          `/courseDetail?id=${item.commodityId}&orderId=${orderId}&activityId=${activityId}`
        );
  };
  return (
    <div className="activity-page-goods">
      {info?.commodityList?.length > 0 &&
        info?.commodityList?.map((item: any, indexZ: any) => {
          return (
            <li
              className="allcourse-item"
              key={indexZ}
              onClick={() => goToDetail(item)}
            >
              <div className="item-imgs">
                <img src={item.thumbnail} alt="" />
                <div className="sellingnumber">
                  {item.salesVolume ? item.salesVolume : 0}人已关注
                </div>
              </div>
              <div className="goods-card-bottom">
                <div className="item-name">{item.commodityName}</div>
                <div className="item-sellpoint line-one">
                  {item.sellingPoint}
                </div>
                <div className="item-bottom">
                  <div className="item-teacherList">
                    {item.teachers
                      ? item.teachers.map((info: any, index: number) => {
                          return index < 3 ? (
                            <div className="teacher-item">
                              <img src={info.photo} alt="" />
                              <p>{info.name}</p>
                            </div>
                          ) : null;
                        })
                      : null}
                  </div>
                  <div className="item-pricebox">
                    {item.differencePrice == 1 ? (
                      <div className="add-level-type-0">
                        {info.type == 7 ? "补差升购" : "补差换购"}
                      </div>
                    ) : (
                      <div className="add-level-type-1">
                        {info.type == 7 ? "升购价" : "换购价"}
                      </div>
                    )}
                    {item.differencePrice == 1 ? (
                      <div className="add-level-type-0-num">
                        您已购买 ￥ {info.orderPayAmount}
                      </div>
                    ) : null}
                    <div className="bottom-line-real">
                      <p className="item-lineprice">
                        <span className="lineprice-icon">￥</span>
                        {item.price}
                      </p>
                      <p className="item-realPrice">
                        <span className="price-icon">￥</span>
                        {item.amount > 0 ? item.amount : 0.0}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </li>
          );
        })}
    </div>
  );
}
export default ActivityGoodsList;
