import FourInLine from "./FourInLine";
import TwoInLine from "./TwoInLine";
import HorizontalScroll from "./HorizontalScroll";
import PlainList from "./PlainList";
import "./css/index.scss";
interface Iprops {
  goShowMore?: any;
  projectList?: [];
  changeProject?: any;
  currentTab: number;
  goCourseDetail: any;
  courseCard: any;
  checkMoreByPro?: Function;
  mode: string;
  title: string;
  showMore: boolean;
  showNum: number;
  showByProject: boolean;
  showTitle: boolean;
  fields?: [];
}

const Goods = (props: Iprops) => {
  const {
    goShowMore,
    showMore,
    title,
    projectList,
    changeProject,
    currentTab,
    goCourseDetail,
    courseCard = [],
    checkMoreByPro,
    mode = "0",
    showNum,
    showByProject,
    showTitle,
    fields,
  } = props;
  const data = Object.assign({ projectList, currentTab, courseCard });
  const renderLayout = (val: string) => {
    switch (val) {
      case "0": // 大图模式
        return (
          <FourInLine
            data={data}
            showMore={showMore}
            title={title}
            showTitle={showTitle}
            showNum={showNum}
            fields={fields}
            showByProject={showByProject}
            changeProject={changeProject}
            goShowMore={goShowMore}
            checkMoreByPro={checkMoreByPro}
            goCourseDetail={goCourseDetail}
          />
        );
      case "1":
        // 2 列表模式
        return (
          <PlainList
            data={data}
            showMore={showMore}
            title={title}
            showTitle={showTitle}
            showNum={showNum}
            fields={fields}
            showByProject={showByProject}
            changeProject={changeProject}
            goShowMore={goShowMore}
            checkMoreByPro={checkMoreByPro}
            goCourseDetail={goCourseDetail}
          />
        );
      case "2": // 横向滑动
        return (
          <HorizontalScroll
            data={data}
            showMore={showMore}
            title={title}
            fields={fields}
            showTitle={showTitle}
            showNum={showNum}
            showByProject={showByProject}
            changeProject={changeProject}
            goShowMore={goShowMore}
            checkMoreByPro={checkMoreByPro}
            goCourseDetail={goCourseDetail}
          />
        );
      case "3": // 一行两个
        return (
          <TwoInLine
            data={data}
            showMore={showMore}
            title={title}
            fields={fields}
            showTitle={showTitle}
            showNum={showNum}
            showByProject={showByProject}
            changeProject={changeProject}
            goShowMore={goShowMore}
            checkMoreByPro={checkMoreByPro}
            goCourseDetail={goCourseDetail}
          />
        );
    }
  };
  return <>{renderLayout(mode)}</>;
};
export default Goods;
