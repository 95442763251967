import { request } from "@/utils/request";

// 查询商品正在进行的赠品活动详情
export const getGiftInfo = (data: any) => {
  return request({
    url: `/util/activity/gift`,
    method: "POST",
    data,
  });
};

// 查询商品正在进行的加价购活动详情
export const getAddBuyInfo = (data: any) => {
  return request({
    url: `util/activity/markup`,
    method: "POST",
    data,
  });
};

// 活动列表查询详情
export const getActivityListDetail = (params: any) => {
  return request({
    url: "/trade/v2/order/activity/detail",
    method: "GET",
    params,
  });
};

// 查询拼团
export const getGroupLateLy = (params: any) => {
  return request({
    url: "/util/activity/group/user",
    method: "GET",
    params,
  });
};
