import React, { useState, useEffect } from "react";
import { Col, Row } from "antd";
import "../css/index.scss";
import { useNavigate } from "react-router-dom";
const statusList = ["待付款", "已付款", "已关闭", "退款中", "已退款", "拼团中"]; 
const expressList = ["待发货", "待收货", "已完成"];
const CountDown: React.FC<{ remainder: number }> = ({ remainder }) => {
  let timer: any = null;
  const [timeData, setTimeData] = useState({
    hour: "0",
    minute: "0",
    second: "0",
  });
  const checkTime = (i: number) => {
    let s = i.toString();
    if (i < 10) {
      s = "0" + i;
    }
    return s;
  };
  const updateTimeData = (t: number) => {
    let hour = Math.floor(t / 1000 / 60 / 60);
    let minute = Math.floor((t / 1000 / 60) % 60);
    let second = Math.floor((t / 1000) % 60);
    setTimeData({
      hour: checkTime(hour),
      minute: checkTime(minute),
      second: checkTime(second),
    });
  };
  // 开启倒计时
  const startTimer = () => {
    if (timer) {
      clearInterval(timer);
    }
    if (remainder < 1000) {
      return;
    }
    let timeDown = remainder;
    timer = setInterval(() => {
      timeDown -= 1000;
      updateTimeData(timeDown);
      if (timeDown < 1000) {
        clearInterval(timer);
      }
    }, 1000);
  };
  useEffect(() => {
    updateTimeData(remainder);
    startTimer();
    return () => {
      if (timer) {
        clearInterval(timer);
      }
    };
  }, [remainder]);
  return (
    <div className="order-count-down">
      <div style={{ marginTop: "4px", marginBottom: "4px" }}>
        <span>{timeData.hour}:</span>
        <span>{timeData.minute}:</span>
        <span>{timeData.second}</span>
      </div>
      <p>后关闭</p>
    </div>
  );
};

const OrderDeatailItem: React.FC<{ commodityInfo: any }> = ({
  commodityInfo,
}) => {
  const navigate = useNavigate();
  //   let status = statusList[odrer.status];
  //   if (
  //     odrer.commodities &&
  //     odrer.commodities[0]?.type === 3 &&
  //     odrer.status === 1
  //   ) {
  //     status = expressList[odrer.expressStatus];
  //   }
  //   console.log('odrer',odrer);

  return (
    <div className="order-info-item" key={commodityInfo.id}>
      <Row>
        <Col className="order-info-border-right" span={12}>
          <div className="order-info-commodity">
            <img src={commodityInfo.url} className="order-info-img" />
            <div className="order-commodity-name">
              <div className="commodity-name">{commodityInfo.name}</div>
              <div className="commodity-skuName">
                {commodityInfo.skuValueName.replace(/,/g, " ")}
              </div>
            </div>
          </div>
        </Col>
        <Col className="order-info-border-right order-info-center gray" span={4}>
          ￥{commodityInfo.linePrice}
        </Col>
        <Col className="order-info-border-right order-info-center" span={4}>
          ￥{commodityInfo.realPrice}
        </Col>
        <Col className="order-info-center" span={4}>
          {commodityInfo.number}
        </Col>
      </Row>
    </div>
  );
};
export default OrderDeatailItem;
