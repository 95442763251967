import React, {
  useEffect,
  useState,
  forwardRef,
  useImperativeHandle,
} from "react";
import "../css/addPrice.scss";
// interface Iprops {
//   isShow: boolean; // 是否展示弹框
//   onClose: Function; // 关闭弹框回调
//   text: string;
// }

const AddPriceBuy = forwardRef((props: any, ref: any) => {
  const { info, getSelectAddPrice } = props;
  const [scrollPosition, setScrollPosition] = useState(0);
  const [selectIds, setSelectIds] = useState<any[]>([]);
  const handleScrollRight = () => {
    const element = document.getElementById("scroll-list");
    let x = element?.scrollWidth;
    if (x && x - scrollPosition > 472 * 2) {
      setScrollPosition(scrollPosition + 472);
    }
  };

  const handleScrollLeft = () => {
    if (scrollPosition === 0) {
      return;
    } else {
      setScrollPosition(scrollPosition - 472);
    }
    console.log(scrollPosition, "444444");
  };
  const clickCard = (cur: any) => {
    let result: any[] = [];
    if (info?.markupNumber == 0) {
      if (JSON.stringify(selectIds).includes(JSON.stringify(cur))) {
        console.log(1);

        result = selectIds.filter((item) => {
          return JSON.stringify(item) != JSON.stringify(cur);
        });
      } else {
        result = JSON.parse(JSON.stringify(selectIds));
        result.push(cur);
        console.log(2);
      }
    } else {
      if (JSON.stringify(selectIds).includes(JSON.stringify(cur))) {
        result = [];
        console.log(3);
      } else {
        result.push(cur);
        console.log(4);
      }
    }
    getSelectAddPrice(result || []);
    setSelectIds(result);
  };
  const clearSelect = () => {
    setSelectIds([]);
  };
  useImperativeHandle(ref, () => ({
    clearSelect,
  }));
  useEffect(() => {
    setScrollPosition(0);
  }, [info]);
  return (
    <div className="add-price-box">
      <div className="box-title-box flex">
        <div className="title-content">优惠加购</div>
        {info?.markupNumber == 0 ? (
          <div className="title-sub">(可选多件)</div>
        ) : null}
      </div>
      <div className="box-content-add">
        <div className="scroll-main-add">
          <div className="left-button-add" onClick={handleScrollLeft}>
            <i className="iconfont icon-symbol_left"></i>
          </div>
          <div className="right-button-add" onClick={handleScrollRight}>
            <i className="iconfont icon-symbol_right"></i>
          </div>
          <div
            className="scroll-list"
            id="scroll-list"
            style={{ transform: `translateX(-${scrollPosition}px)` }}
          >
            {info?.upgradeSkuData?.length > 0 &&
              info.upgradeSkuData.map((item: any, indexS: number) => {
                return (
                  <div
                    className={
                      JSON.stringify(selectIds).includes(
                        JSON.stringify({
                          commodityId: item.commodityId,
                          skuId: item.skuId,
                        })
                      )
                        ? "card-box card-select btn-cursor"
                        : "card-box btn-cursor"
                    }
                    onClick={() =>
                      clickCard({
                        commodityId: item.commodityId,
                        skuId: item.skuId,
                      })
                    }
                    key={indexS}
                  >
                    <img src={item?.thumbnail} className="left-img" />
                    <div className="right-card-add">
                      <div className="good-name line-one">
                        {item?.commodityName}
                      </div>

                      <div className="right-card-sellingPoint">
                        {item?.sellingPoint}
                      </div>

                      <div className="bottom-box-add">
                        <div className="price-left-add">
                          <div className="line-price-add">￥{item?.price}</div>
                          <div className="price-add flex">
                            <div className="price-add-icon">￥</div>
                            <div className="price-add-data">{item?.amount}</div>
                          </div>
                        </div>
                        <div
                          className={
                            JSON.stringify(selectIds).includes(
                              JSON.stringify({
                                commodityId: item.commodityId,
                                skuId: item.skuId,
                              })
                            )
                              ? "price-right-add-select"
                              : "price-right-add"
                          }
                        ></div>
                      </div>
                    </div>
                  </div>
                );
              })}
          </div>
        </div>
      </div>
    </div>
  );
});

export default AddPriceBuy;
