import { ENV_DEV, ENV_TEST, ENV_PROD } from "./const";

// 获取当前环境
export function getEnv() {
  console.log("process.env---", process.env);
  return process.env.REACT_APP_ENV;
}

// CDN地址
export function getCDNUrl() {
  const env = getEnv();
  if (env === ENV_DEV) {
    return "https://embryon-acc.wangxiao.net";
  } else if (env === ENV_TEST) {
    return "https://chestnut-acc.wangxiao.net";
  } else {
    return "https://acc.wangxiao.net";
  }
}

// cc的siteid
export function getSiteId() {
  const env = getEnv();
  if (env === ENV_DEV) {
    return "AC0DA9A36DE604E5";
  } else if (env === ENV_TEST) {
    return "AC0DA9A36DE604E5";
  } else {
    return "65D53B74AA1B77C4";
  }
}

// cc的url
export function getClientUrl() {
  const env = getEnv();
  if (env === ENV_DEV) {
    return "https://embryon-client.wangxiao.net";
  } else if (env === ENV_TEST) {
    return "https://chestnut-client.wangxiao.net";
  } else {
    return "https://client.wangxiao.net";
  }
}
