/*
 * @Author: liuweixing
 * @Date: 2024-05-13 10:03:09
 * @Description: 优惠券组件
 */
import TwoInLine from "./TwoInLine";
import HorizontalScroll from "./HorizontalScroll";
import { handleBread } from "@/utils/bread";
import { useNavigate } from "react-router-dom";
import "./css/index.scss";
import { useEffect, useState } from "react";
import { getCouponDeatilList } from "@/apis/coupon";
import { receiveCoupon } from "@/apis/courseDetail";
import { message } from "antd";
interface Iprops {
  showMore: Boolean;
  ids: any[];
  title: string;
  showTitle: Boolean;
  showNum: Number;
  hideLossEfficacy: Boolean;
  layout: Number;
  customStyle: any;
}
const Coupon = (props: Iprops) => {
  const {
    showMore,
    ids,
    title,
    showTitle,
    showNum,
    hideLossEfficacy,
    layout,
    customStyle,
  } = props;
  const navigate = useNavigate();
  const [messageApi, contextHolder] = message.useMessage();
  const [dataList, setDataList] = useState([]);
  const toShowMore = () => {
    handleBread({ title: `全部优惠券`, href: `/allCoupon/` });
    navigate(`/allCoupon/`, { state: { ids, hideLossEfficacy } });
  };
  useEffect(() => {
    ids?.length > 0 && getDataList(ids);
  }, [ids]);
  const getDataList = async (ids: any) => {
    const res = await getCouponDeatilList({
      couponIds: ids,
    });
    setDataList(res);
  };
  const success = () => {
    messageApi.open({
      type: "success",
      content: "领取优惠券成功",
    });
  };
  const handlerClick = async (
    id: string,
    viewStatus: any,
    usableRange: any
  ) => {
    if (viewStatus == 1) {
      navigate(`/allCourseCoupon/${usableRange}/${id}`);
    } else {
      console.log("领取-俩了", id);
      const res = await receiveCoupon(id);
      if (res) {
        success();
        getDataList(ids);
      }
    }
  };
  const mode = 1; // 0-横向滑动 1-一行两个
  const renderLayout = (val: any) => {
    switch (+val) {
      case 0: // 横向滑动
        return (
          <HorizontalScroll
            data={dataList}
            showMore={showMore}
            showTitle={showTitle}
            title={title}
            toShowMore={toShowMore}
            hideLossEfficacy={hideLossEfficacy}
            showNum={showNum}
            handlerClick={handlerClick}
            customStyle={customStyle}
          />
        );
      case 1: // 一行两个
        return (
          <TwoInLine
            data={dataList}
            showMore={showMore}
            showTitle={showTitle}
            title={title}
            toShowMore={toShowMore}
            hideLossEfficacy={hideLossEfficacy}
            showNum={showNum}
            handlerClick={handlerClick}
            customStyle={customStyle}
          />
        );
      default:
        // 横向滑动
        return (
          <HorizontalScroll
            data={dataList}
            showMore={showMore}
            showTitle={showTitle}
            title={title}
            toShowMore={toShowMore}
            hideLossEfficacy={hideLossEfficacy}
            showNum={showNum}
            handlerClick={handlerClick}
            customStyle={customStyle}
          />
        );
    }
  };
  return (
    <div className="setmeal-groups">
      {renderLayout(layout)} {contextHolder}
    </div>
  );
};
export default Coupon;
