/* eslint-disable  */
import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { Col, Row, message } from "antd";
import copy from "copy-to-clipboard";
import PayModal from "@/components/PayModal";
import OrderStatus from "./comps/OrderStatus";
import ActivityBanner from "./comps/ActivityBanner";
import { getOrderDetailV2, getOrderStatus, closeOrder } from "@/apis/order";
import { getGroupLateLy } from "@/apis/activity";
import OrderDeatailItem from "./comps/OrderDeatailItem";
import Gift from "../OrderSettlement/comps/Gift";
import GroupInvite from "../PaymentStatus/comps/GroupInvite";
import "./css/index.scss";

function OrderDetail(props: any) {
  const params: any = useParams();
  const navigate = useNavigate();
  const [messageApi, contextHolder] = message.useMessage();
  // const statusList = [
  //   "待付款",
  //   "已付款",
  //   "已关闭",
  //   "退款中",
  //   "已退款",
  //   "拼团中",
  // ];
  const [orderData, setOrderData] = useState<any>({});
  const [giftList, setGiftList] = useState<any[]>([]);
  const [status, setStatus] = useState<number>(1); //课程商品状态 0-待付款 1-已付款 2-已关闭 3-退款中 4-已退款 5-拼团中
  const [isShowPayModal, setIsShowPayModal] = useState<any>(false); // 打开支付弹框
  const [activityList, setActivityList] = useState<any[]>([]); // 活动列表
  const [preOrderRelation, setPreOrderRelation] = useState(0); // 与前置订单关系 0-无前置订单 1-前置订单升级到当前订单 2-前置订单换购到当前订单
  const [nextOrderRelation, setNextOrderRelation] = useState(0); // 与后置订单关系 0-无后置订单 1-当前订单升级到后置订单 2-当前订单换购到后置订单
  const [groupInfo, setGroupInfo] = useState<any>({});
  useEffect(() => {
    if (params.id) {
      getDetailInfo(params.id);
    }
  }, [params]);
  const getDetailInfo = async (id: string) => {
    const res = await getOrderDetailV2(id);
    setOrderData(res || {});
    setStatus(res?.status);
    setGiftList(res?.giftList || []);
    setActivityList(res?.activityList || []);
    setPreOrderRelation(res?.preOrderRelation || 0);
    setNextOrderRelation(res?.nextOrderRelation || 0);
    // 查询是否有拼团信息
    const ret = await getGroupLateLy({
      id: id,
      commodityId: res?.commodities[0].id,
    });
    if (ret?.groupId) {
      setGroupInfo(ret);
    }
    // setStatus(0);
  };
  // 复制订单号
  const copyId = () => {
    copy(orderData.id);
    messageApi.open({
      type: "success",
      content: "复制成功",
    });
  };
  // 去活动列表
  const toBuy = (activityId: any) => {
    navigate(`/activityList?orderId=${params.id}&activityId=${activityId}`);
  };
  // 去往原订单
  const goToPreOrder = () => {
    // console.log(orderData.preOrderId, "preOrderId");
    navigate(`/orderDetail/${orderData.preOrderId}`);
  };
  // 去往升级购/换购新订单
  const goToNextOrder = () => {
    navigate(`/orderDetail/${orderData.nextOrderId}`);
  };
  // 渲染活动顶部
  const renderAcitivityTop = () => {
    return (
      <div>
        {/* 活动--bannner */}
        <div>
          {activityList.length > 0
            ? activityList.map((item, index) => {
                return (
                  <div
                    style={{
                      marginTop:
                        status == 2 || status == 5 ? 40 : index == 0 ? 60 : 20,
                    }}
                  >
                    <ActivityBanner
                      info={item}
                      key={index}
                      toBuy={() => toBuy(item.activityId)}
                    />
                  </div>
                );
              })
            : null}
        </div>
        {/* 活动文案 -当前为原订单 */}
        {nextOrderRelation != 0 ? (
          <div
            className="info-origin-level"
            onClick={goToNextOrder}
            style={{
              marginTop:
                status == 2 || status == 4
                  ? 40
                  : activityList.length > 0
                  ? 20
                  : 60,
            }}
          >
            {nextOrderRelation === 1
              ? "该订单您已成功升级, 点击可查看升级购订单 >"
              : nextOrderRelation === 2
              ? "该订单您已成功换购, 点击可查看换购订单 >"
              : ""}
          </div>
        ) : null}
        {/* 活动文案 -当前为后置订单 */}
        {preOrderRelation != 0 ? (
          <div
            className="info-add-level"
            onClick={goToPreOrder}
            style={{
              marginTop:
                nextOrderRelation == 0
                  ? status == 2 || status == 4
                    ? 40
                    : 60
                  : 20,
            }}
          >
            {" "}
            {preOrderRelation === 1
              ? " 该订单为升级购订单, 点击可查看原订单 >"
              : preOrderRelation === 2
              ? "该订单为换购订单, 点击可查看原订单 >"
              : ""}
          </div>
        ) : null}
      </div>
    );
  };
  // 类型 0 去支付,1去学习,3取消退款
  const renderBtn = (type: number) => {
    switch (type) {
      case 0:
        return (
          <div className="bottom-btn" onClick={() => goPay()}>
            去支付
          </div>
        );
      case 1:
        console.log(orderData?.needSign, "needSign");
        if (orderData?.needSign) {
          return (
            <div className="bottom-btn" onClick={() => goSign()}>
              签署协议
            </div>
          );
        } else if (
          orderData?.activityFlag == 100 ||
          orderData?.activityFlag == 101 ||
          orderData?.activityFlag == 200 ||
          orderData?.activityFlag == 201
        ) {
          return null;
        } else {
          return (
            <div className="bottom-btn" onClick={() => goStudy()}>
              去学习
            </div>
          );
        }
      case 3:
        return (
          <div className="bottom-btn" onClick={() => cancelRefund()}>
            取消退款
          </div>
        );
    }
    return null;
  };
  const goPay = () => {
    setIsShowPayModal(true);
  };
  const goStudy = () => {
    navigate("/personalCenter");
  };
  const cancelRefund = async () => {
    const data = await getOrderStatus(orderData.id);
    if (data.status == 3) {
      await closeOrder(orderData.refundId);
      getDetailInfo(orderData.id);
    } else if (data.status == 4) {
      messageApi.open({
        type: "success",
        content: "退款成功,订单已关闭",
      });
      getDetailInfo(orderData.id);
    }
  };
  // 签协议
  const goSign = () => {
    navigate(
      `/Agreement?pdfurl=${orderData?.contractUrl}&orderContractId=${orderData?.historyId}&isOrder=true&goodsId=${orderData?.commodities?.[0]?.id}&redirectUrl=orderDetail/${params.id}`
    );
  };
  // 关闭弹框
  const handleClose = () => {
    setIsShowPayModal(false);
  };

  return (
    <div className="orderDetailOutwrap">
      <div className="orderDetail-wrap">
        <OrderStatus status={status} />
        {groupInfo?.groupId && groupInfo?.status == 1 ? (
          <div style={{ marginTop: status == 5 ? 40 : 60 }}>
            <GroupInvite
              groupInfo={groupInfo}
              orderId={params.id}
              commodityId={orderData?.commodities?.[0]?.id}
            />
          </div>
        ) : null}
        {renderAcitivityTop()}
        <div className={status == 1 ? "top-60" : "top-40"}></div>
        <div className="order-info-box">
          <div className="order-info-title">订单信息</div>
          <div className="order-info-content">
            <div className="order-info-top">
              <div className="order-info-time">
                购买时间 :{" "}
                <span className="order-info-time-val">
                  {orderData.createTime}
                </span>
              </div>
              <div className="order-info-num-box">
                <div className="order-info-num">
                  订单编号:
                  <span className="order-info-num-val">{orderData.id}</span>
                </div>
                <div className="copy-btn" onClick={() => copyId()}>
                  复制
                </div>
              </div>
            </div>
            <div className="order-info-table">
              {orderData.commodities && orderData.commodities.length > 0 ? (
                <Row className="order-info-header">
                  <Col span={12}>商品</Col>
                  <Col span={4}>原价</Col>
                  <Col span={4}>现价</Col>
                  <Col span={4}>数量</Col>
                </Row>
              ) : null}
              {orderData.commodities && orderData.commodities.length > 0
                ? orderData.commodities.map(
                    (commodityInfo: any, index: number) => {
                      return (
                        <OrderDeatailItem
                          commodityInfo={commodityInfo}
                          key={index}
                        ></OrderDeatailItem>
                      );
                    }
                  )
                : null}
            </div>
          </div>
          <div className="order-info-bottom">
            <div>
              <div className="order-info-bottom-box">
                <div>订单金额:</div>
                <div>￥{orderData.amount}</div>
              </div>
              <div className="order-info-bottom-box">
                <div>优惠金额:</div>
                <div className="red-text">
                  - ￥
                  {orderData?.discount > orderData.amount
                    ? orderData?.amount
                    : orderData?.discount}
                </div>
              </div>
              <div className="order-info-bottom-box">
                <div>{status == 0 ? "待付款" : "实付款"}:</div>
                <div>￥{orderData.payAmount}</div>
              </div>
            </div>
          </div>
        </div>
        {status == 1 && giftList?.length > 0 ? (
          <div style={{ marginBottom: -24 }}>
            {giftList?.length > 0 ? (
              <Gift info={giftList} fromType={"order"} orderStatus={status} />
            ) : null}
          </div>
        ) : null}
        <div className="order-right-box">
          <div>
            <div className="bottom-tip">
              {orderData?.activityFlag == 100 || orderData?.activityFlag == 101
                ? "提示: 原订单权益失效-请查看升级后的订单"
                : orderData?.activityFlag == 200 ||
                  orderData?.activityFlag == 201
                ? "提示: 原订单权益失效-请查看换购后的订单"
                : preOrderRelation != 0
                ? ""
                : "提示: 所购商品可以在个人中心-我的订单-已购买中查看"}
            </div>
          </div>
        </div>
        <div className="order-right-box">
          <div>{renderBtn(status)}</div>
        </div>
        {contextHolder}
      </div>
      {isShowPayModal ? (
        <PayModal onClose={handleClose} orderId={params.id} />
      ) : null}
    </div>
  );
}

export default OrderDetail;
