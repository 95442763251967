import { useState } from "react";
import "../css/ExchangeItem.scss";
import QrcodeModal from "@/components/QrcodeModal";
import CourseExchangeModel from "@/components/CourseExchangeModel";

function ExchangeItem(params: any) {
  const {skuId}=params;
  const [isModalOpen, setIsModalOpen] = useState(false);
  const goView = () => {
        setIsModalOpen(true);
        console.log("skuId",skuId);
        
      };
  const handleCloseQrCode = () => {
        setIsModalOpen(false);
  };
  return (
    <div className="recordContent">
      <div className="leftinfo">
        <div className="course-name"> {params.activityName}</div>
        <div className="exchange-time"> {params.createTime}</div>
      </div>
      <div className="rightinfo">
        <div className="goStudy" onClick={() => goView()}>
          查看
        </div>
      </div>
      {/* <QrcodeModal
        isShow={isModalOpen}
        type={"study"}
        onCloseQrCode={handleCloseQrCode}
      /> */}
      <CourseExchangeModel isShow={isModalOpen} onCloseQrCode={handleCloseQrCode} courseExchangeList={params} skuId={skuId}/>
    </div>
  );
}
export default ExchangeItem;
