/* eslint-disable  */
import React, { useState, useEffect } from "react";
import type { SelectProps, MenuProps } from "antd";
import { useNavigate } from "react-router-dom";
import userStore from "@/store/user";
import { CHECK_AUTO_LOGIN } from "@/config/const";
import { Modal, Select, Dropdown, Space } from "antd";
import { DownOutlined, SmileOutlined } from "@ant-design/icons";
import Login from "@/components/Login"; // 登录
import { fetchChannelDetail } from "@/apis/user";
import "../css/videoTop.scss";
function VideoTop(props: any) {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [darkLogo, setDarkLogo] = useState(""); // 机构名称
    const { crumbList } = props
    const navigate = useNavigate();
    const userInfo = userStore.getUserInfo();
    const handleNavigate = (url: string) => {
        navigate(url);
    };
      // 退出
    const logout = () => {
        userStore.setToken("");
        localStorage.removeItem(`${CHECK_AUTO_LOGIN}`);
        userStore.setUserInfo({});
        navigate("/");
        window.location.reload();
    };
    const items: MenuProps["items"] = [
        {
          key: "1",
          label: (
            <span
              rel="noopener noreferrer"
              onClick={() => handleNavigate("/personalCenter?topNavIndex=5")}
            >
              个人资料
            </span>
          ),
        },
        {
          key: "2",
          label: (
            <span
              rel="noopener noreferrer"
              onClick={() => {
                handleNavigate("/personalCenter?topNavIndex=3");
              }}
            >
              我的订单
            </span>
          ),
        },
        {
          key: "3",
          label: (
            <span
              rel="noopener noreferrer"
              onClick={() => {
                handleNavigate("/personalCenter?topNavIndex=0");
              }}
            >
              我的课程
            </span>
          ),
        },
        {
          key: "4",
          label: (
            <a onClick={logout} rel="noopener noreferrer" href="javascript:void(0)">
              退出登录
            </a>
          ),
        },
    ]
    // 面包屑跳转
    function goToAny(item:any){
      if(item.herf){
        navigate(item.herf);
      }
    };
    // 点击后打开登录弹窗
    function handelToLogin() {
        setIsModalOpen(true);
    }
    const handleOk = () => {
        setIsModalOpen(false);
    };
    const handleCancel = () => {
        setIsModalOpen(false);
    };

    const getChannelId = async () => {
      const { darkColorLogo = ''} = await fetchChannelDetail();
      setDarkLogo(darkColorLogo)
    };

  // 获取logo
  useEffect(() => {
      getChannelId();
  }, []);

  // 点击跳转到首页
  function handleToHome() {
    navigate("/");
  }
  return (
    <div className="video-top-box">
        <div className="video-top-left">
            <img className="header-logo"   src={darkLogo} onClick={handleToHome}></img>
            <div className="video-top-crumb">
            {crumbList &&crumbList.map((item: any, index: number) => {
              return (
                <div className="bread-one" key={index}>
                  <div
                    onClick={() => {
                      goToAny(item);
                    }}
                    className="bread-one-text"
                  >
                    {item.title}
                  </div>
                  {index != crumbList.length - 1 ? (
                    <div className="bread-one-icon">{">"}</div>
                  ) : null}
                </div>
              );
            })}
            </div>
        </div>
        <div className="video-top-right">
         {userStore.getToken() ? (
            <div className="header-adylogin">
              <div className="header-user">
                <img
                  src={userInfo.avatar}
                  alt="avatar"
                  className="user-avatar"
                />
                <div className="user-name">{userInfo.nickname}</div>
              </div>
              {/* <div className="down-icon iconfont icon-symbol_down"></div> */}
              <Dropdown menu={{ items }}>
                <a onClick={(e) => e.preventDefault()}>
                  <Space>
                    <DownOutlined   style={{ fontSize: '16px',  color: '#C9CDD4' }}/>
                  </Space>
                </a>
              </Dropdown>
            </div>
          ) : (
            <div className="header-login" onClick={handelToLogin}>
              <div className="login-item">
                <span className="iconfont icon-user"></span>
                <div className="login-btn">登录</div>
              </div>
              <div>|</div>
              <div className="register-btn">注册</div>
            </div>
          )} 
        </div>

        <Modal
          title=""
          className="header-modal-wrap"
          centered
          open={isModalOpen}
          onOk={handleOk}
          onCancel={handleCancel}
          footer={null}
        >
        <Login />
      </Modal>
    </div>
  );
}

export default VideoTop;