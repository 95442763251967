import { useState } from "react";
import "../css/limit.scss";
function UseLimit(props: any) {
  const { timeList, selectList, changeSku } = props;
  const [currentSkuId, setCurrentSkuId] = useState(selectList[0].skuId);
  return (
    <div className="order-limit-box">
      <div className="order-header">
        <div className="order-title">购买服务</div>
        <div className="goods-name">{selectList[0]?.name}</div>
      </div>
      <div className="limit-box">
        <div className="limit-title">使用期限</div>
        <div className="limit-content">
          {timeList &&
            timeList.map((item: any) => {
              if (item.skuId == currentSkuId) {
                return (
                  <div
                    className="limit-checked"
                    onClick={() => {
                      setCurrentSkuId(item.skuId);
                      changeSku(item);
                    }}
                  >
                    <p className="limit-date">{item.timeLimit}月</p>
                    {item.discount ? (
                      <p className="limit-discount">{item.discount}折</p>
                    ) : null}
                    <div className="limit-icon">
                      <i className="iconfont icon-correct"></i>
                    </div>
                  </div>
                );
              } else {
                return (
                  <div
                    className="limit-item"
                    onClick={() => {
                      setCurrentSkuId(item.skuId);
                      changeSku(item);
                    }}
                  >
                    <p className="limit-date">{item.timeLimit}月</p>
                    {item.discount ? (
                      <p className="limit-discount">{item.discount}折</p>
                    ) : null}
                  </div>
                );
              }
            })}
        </div>
      </div>
    </div>
  );
}
export default UseLimit;
