/* eslint-disable  */
import React, { useState, useEffect } from "react";
import CourseInfo from "./comps/CourseInfo";
import CourseContent from "./comps/CourseContent";
// import { getGoodsDeatilV2, getActivityInfo } from "@/apis/courseDetail";
import { getNewGoodsDeatilV2, getActivityInfo } from "@/apis/courseDetail";
import BreadCrumb from "@/components/BreadCrumb";
import userStore from "@/store/user";
import { useLocation, useSearchParams } from "react-router-dom";
import "./css/index.scss";
import "./css/loginAlert.scss";
import "./css/pagination.scss";

function CourseDetail(props: any) {
  // const params: any = useParams();
  const [searchParams] = useSearchParams();
  const [courseData, setCourseData] = useState({});
  const { state } = useLocation();
  const params: any = {
    id: searchParams?.get("id"),
    supplierId: searchParams?.get("supplierId"),
    orderId: searchParams?.get("orderId"),
    activityId: searchParams?.get("activityId"),
  };
  console.log(params, "params");

  const curTab = state ? state.curTab : 0;
  useEffect(() => {
    userStore.setSupplierId(params?.supplierId || "");
    if (params.id) {
      getDetilData(params.id, params?.orderId, params?.activityId);
    }
  }, [
    searchParams?.get("id"),
    searchParams?.get("supplierId"),
    searchParams?.get("activityId"),
    searchParams?.get("orderId"),
  ]);
  const getDetilData = async (id: any, orderId: any, activityId: any) => {
    const res = await getNewGoodsDeatilV2({
      id,
      orderId,
      activityId,
      blocks: [
        {
          blocksName: 'base',
        },
        {
          blocksName: 'sku',
        },
        {
          blocksName: 'teacher',
        },
        {
          blocksName: 'oper',
        },
        {
          blocksName: 'setting',
        },
        {
          blocksName: 'activity',
        },
        {
          blocksName: 'stat',
        },
      ],
    });
    if (!orderId && !activityId) {
      const ret = await getActivityInfo(id);
      if (ret?.activityId) {
        res.activityType = ret.type;
        if (ret?.activityStatus == 0) {
          res.activityId = ret.activityId;
          res.skuList = ret?.skuData;
        } else {
          res.startTimeStr = ret.startTimeStr;
        }
        res.activityStatus = ret?.activityStatus;
        res.groupNum = ret?.number || 0;
        res.inDate = ret.inDate;
        // setActivityId(ret?.activityId);
        // setAmount(ret?.amount);
        if (res.activityType == 4) {
          res.skuList.forEach((item: any) => {
            item.stock = 10000;
          });
        }
      }
    }
    res.skuList.forEach((item: any) => {
      item.stock = Math.min(
        item.stock ?? 10000,
        item.overplus ?? 10000,
        item.quantity ?? 10000
      );
    });
    setCourseData(res);
  };
  return (
    <div className="coursedetailOutwrap">
      {Object.keys(courseData).length > 0 ? (
        <div className="course-wrap">
          <BreadCrumb />
          <CourseInfo
            courseData={courseData}
            originalOrderId={params?.orderId || ""}
            originalActivityId={params?.activityId || ""}
          />
          <CourseContent courseData={courseData} curTab={curTab} supplierId={params?.supplierId || ""}/>
        </div>
      ) : null}
    </div>
  );
}

export default CourseDetail;
