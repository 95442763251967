import { Tabs, List, Empty } from "antd";
import AllInfoItem from "./AllInfoItem";
import "./css/index.scss";
interface props {
  tabList: { label: string; key: string }[] | any; // tab数据
  infoList: [] | any; // 资讯列表
  changeTab?: Function; //切换tab方法
  goInfoDetail?: Function; //点击进入资讯详情方法
  current?: number;
  total?: number;
  changePages: any; //翻页方法
}
const AllInfo = (props: props) => {
  const {
    tabList,
    infoList = [],
    changeTab,
    goInfoDetail,
    current,
    total,
    changePages,
  } = props;
  return (
    <div className="info-content">
      <div className="course-tab-list">
        <Tabs
          tabPosition={"top"}
          style={{ height: 220 }}
          indicatorSize={(origin) => origin - 16}
          items={tabList.map((item: any) => {
            return {
              label: `${item.name}`,
              key: `${item.id}`,
              // children: `Content of tab ${id}`,
            };
          })}
          onChange={(id) => {
            changeTab && changeTab(id);
          }}
        />
      </div>
      <div id="scrollableDiv">
        <List
          pagination={{
            position: "bottom",
            align: "center",
            pageSize: 10,
            onChange: changePages,
            defaultCurrent: 1,
            current: current,
            total: total,
            hideOnSinglePage: true,
          }}
          dataSource={infoList}
          renderItem={(item, index) => (
            <AllInfoItem
              item={item}
              index={index}
              goInfoDetail={goInfoDetail}
            />
            // <List.Item>

            // </List.Item>
          )}
          locale={{
            emptyText:
              infoList.length === 0 ? (
                <Empty
                  description="暂无数据"
                  style={{
                    minHeight: 300,
                    textAlign: "center",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                />
              ) : null,
          }}
        />
      </div>
    </div>
  );
};
export default AllInfo;
