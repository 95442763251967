import { useLocation } from "react-router-dom";
import RecursionTree from "@/components/GoodsShow/RecursionTree";

import "./index.scss";

// 查看更多
function GoodsShowMore() {
  const { state: { params, config } } = useLocation();
  
  return (
    <div className="goodsshow-more-container">
      <RecursionTree params={params} config={{...config, showMore: false}} />
    </div>
  );
}
export default GoodsShowMore;
