import React from "react"
import { observer } from "mobx-react"
import { Modal } from "antd"
import Login from "@/components/Login/index"
import userStore from "@/store/user"

const LoginModal = () => {
  return (
    <Modal
      title=''
      className='header-modal-wrap'
      centered
      open={userStore.isLoginModalOpen}
      onCancel={userStore.hideLoginModal}
      onOk={userStore.hideLoginModal}
      footer={null}
    >
      <Login />
    </Modal>
  )
}

export default observer(LoginModal)
