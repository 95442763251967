/*
 *一行两个商品组件
 */
import { Empty } from "antd";
import "./css/index.scss";
interface Iprops {
  data: any;
  showMore: boolean;
  goShowMore?: any;
  changeProject?: any;
  goCourseDetail: any;
  checkMoreByPro?: Function;
  title: string;
  showNum: number;
  showByProject: boolean;
  showTitle: boolean;
  fields: any;
}
const TwoInLine = (props: Iprops) => {
  const {
    goShowMore,
    showMore,
    changeProject,
    goCourseDetail,
    checkMoreByPro,
    title,
    showNum,
    showByProject,
    showTitle,
    fields,
  } = props;
  const { projectList, currentTab, courseCard } = props.data;

  return (
    <div className="setmeal-group recommend-course recommend-top">
      <div className="group-top clear">
        {showTitle ? (
          <div
            className="top-all-recommend"
            style={{ paddingTop: 24, paddingBottom: 24 }}
          >
            {showTitle ? (
              <div className="group-title activity-title fl">{title}</div>
            ) : null}
            <br />
            {showByProject ? (
              <div
                className="exam-list fl "
                style={{ flex: 1, marginLeft: 60 }}
              >
                <ul className="ul-list">
                  {projectList &&
                    projectList?.length > 0 &&
                    projectList.map((item: any, index: Number) => {
                      return (
                        <li
                          onClick={() => changeProject(item, index)}
                          key={item.projectId}
                          data-classNameify="free"
                          data-sign="gongcheng"
                          className={
                            currentTab === index
                              ? "exam-item exam-active"
                              : "exam-item"
                          }
                        >
                          {item.projectName}
                        </li>
                      );
                    })}
                </ul>
              </div>
            ) : null}
            {showMore && showTitle ? (
              <div className="more-btn" onClick={() => goShowMore()}>
                查看更多{" "}
                <span className=" iconfont icon-symbol_right"></span>
              </div>
            ) : null}
          </div>
        ) : null}
      </div>
      <div className="setmeal-swiper" style={{ width: "100%" }}>
        <div id="freeSwiper" className="swiper-container-recommed">
          <div className="recommed-box">
            <div
              className={
                courseCard.length > 0
                  ? "swiper-wrapper-recommend"
                  : "empty-content"
              }
            >
              {courseCard && courseCard?.length > 0 ? (
                courseCard.map((item: any, indexS: number) => {
                  {
                    return showNum > indexS ? (
                      <div
                        className="recommend-card btn-cursor"
                        onClick={() => goCourseDetail(item)}
                      >
                        <div className="img-content1">
                          <img src={item?.thumbnail} className="left-img" />
                          {fields.includes("peoples") ? (
                            <div className="sellingnumber1">
                              {" "}
                              {item.salesVolume ? item.salesVolume : 0}人已关注
                            </div>
                          ) : null}
                        </div>

                        <div className="right-card">
                          {fields.includes("name") ? (
                            <div className="right-card-name line-one">
                              {item?.name}
                            </div>
                          ) : null}
                          {fields.includes("sellPoint") ? (
                            <div className="right-card-sellingPoint">
                              {item?.sellingPoint}
                            </div>
                          ) : null}
                          <div className="right-card-bottom">
                            {fields.includes("teachers") ? (
                              <div className="recommend-teacher-box">
                                {item?.teachers?.length > 0 ? (
                                  <div style={{ display: "flex" }}>
                                    {item.teachers.map(
                                      (child: any, index: number) => {
                                        if (index < 3) {
                                          return (
                                            <div className="teacher-one-recommed">
                                              <img
                                                src={child.photo}
                                                className="teacher-img-l-recommed"
                                              />
                                              <div className="teacher-name-l-recommed">
                                                {child.name}
                                              </div>
                                            </div>
                                          );
                                        }
                                      }
                                    )}
                                  </div>
                                ) : null}
                              </div>
                            ) : null}
                            {fields.includes("price") ? (
                              <div className="price-recommed-box">
                                <span className="recommed-price-icon">￥</span>
                                <span className="recommed-price-data">
                                  {item?.price}
                                </span>
                                <span className="recommed-price-text">起</span>
                              </div>
                            ) : null}
                          </div>
                        </div>
                      </div>
                    ) : null;
                  }
                })
              ) : (
                <Empty
                  description="暂无数据"
                  style={{
                    minHeight: 300,
                    textAlign: "center",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default TwoInLine;
