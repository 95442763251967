/* eslint-disable  */
import React, { useState, useEffect } from "react";
// import { useParams } from "react-router-dom";
import "../css/orderstatus.scss";

function OrderStatus(props: any) {
  const { status } = props; //课程商品状态 0-待付款 1-已付款 2-已关闭 3-退款中 4-已退款 5-拼团中
  const [type, setType] = useState(4); //type 类型 0-待支付 1-退款中 2- 已关闭 3-拼团中 4-其他
  // const params: any = useParams();
  // const statusList = [
  //   "待付款",
  //   "已付款",
  //   "已关闭",
  //   "退款中",
  //   "已退款",
  //   "拼团中",
  // ];
  // const expressList = ["待发货", "待收货", "已完成"];
  useEffect(() => {
    if (status == 0) {
      setType(0);
    } else if (status == 3) {
      setType(1);
    } else if (status == 2 || status == 4) {
      setType(2);
    } else if (status == 5) {
      setType(3);
    } else {
      setType(4);
    }
  }, [status]);

  // 付款成功
  const PaymentSuccess = () => {
    return (
      <div className="pay-status-header-wrap">
        <div className="pay-status-img">
          <img src={require("../images/pay_success.png")} alt="" />
        </div>
        <div className="pay-status-text-wrap">
          <div className="pay-status-text">付款成功</div>
          <div style={{ height: 4 }}></div>
          <div className="pay-status-tip">您已成功购买，快开始学习吧~</div>
        </div>
      </div>
    );
  };

  return (
    <div>
      {
        status == 1 ? <PaymentSuccess /> : null
      }
      {type != 4 ? (
        <div className="status-box">
          <div
            className={
              type == 0
                ? "status-0 status-icon"
                : type == 1
                ? "status-1 status-icon"
                : type == 2
                ? "status-2 status-icon"
                : "status-3 status-icon"
            }
          ></div>
          <div>
            <div className="status-title">
              {type == 0
                ? "待支付"
                : type == 1
                ? "售后中"
                : type == 2
                ? "已关闭"
                : "拼团中"}
            </div>
            <div className="status-subTitle">
              {type == 0
                ? "30分钟后未支付,订单自动关闭"
                : type == 1
                ? "已提交申请,帮您处理中"
                : type == 2
                ? "订单已关闭"
                : "结束后，系统自动匹配好友拼单成功"}
            </div>
          </div>
        </div>
      ) : null}
    </div>
  );
}

export default OrderStatus;
