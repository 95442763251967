/* eslint-disable  */
import React, { useState, useEffect } from "react";
import "../css/livePlay.scss";
function LivePlay(props:any) {
    const {url} = props
    const [liveUrl, setLiveUrl] = useState(url);
    useEffect(() => {
        setLiveUrl(url)
      }, [url]);
  return (
    <div className="live-box">
        <iframe className="live-iframe" src={liveUrl}></iframe>
    </div>
  );
}

export default LivePlay;