import React, { useEffect, useState } from "react";
import { Modal, QRCode, message } from "antd";

interface Iprops {
  isShow: boolean; // 是否展示弹框
  onClose: Function; // 关闭弹框回调
  text: string;
}

const ExModal = (props: Iprops) => {
  const { onClose, text } = props;
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleCancel = () => {
    onClose(false);
  };
  useEffect(() => {
    setIsModalOpen(props.isShow);
  }, [props.isShow]);
  return (
    <Modal
      // title="选择支付方式"
      centered
      className="ex-modal"
      open={isModalOpen}
      onCancel={handleCancel}
      footer={null}
      width={301}
    >
      <div className="ex-madal-title">使用说明</div>
      <div className="ex-content">{text}</div>
      <div className="ex-btn" onClick={() => handleCancel()}>
        我知道了
      </div>
    </Modal>
  );
};

export default ExModal;
