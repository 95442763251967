import React, { useState, useEffect } from "react"
import { useNavigate, useSearchParams, useLocation } from "react-router-dom"
import { Document, Page, pdfjs } from "react-pdf"
import "../css/PreviewAgreement.scss"
pdfjs.GlobalWorkerOptions.workerSrc = `https://resource.wangxiao.net/pc/pdf.worker.js`
// pdfjs.GlobalWorkerOptions.workerSrc = new URL(
//   'pdfjs-dist/build/pdf.worker.min.js',
//   import.meta.url,
// ).toString();
// import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
// import 'react-pdf/dist/esm/Page/TextLayer.css';
const options = {
  cMapUrl: `https://resource.wangxiao.net/cmaps/`,
}

const PreviewAgreement: React.FC = () => {
  const { state } = useLocation()
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()
  const orderContractId = searchParams.get("orderContractId")
  const goodsId = searchParams.get("goodsId")
  const isOrder = searchParams.get("isOrder")
  const pdfurl = searchParams.get("pdfurl")
  const redirectUrl = searchParams.get("redirectUrl")
  const [file, setFile] = useState(pdfurl)
  const [numPages, setNumPages] = useState(null)
  const onDocumentLoadSuccess = (e: any) => {
    setNumPages(e.numPages)
  }
  useEffect(() => {
    setFile(pdfurl)
  }, [pdfurl])

  const handleNavigate = () => {
    const url = `/signAgreement/?orderContractId=${orderContractId}&goodsId=${goodsId}&isOrder=${isOrder}&redirectUrl=${redirectUrl}`
    navigate(url, { state })
  }

  return (
    <div className='agreement-box'>
      <div className='pdf-content'>
        <div className='video-pdf-box'>
          <Document
            options={options}
            file={file}
            className='document-pdf'
            onLoadSuccess={onDocumentLoadSuccess}
          >
            {Array.from(new Array(numPages), (el, index) => (
              <Page
                key={`page_${index + 1}`}
                pageNumber={index + 1}
                renderAnnotationLayer={false}
                renderTextLayer={false}
                scale={1.5}
              />
            ))}
          </Document>
        </div>
        {orderContractId && (
          <button className='sign-button' onClick={handleNavigate}>
            签署此协议
          </button>
        )}
      </div>
    </div>
  )
}

export default PreviewAgreement
