/* eslint-disable  */
import React, { useState, useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import VideoTop from "../VideoPlay/comps/videoTop";
import ReplayLeft from "./comps/replayLeft";
import LivePlay from "../VideoPlay/comps/livePlay";
import {
  getReplayList,
} from "@/apis/courseDetail";
import "../VideoPlay/css/videoPlay.scss";
function LiveReplay(props: any) {
  const [params] = useSearchParams();
  const productId: any = params.getAll("productId")[0]; //产品id
  const liveRoomId: any = params.getAll("liveRoomId")[0]; //直播间id
  const unitName: any = params.getAll("unitName")[0]; //单元名称
  const [replayList,setReplayList] = useState<any[]>([])
  const crumbList = [
      {
        title: "首页",
        herf: "/",
      },
      {
        title: "回放列表",
        herf: "",
      }
  ];

  const [liveUrl, setLiveUrl] = useState(); //直播回放的路径
  const handleCallback = (url: any) => {
    setLiveUrl(url)
    console.log(url, "当前点击");
  };
  const getList = async()=>{
    const res = await getReplayList({ productId:productId, liveRoomId: liveRoomId });
    setReplayList(res)
    const  url = res[0]?.playUrl || ''
    setLiveUrl(url)
  }

  useEffect(() => {
    getList()
  }, [liveRoomId,productId]);
  return (
    <div>
      <VideoTop crumbList={crumbList}></VideoTop>
      <div className="video-play-box">
        <LivePlay url={liveUrl}></LivePlay>
        <ReplayLeft
          list={replayList}
          unitName={unitName}
          callback={handleCallback}
        ></ReplayLeft>
      </div>
    </div>
  );
}
export default LiveReplay;
