import { request } from "@/utils/request";

// 获取用户基础认证信息
export function fetchUserInfo() {
  return request({
    url: `/auth/student/info`,
    method: "GET",
  });
}
export function fetchcourse(data: any) {
  return request({
    url: `/commodity/display/list`,
    method: "POST",
    data,
  });
}

// 账号密码登录
export function loginPassword(data: any) {
  return request({
    url: `/auth/login/password`,
    method: "POST",
    data,
  });
}

// 获取验证码
export function getVerifyCode(params: any) {
  return request({
    url: `/auth/login/verifyCode`,
    method: "GET",
    params,
  });
}

// 验证码登录
export function verifyCodeLogin(params: any, data: any) {
  return request({
    url: `/auth/login/mobile?sessionKey=${params.sessionKey}&code=${params.code}`,
    method: "POST",
    data: data,
  });
}

// 获取渠道信息
export function fetchChannelInfo() {
  return request({
    url: `/base/app/application`,
    method: "GET",
  });
}
// 获取渠道信息
export function fetchChannelDetail() {
  const  sessionKey = 'channel_detail'
  const  channelDetail =  window.sessionStorage.getItem(sessionKey) || '';
  return new Promise<any>(async (resolve) => {
    if(channelDetail && channelDetail != ''){
      resolve(JSON.parse(channelDetail));
    }else{
      let res = await request({
        url: `/base/app/channel/simple/info`,
        method: "GET",
      });
      window.sessionStorage.setItem(sessionKey,JSON.stringify(res))
      resolve(res);
    }
  });
}

// 修改用户个人单位
export function editUserCompany(data: any) {
  return request({
    url: "/auth/student/update/org",
    method: "POST",
    data,
  });
}

// 修改-用户基础信息
export function editUserInfo(data: any) {
  return request({
    url: "/auth/student/info",
    method: "put",
    data,
  });
}
// 查询用人单位
export function getEnterpriseList(data: any) {
  return request({
    url: "/util/org/" + `?key=${data.key}&index=${data.index}&row=${data.row}`,
    method: "post",
  });
}
// // 上传图片 let file = await uploadFiles('/upload/avatar', res.tempFilePaths[0]);
export function upload(data: any) {
  return request({
    url: "/upload/avatar",
    method: "post",
    headers:{
      'client-channel-id': '94amu3n7ibu7u05cxniykxj',
      "Content-Type":" multipart/form-data",
    },
    data
  });
}

// 获取客服配置
export function fetchCustomer() {
  return request({
    url: `/base/agent-biz/customer/settings`,
    method: "GET",
  });
}