import { Empty, Tabs } from "antd";
import InfoItem from "./InfoItem";
import "./css/index.scss";
import "./css/infoItem.scss";
interface props {
  tabList: { label: string; key: string }[] | any; // tab数据
  infoList: [] | any; // 资讯列表
  changeTab?: Function; //切换tab方法
  goInfoDetail?: Function; //点击进入资讯详情方法
  showNum: Number;
}
const Info = (props: props) => {
  const { tabList, infoList = [], showNum, changeTab, goInfoDetail } = props;
  return (
    <div className="info-content">
      <div className="course-tab-list">
        <Tabs
          tabPosition={"top"}
          style={{ height: 220 }}
          tabBarStyle={{ fontSize: 40, color: "red" }}
          indicatorSize={(origin) => origin - 16}
          items={
            tabList?.length > 0 &&
            tabList.map((item: any) => {
              return {
                label: `${item.name}`,
                key: `${item.id}`,
              };
            })
          }
          onChange={(id) => {
            changeTab && changeTab(id);
          }}
        />
        {/* <div>
            {inforTab &&
              inforTab.map((item: any, index: any) => {
                return (
                  <span
                    key={index}
                    className={
                      currentTab == index
                        ? "course-catalog active"
                        : "course-catalog"
                    }
                    onClick={() => {
                      getInfoListbyCategory(item?.id);
                    }}
                  >
                    {item.name}
                  </span>
                );
              })}
          </div> */}
      </div>
      <div className="infoList-content">
        {infoList.length > 0 ? (
          infoList?.map((item: any, index: any) => {
            return index < showNum ? (
              <InfoItem
                item={item}
                index={index}
                key={index}
                goInfoDetail={goInfoDetail}
              />
            ) : null;
          })
        ) : (
          <Empty
            description="暂无数据"
            style={{
              minHeight: 300,
              textAlign: "center",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          />
        )}
      </div>
    </div>
  );
};
export default Info;
