import React from "react"
import { useSearchParams, useNavigate, useLocation } from "react-router-dom"
import { Col, Row } from "antd"
import "../css/SignFinish.scss"

const SignFinish = () => {
  const { state } = useLocation()
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()
  const orderContractId = searchParams.get("orderContractId")
  const redirectUrl = searchParams.get("redirectUrl")
  const isOrder = searchParams.get("isOrder") !== "false"

  const handleNavigate = () => {
    if (redirectUrl) {
      const newRedirectUrl = `/${redirectUrl}?orderContractId=${orderContractId}`
      navigate(newRedirectUrl, {
        state,
      })
    } else {
      console.error("Redirect URL is null")
      navigate("/")
    }
  }

  return (
    <Row className='background-container' align={"middle"}>
      <Col span={14}></Col>
      <Col span={8}>
        <div className='success-container'>
          <div className='success-icon'>
            <div className='iconfont icon-succeed'></div>
          </div>
          <p className='success-text'>签署成功</p>
          <p className='congrats'>恭喜您，已成功签署协议</p>
          <button className='study-btn' onClick={handleNavigate}>
            {isOrder ? "继续学习" : "继续购买"}
          </button>
        </div>
      </Col>
      <Col span={2}></Col>
    </Row>
  )
}

export default SignFinish
