import { useEffect, useState } from "react";
import TopNav from "./TopNav";
import Recommend from "./Recommend";
import Information from "./Information";
import BreadStore from "@/store/breadCrumb";
import LiveList from "@/components/LiveList";
import Coupon from "@/components/Coupon";
import LiveSingle from "@/components/LiveSingle";
import Advertisement from "@/components/Advertisement";
// import Goods from "@/components/Goods";
import { requestShopDecorateTemplate } from "@/apis/common";
import { HOME_PAGE_CODE } from "@/config/const";
import RichText from "@/components/RichText";
import Footer from "@/components/Footer";
import userStore from "@/store/user";
import StudyGuide from "@/components/StudyGuide";

import {
  formatInfoData,
  formatGoodsData,
  formatCategoryNavigation,
  formatCouponData,
  formatLiveListData,
  formatLiveIngData,
  formatAdvertisement,
  formatEditor,
  formatFooter,
  formatHeader,
  formatStudyGuide,
} from "@/utils/shopDecorate";
import Header from "@/components/Header";
import GoodsShow from "@/components/GoodsShow"; // 商品展示组件

function Home() {
  // 获取店铺装修数据
  const [homeTemplate, setHomeTemplate] = useState([]);
  const [bgImage, setBgImage] = useState("");
  const [bgColor, setBgColor] = useState("");
  // 广告位信息
  const getShopDecorateTemplate = async () => {
    const { pageJson = "{}", areaJson = "{}" } =
      await requestShopDecorateTemplate({
        pageCode: HOME_PAGE_CODE,
        id: "-1",
      });
    const areaJson1 = JSON.parse(areaJson);
    areaJson1?.style?.forEach((item: any) => {
      if (item.attr === "background-image") {
        setBgImage(item.val);
      }
      if (item.attr === "background-color") {
        setBgColor(item.val);
      }
    });
    setHomeTemplate(JSON.parse(pageJson));
    // const { areaJson = "{}", pageJson = "{}"} = result;
    // 统一在src/utils/shopDecorate.ts格式化组件数据
    return;
  };
  const renderHome = (data: any, index: number) => {
    switch (data.type) {
      case "pc-goods-show":
        return <GoodsShow data={data} />;
      // 学习引导
      case "pc-study-guide":
        const studyData = formatStudyGuide(data);
        return <StudyGuide data={studyData} key={index} />;
      //页眉
      case "header-comp":
        const formatHeaderData = formatHeader(data);
        console.log("渲染数据");

        console.log(formatHeaderData);

        userStore.setHeaderComp(formatHeaderData);
        console.log("仓库--------");
        console.log(userStore.getHeaderComp());
        return <Header data={formatHeaderData} key={index} />;
      //分类导航
      case "classify-navigation":
        const formatCategoryNavigationData = formatCategoryNavigation(data);
        return <TopNav data={formatCategoryNavigationData} key={index} />;
      //广告位
      case "pc-advertisement":
        const formatAdvertisementData = formatAdvertisement(data);
        return <Advertisement data={formatAdvertisementData} key={index} />;
      //商品组件
      case "goods-list":
        const goodsData = formatGoodsData(data);
        return <Recommend data={goodsData} key={index} />;
      //资讯
      case "info-list":
        const formatData = formatInfoData(data);
        return <Information data={formatData} key={index} />;
      //优惠券
      case "coupon-comp-pc":
        const {
          title,
          showTitle,
          showMore,
          showNum,
          ids,
          hideLossEfficacy,
          layout,
          customStyle,
        } = formatCouponData(data);
        return (
          <Coupon
            title={title}
            showTitle={showTitle}
            showMore={showMore}
            showNum={showNum}
            ids={ids}
            hideLossEfficacy={hideLossEfficacy}
            layout={layout}
            customStyle={customStyle}
            key={index}
          />
        );
      //富文本
      case "editor-text":
        const formatEditordata = formatEditor(data);
        return <RichText data={formatEditordata} key={index} />;
      //直播列表
      case "live-list-pc":
        const liveData = formatLiveListData(data);
        return <LiveList {...liveData} key={index} />;
      //直播
      case "live-single-pc":
        const liveIng = formatLiveIngData(data);
        return <LiveSingle {...liveIng} key={index} />;
      //页尾
      case "footer-comp":
        const formatFooterData = formatFooter(data);
        userStore.setFooterComp(formatFooterData);
        return <Footer data={formatFooterData} key={index} />;
    }
  };

  useEffect(() => {
    getShopDecorateTemplate();
  }, []);
  useEffect(() => {
    // getcourse();
    BreadStore.setPageData([{ title: "首页", href: "/" }]);
  }, []);
  return (
    <div
      style={{
        backgroundColor: bgColor,
        backgroundImage: `url(${bgImage})`,
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center center",
        minHeight: "100vh",
      }}
    >
      {homeTemplate.map((item, index) => {
        return renderHome(item, index);
      })}
      {/* <TopNav />
      <Coupon />
      <Recommend />
      <LiveList />
      <LiveSingle />
      <Information /> */}
    </div>
  );
}

export default Home;
