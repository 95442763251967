import { useLocation } from "react-router-dom";
import "./css/index.scss";
import { getSingPageDetail } from "@/apis/information";
import BreadCrumb from "@/components/BreadCrumb";
import { useEffect, useRef, useState } from "react";
function SingPage() {
  const {
    state: { id },
  } = useLocation();
  const initialized = useRef(false);
  const getContent = async (id: any) => {
    const res = await getSingPageDetail(id);//获取详情
    setContent(res.pageDetails);
    setTitle(res.pageName);
  };
  const [content, setContent] = useState<any>(); //内容
  const [title, setTitle] = useState<any>(); //标题
  useEffect(() => {
    if (!initialized.current) {
      initialized.current = true;
      getContent(id);
    }
  }, [id]);
  return (
    <div>
      <BreadCrumb />
      <div className="page-info-box">
        <div className="page-info-title">{title}</div>
        <div
          className="page-iframe-box"
          dangerouslySetInnerHTML={{ __html: content }}
        >
        </div>
      </div>
    </div>
  );
}
export default SingPage;
