import React, { useState, useEffect } from "react";
import { Col, Row, Empty, message } from "antd";
import "../css/MyOrder.scss";
import OrderItem from "./OrderItem";
import { Tabs, Pagination } from "antd";
import { fetchOrderList, closeOrder, fetchOrderListV2 } from "@/apis/order";
import QrcodeModal from "@/components/QrcodeModal";
const MyOrder: React.FC = () => {
  const [tabs] = useState([
    { name: "全部", key: "" },
    { name: "待付款", key: "0" },
    { name: "已付款", key: "1" },
  ]);
  const [activeTab, setActiveTab] = useState("");
  const [pageIndex, setPageIndex] = useState(1);
  const [pageRow] = useState(5);
  const [pageTotal, setPageTotal] = useState(0);
  const [tabData, setTabData] = useState([]);
  const [qrCodeModalOpen, setQrCodeModalOpen] = useState(false); // h5邀请弹窗
  const [curOrder, setCurOrder] = useState(""); // 当前操作的订单id
  const [messageApi, contextHolder] = message.useMessage();
  const changeTab = (id: any) => {
    setPageIndex(1);
    setActiveTab(id);
  };
  const onChange = (index: number) => {
    setPageIndex(index);
  };
  // 取消订单
  const hanleCloseOrder = async (val: any) => {
    const res = await closeOrder(val.id);
    if (res) {
      messageApi.open({
        type: "success",
        content: "取消订单成功",
      });
      const ret = await fetchOrderListV2(activeTab, pageIndex, pageRow);
      setTabData(ret.body);
      if (pageTotal !== ret.page?.total) {
        setPageTotal(ret.page?.total);
      }
    }
  };
  // 补填地址
  const reFillAddress = (order: any) => {
    console.log(order, "order--001");
    setCurOrder(order.id);
    setQrCodeModalOpen(true);
  };
  const closeModal = () => {
    setQrCodeModalOpen(false);
  };
  useEffect(() => {
    const fetchTabs = async () => {
      const res = await fetchOrderListV2(activeTab, pageIndex, pageRow);
      setTabData(res.body);
      if (pageTotal !== res.page?.total) {
        setPageTotal(res.page?.total);
      }
    };
    fetchTabs();
  }, [activeTab, pageIndex, pageRow, pageTotal]);
  return (
    <div className="order-my-content">
      <div className="order-tab-list">
        <Tabs
          tabPosition={"top"}
          style={{ height: 220 }}
          indicatorSize={(origin) => origin - 16}
          items={tabs.map((item: any) => {
            return {
              label: `${item.name}`,
              key: `${item.key}`,
            };
          })}
          onChange={changeTab}
        />
      </div>
      <div className="order-content">
        {tabData && tabData.length > 0 ? (
          <Row className="order-header">
            <Col span={12}>订单信息</Col>
            <Col span={3}>金额(元)</Col>
            <Col span={3}>实付款(元)</Col>
            <Col span={3}>状态</Col>
            <Col span={3}>操作</Col>
          </Row>
        ) : null}
        {tabData && tabData.length > 0 ? (
          tabData.map((order: any, index: number) => {
            return (
              <OrderItem
                odrer={order}
                hanleCloseOrder={hanleCloseOrder}
                reFillAddress={reFillAddress}
              ></OrderItem>
            );
          })
        ) : (
          <Empty
            style={{ marginTop: 100 }}
            image={
              <img src={require("../images/order-empty.png")} alt=""></img>
            }
            imageStyle={{ height: 172 }}
            description={<span style={{ color: "#9FA1A4" }}>暂无订单</span>}
          ></Empty>
        )}
        {tabData && tabData.length > 0 ? (
          <Pagination
            onChange={onChange}
            total={pageTotal}
            showSizeChanger={false}
            pageSize={pageRow}
            current={pageIndex}
          />
        ) : null}
        <QrcodeModal
          isShow={qrCodeModalOpen}
          type={"address"}
          onCloseQrCode={closeModal}
          addressUrl={`/page_material/login/pages/reFillAddress?orderId=${curOrder}`}
          // groupUrl={`/page_commodity/order/pages/collageDetail?id=${orderId}&commodityId=${commodityId}`}
        />
      </div>
      {contextHolder}
    </div>
  );
};

export default MyOrder;
