import React, { useState, useEffect } from "react";
// import { fetchGetCourseName} from "@/apis/allCourseName"
import Goods from "@/components/Goods";
import { getPopularCourse } from "@/apis/allCourseName";
import { getOtherCourse } from "@/apis/allCourseName";
import { getProjectIds } from "@/apis/allCourseName";
import { getRecommendProjectIds } from "@/apis/allCourseName";
import { getRecommendCourses } from "@/apis/allCourseName";
import userStore from "@/store/user";

import { getCommodityByProject, getCommodityByIds } from "@/apis/allCourseName";
// import { getRecommendCourse } from "@/apis/allCourseName";
// import { getProjectIds } from "@/apis/allCourseName";
import { useNavigate } from "react-router-dom";
import { handleBread } from "@/utils/bread";
import "../Home/css/recommend.scss";

function Recommend(props: any) {
  const {
    showTitle,
    title,
    showNum,
    showMore,
    showByProject,
    fields,
    goodsData,
    mode,
    customStyle,
  } = props.data;
  console.log(props.data, customStyle, "1111");

  const navigate = useNavigate();
  const [courseCard, setCourseCardList] = useState([]); // 全部课程 -卡片
  const [ids, setIds] = useState([]);
  const [courseRecommondCard, setCourseRecommondCardList] = useState([]); // 推荐课程-卡片
  const [projectList, setProjectList] = useState<any>([]); // 列表
  const [currentTab1, setCurrentTab1] = useState(0); // 全部课程的tab切换

  // 获取全部课程-项目信息
  // const getProjectIdInfoAllCourse = async () => {
  //   let arr = [{ value: "热门课程", key: "hotCourse" }];
  //   const res = await getProjectIds();
  //   if (res?.length > 0) {
  //     res.forEach((item: any) => {
  //       arr.push(item);
  //     });
  //   }
  //   setProjectList(arr);
  // };
  // 获取推荐课程-项目信息
  // const getProjectIdInfoRecomCourse = async () => {
  //   const res = await getRecommendProjectIds();
  //   setRecommendCourse(res);
  //   if (res?.length > 0) {
  //     setCurRecommendProjectId(res[0]?.key);
  //   }
  // };
  // 更改全部课程-选中项目
  const changeProject = async (item: any, index: any) => {
    setCurrentTab1(index);
    if (item.key == "hotCourse") {
      // getHotClassInfo();
    } else {
      setCourseCardList(item.commodityInfos);
    }
    //  const res = await getPopularCourse(item.id);
  };
  // 更改推荐课程-选中项目
  // const clickRecommendCourse = async (item: any, index: any) => {
  //   setCurrentTab2(index);
  //   setCurRecommendProjectId(item.key);
  //   // getProjectIdInfoRecomCourse();
  //   // getRecommendCoursesInfo(item.key);
  //   //  const res = await getPopularCourse(item.id);
  // };

  // 去往课程详情页
  const goCourseDetail = (item: any) => {
    userStore.setSupplierId(item.supplierId || "");
    item.supplierId
      ? handleBread({
          title: `${item.name}`,
          href: `/courseDetail?id=${item.id}&supplierId=${item.supplierId}`,
        })
      : handleBread({
          title: `${item.name}`,
          href: `/courseDetail?id=${item.id}`,
        });

    item.supplierId
      ? navigate(`/courseDetail?id=${item.id}&supplierId=${item.supplierId}`)
      : navigate(`/courseDetail?id=${item.id}`);
  };
  // 获取热门课程的课程列表
  // const getHotClassInfo = async () => {
  //   const res = await getPopularCourse();

  //   // res.push({ title: "查看更多" });
  //   // setCourseCardList(res);
  //   // setData(res)

  //   // console.log(res);
  // };
  // 获取非热门课程的课程列表-byid
  // const getOtherClassInfo = async (key: any) => {
  //   const res = await getOtherCourse(key, 0, 7);
  //   // res.push({ title: "查看更多" });
  //   setCourseCardList(res);
  // };

  // useEffect(() => {
  //   // getProjectIdInfoAllCourse();
  //   // getHotClassInfo();
  //   getProjectIdInfoRecomCourse();
  //   // getRecommendCoursesInfo()
  // }, []);
  useEffect(() => {
    if (showByProject) {
      getProjectList();
    } else {
      getCommodityById();
    }
  }, []);

  // 获取项目下推荐课程列表
  // const getRecommendCoursesInfo = async (key: any) => {
  //   const res = await getRecommendCourses(key, 0, 4);
  //   setCourseRecommondCardList(res);
  // };
  const getProjectList = async () => {
    const ids = goodsData.map((item: any) => {
      return item.id;
    });
    setIds(ids);
    const res = await getCommodityByProject({ commodityIds: ids });
    setProjectList(res);
    setCourseCardList(res[0]?.commodityInfos);
  };
  const getCommodityById = async () => {
    const ids = goodsData.map((item: any) => {
      return item.id;
    });
    setIds(ids);
    const res = await getCommodityByIds({
      commodityIdList: ids,
      index: 0,
      row: ids.length,
    });
    setCourseCardList(res);
  };
  // 顶部的查看更多
  const checkAllTop = () => {
    handleBread({ title: `全部课程`, href: `/allCourse/4/${ids}` });
    navigate(`/allCourse/4/${ids}`);
  };
  // 带项目的查看更多
  const checkMoreByPro = () => {
    if (currentTab1 == 0) {
      handleBread({ title: "全部课程", href: `/allCourse/4/1` });
      navigate(`/allCourse/4/1`);
    } else {
      handleBread({
        title: `${projectList[currentTab1].value}`,
        href: `/allCourse/5/${projectList[currentTab1].projectId}`,
      });
      navigate(`/allCourse/5/${projectList[currentTab1].projectId}`);
    }
  };

  return (
    <div className="page-content">
      <div className="content-wrap">
        <div className="setmeal-groups" style={customStyle}>
          <Goods
            projectList={projectList}
            title={title}
            showTitle={showTitle}
            showMore={showMore}
            changeProject={changeProject}
            courseCard={courseCard}
            goShowMore={checkAllTop}
            showNum={showNum}
            fields={fields}
            showByProject={showByProject}
            checkMoreByPro={checkMoreByPro}
            currentTab={currentTab1}
            goCourseDetail={goCourseDetail}
            mode={mode}
          />

          {/* 推荐课程 */}
          {/* <div className="setmeal-group recommend-course recommend-top">
            <div className="group-top clear">
              <div className="top-all-recommend">
                <div className="group-title activity-title fl">推荐课程</div>
                <br />
                <div className="exam-list fl center">
                  <ul>
                    {recommendCourse &&
                      recommendCourse?.length > 0 &&
                      recommendCourse.map((item: any, index: Number) => {
                        return (
                          <li
                            onClick={() => clickRecommendCourse(item, index)}
                            key={item.key}
                            data-classNameify="free"
                            data-sign="gongcheng"
                            className={
                              currentTab2 === index
                                ? "exam-item exam-active"
                                : "exam-item"
                            }
                          >
                            {item.value}
                          </li>
                        );
                      })}
                  </ul>
                </div>
              </div>
            </div>
            <div className="setmeal-swiper">
              <div id="freeSwiper" className="swiper-container-recommed">
                <div className="recommed-box">
                  <div className="swiper-wrapper-recommend">
                    {courseRecommondCard &&
                      courseRecommondCard?.length > 0 &&
                      courseRecommondCard.map((item: any, indexS: number) => {
                        return (
                          <div
                            className={
                              indexS == 1 || indexS == 3
                                ? "recommend-card btn-cursor recommend-right"
                                : "recommend-card btn-cursor"
                            }
                            style={{ marginTop: indexS > 1 ? 17 : 0 }}
                            onClick={() => goCourseDetail(item)}
                          >
                            <img src={item?.thumbnail} className="left-img" />
                            <div className="right-card">
                              <div className="right-card-name line-one">
                                {item?.name}
                              </div>
                              <div className="right-card-sellingPoint">
                                {item?.sellingPoint}
                              </div>
                              <div className="right-card-bottom">
                                <div className="recommend-teacher-box">
                                  {item?.teachers?.length > 0 ? (
                                    <div style={{ display: "flex" }}>
                                      {item.teachers.map(
                                        (child: any, index: number) => {
                                          if (index < 3) {
                                            return (
                                              <div className="teacher-one-recommed">
                                                <img
                                                  src={child.photo}
                                                  className="teacher-img-l-recommed"
                                                />
                                                <div className="teacher-name-l-recommed">
                                                  {child.name}
                                                </div>
                                              </div>
                                            );
                                          }
                                        }
                                      )}
                                    </div>
                                  ) : null}
                                </div>
                                <div className="price-recommed-box">
                                  <span className="recommed-price-icon">
                                    ￥
                                  </span>
                                  <span className="recommed-price-data">
                                    {item?.price}
                                  </span>
                                  <span className="recommed-price-text">
                                    起
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>
                        );
                      })}{" "}
                  </div>
                </div>
              </div>
            </div>
          </div> */}
        </div>
      </div>
    </div>
  );
}

export default Recommend;
