import React, { useEffect, useState } from "react";
import TopBanner from "./comps/TopBanner";
import ActivityGoodsList from "./comps/ActivityGoodsList";
import { useSearchParams } from "react-router-dom";
import { getActivityListDetail } from "@/apis/activity";
import "./css/index.scss";
function ActivityList() {
  const [searchParams] = useSearchParams();
  const [listInfo, setListInfo] = useState<any>({});
  const params: any = {
    orderId: searchParams?.get("orderId"),
    activityId: searchParams?.get("activityId"),
  };
  // console.log(params, "activityId");
  useEffect(() => {
    const getListDetail = async (orderId: any, activityId: any) => {
      const res = await getActivityListDetail({
        orderId,
        activityId,
        channelType: "pc",
      });
      console.log(res, "resresresresresresres");
      setListInfo(res[0] || {});
    };
    if (params?.orderId && params?.activityId) {
      getListDetail(params?.orderId, params?.activityId);
    }
  }, [searchParams?.get("orderId"), searchParams?.get("activityId")]);
  return (
    <div className="activity-page">
      <TopBanner info={listInfo} />
      <ActivityGoodsList
        info={listInfo}
        orderId={params.orderId}
        activityId={params.activityId}
      />
    </div>
  );
}
export default ActivityList;
