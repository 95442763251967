import { useParams } from "react-router-dom";
import { getCDNUrl } from "@/config/index";
import "./css/index.css";
function Protocol() {
  const params: any = useParams();
  const { channelId = "", protocolName = "" } = params;
  return (
    <div className="bg-content">
      <div className="iframebox">
        <iframe
          src={`${getCDNUrl()}/protocol/${channelId}/${protocolName}.html`}
          width={1080}
          className="infram-content"
        />
      </div>
    </div>
  );
}
export default Protocol;
