import "./css/liveList.scss";
import { liveProps } from "@/utils/shopDecorateProps";
import { getLiveList, getLiveNum } from "@/apis/live";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { setPlayUnit } from "@/utils/storageFactory";
import { handleBread } from "@/utils/bread";
import { getUnitDetail, getReplayList } from "@/apis/courseDetail";
const LiveList = (props: liveProps) => {
  const {
    showMore,
    showTitle,
    showNum,
    list,
    title,
    layout,
    showStatus,
    customStyle,
  } = props;
  const navigate = useNavigate();
  const statusName = ["未开始", "直播中", "已结束", "待预约", "看回放"];
  const [liveData, setLiveData] = useState<any[]>([]);
  const [scrollPosition, setScrollPosition] = useState(0);

  const handleScrollRight = () => {
    const element = document.getElementById("scroll-list");
    let x = element?.scrollWidth;
    console.log(scrollPosition, x, "555555");
    if (x && x - scrollPosition > 458 * 2) {
      setScrollPosition(scrollPosition + 458);
    }
  };

  const handleScrollLeft = () => {
    if (scrollPosition === 0) {
      return;
    } else {
      setScrollPosition(scrollPosition - 458);
    }
    console.log(scrollPosition, "444444");
  };
  const getDataList = async (list: any) => {
    const res = await getLiveList(list);
    const data = res.filter((item: any) => {
      const flag = showStatus.includes("" + item.liveRoomStatus);
      // 未购买并且直播未开始（待预约）
      if (!item.authorizeCode && item.liveRoomStatus == 0) {
        item.liveRoomStatus = 3;
      }
      if (item.replayStatus == 1 && item.liveRoomStatus == 2) {
        // 已结束，并且已生成回看
        item.liveRoomStatus = 4;
      }
      return flag;
    });
    // setLiveData(data);
    fechLiveNum(data);
  };

  const fechLiveNum = async (data: any[]) => {
    setLiveData(data);
    const prames = data.filter((item) => {
      const flag = item.liveRoomStatus == 1;
      return flag;
    });
    // 正在直播获取在线人数
    if (prames && prames.length > 0) {
      const ids = prames.map((item) => {
        return item.ccliveRoomId;
      });
      const res = await getLiveNum(ids);
      if (res) {
        const liveArr = data.map((live) => {
          let obj = res.find((item: any) => {
            return item.liveRoomId == live.ccliveRoomId;
          });
          if (obj) {
            live.num = obj.number;
          }
          return live;
        });
        setLiveData(liveArr);
      }
    }
  };

  const fromTime = (time: string) => {
    const dateTime = time.split(" ");
    const dateArr = dateTime[0].split("-");
    const timeArr = dateTime[1].split(":");
    return [
      `${parseInt(dateArr[1])}月${parseInt(dateArr[2])}日`,
      `${timeArr[0]}:${timeArr[1]}`,
    ];
  };
  // 跳转
  const gotoList = async (live: any) => {
    if (live.authorizeCode) {
      // 已购买
      const unitDetailRes = await getUnitDetail({ code: live.authorizeCode });
      // 待直播和直播中
      if (unitDetailRes.status === 0 || unitDetailRes.status === 1) {
        setPlayUnit("", live.productId, "", live.liveUnitId);
        window.open(
          `${window.location.origin}/#/videoPlay?commodityId=${
            live.commodityId
          }&isPurchase=${true}`
        );
      } else {
        const res = await getReplayList({
          productId: live.productId,
          liveRoomId: unitDetailRes.liveRoomId,
        });
        if (res.length > 1) {
          window.open(
            `${window.location.origin}/#/liveReplay?productId=${live.productId}&liveRoomId=${unitDetailRes.liveRoomId}&unitName=${live.liveUnitName}`
          );
        } else {
          window.open(
            `${window.location.origin}/#/videoPlay?commodityId=${
              live.commodityId
            }&isPurchase=${true}`
          );
        }
      }
    } else {
      // 未购买
      navigate(`/courseDetail?id=${live.commodityId}`, {
        state: { curTab: 1 },
      });
    }
  };
  const gotoDetail = () => {
    handleBread({ title: `全部直播`, href: `/allLive/` });
    const obj = {
      showMore: false,
      showTitle: false,
      showNum: 999999,
      list,
      title: "",
      layout: 0,
      showStatus,
    };
    navigate(`/allLive/`, {
      state: {
        showObj: obj,
      },
    });
  };
  useEffect(() => {
    list?.length > 0 && getDataList(list);
    setScrollPosition(0);
    // getDataList(list)
  }, [list]);
  return (
    <div className="live-list-box" style={customStyle}>
      <div style={{ position: "relative" }}>
        {showTitle ? (
          <div className="title-header">
            <span className="bord-16">{title}</span>
            {showMore ? (
              <span className="more-button" onClick={() => gotoDetail()}>
                查看更多<span className="iconfont icon-symbol_right"></span>
              </span>
            ) : null}
          </div>
        ) : null}
        <div
          style={{
            position: "relative",
            overflow: layout == 3 ? "hidden" : "visible",
          }}
        >
          {layout == 3 ? (
            <div className="left-button" onClick={handleScrollLeft}>
              <i className="iconfont icon-symbol_left"></i>
            </div>
          ) : null}
          {layout == 3 ? (
            <div className="right-button" onClick={handleScrollRight}>
              <i className="iconfont icon-symbol_right"></i>
            </div>
          ) : null}
          <div
            className={`list-layout-${layout}`}
            id="scroll-list"
            style={{ transform: `translateX(-${scrollPosition}px)` }}
          >
            {liveData &&
              liveData.map((live: any, index: number) => {
                if (index < showNum) {
                  return (
                    <div
                      className="item-box"
                      key={index}
                      onClick={() => gotoList(live)}
                      style={{
                        marginRight:
                          layout == 3
                            ? "20px"
                            : (index + 1) % (layout == 0 ? 4 : 2) == 0
                            ? 0
                            : "2%",
                      }}
                    >
                      <div className={`live-img-box-${layout}`}>
                        <img
                          className="img-bg"
                          src={
                            live.coverImg
                              ? live.coverImg
                              : require("./images/live-bg-pc.png")
                          }
                        ></img>
                        <div
                          className={`tag-status tag-status-${live.liveRoomStatus}`}
                        >
                          {
                                live.liveRoomStatus === 1 ? (
                           <img src={require("./images/live-tag.png")} alt="Live Tag" />
                           ) : null
                          }
                          {statusName[parseInt(live.liveRoomStatus)]}
                        </div>
                        {live.authorizeCode ? (
                          <span className="live-btn1">点击进入直播间</span>
                        ) : (
                          <div className="live-cover1">
                            <span className="live-btn1 live-btn-lock1">
                              {" "}
                              <img
                                src={require("../LiveList/images/live-lock.png")}
                              />
                              点击购买观看直播
                            </span>
                          </div>
                        )}
                      </div>
                      <div className="content-box">
                        {layout == 1 ? (
                          <div className="ellipsis-two live-name">
                            {live.liveUnitName}
                          </div>
                        ) : (
                          <span className="ellipsis-two live-name">
                            {live.liveUnitName}
                          </span>
                        )}
                        <div className="time-box">
                          {live.liveRoomStatus == 1 ? (
                            <span className="info gay-color">{`${
                              live.num ? live.num : 0
                            }人在线`}</span>
                          ) : null}
                          {live.liveRoomStatus == 0 ||
                          live.liveRoomStatus == 3 ? (
                            <div className="flex-center">
                              <img
                                src={require("./images/live-time.png")}
                                className="icon-img"
                              ></img>
                              {fromTime(live.liveStartTime)[0]}
                              <span className="red-color">
                                &nbsp;{fromTime(live.liveStartTime)[1]}&nbsp;
                              </span>
                              开始
                            </div>
                          ) : null}
                        </div>
                      </div>
                    </div>
                  );
                }
              })}
          </div>
        </div>
      </div>
    </div>
  );
};
export default LiveList;
