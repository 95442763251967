import { Empty } from "antd";
import "./css/index.scss";
interface Iprops {
  data: any;
  title: string;
  goShowMore?: any;
  showMore: boolean;
  changeProject?: any;
  goCourseDetail: any;
  checkMoreByPro?: Function;
  showNum: number;
  showByProject?: boolean;
  showTitle: boolean;
  fields: any;
}
const FourInLine = (props: Iprops) => {
  // const { item, indexZ, goCourseDetail } = props;
  const {
    goShowMore,
    showMore,
    changeProject,
    goCourseDetail,
    checkMoreByPro,
    title,
    showNum,
    showByProject,
    showTitle,
    fields,
  } = props;
  const { projectList, currentTab, courseCard } = props.data;
  console.log(courseCard, "courseCard---");

  const CardItem = (item: any, indexZ: any) => {
    return (
      <div
        key={indexZ}
        className={
          indexZ > 3
            ? "swiper-slide setmeal-item btn-cursor setmeal-seckill child card-all-top "
            : '"swiper-slide setmeal-item btn-cursor setmeal-seckill child"'
        }
        onClick={() => goCourseDetail(item)}
      >
        <div className="img-content2">
          <img src={item.thumbnail} className="setmeal-pic" />
          {fields.includes("peoples") ? (
            <div className="sellingnumber2">
              {" "}
              {item.salesVolume ? item.salesVolume : 0}人已关注
            </div>
          ) : null}
        </div>

        {fields.includes("name") ? (
          <div className="setmeal-title line-one">
            {item.name}
            {fields.includes("sellPoint") ? (
              <div className="course-label-introduction line-one">
                <span>{item?.sellingPoint}</span>
              </div>
            ) : null}
          </div>
        ) : null}

        <div className="bottom-box">
          <div
            className={`teacher-box ${
              fields.includes("teachers") ? "show-teachers" : ""
            }`}
          >
            {fields.includes("teachers") && item?.teachers?.length > 0 ? (
              <div style={{ display: "flex" }}>
                {item.teachers.map((child: any, index: number) => {
                  if (index < 3) {
                    return (
                      <div className="teacher-one" key={index}>
                        <img src={child.photo} className="teacher-img-l" />
                        {fields.includes("name") && (
                          <div className="teacher-name-l">
                            {child?.name?.substr(0, 4)}
                          </div>
                        )}
                      </div>
                    );
                  }
                })}
              </div>
            ) : null}
          </div>
          {fields.includes("price") ? (
            <div className="price-box">
              <div className="line-price">
                <span>￥</span>
                <span className="false-price">{item.linePrice}</span>
                <span>起</span>
              </div>
              <div className="real-price">
                <span className="ele">￥</span>
                <span className="true-price">{item.price}</span>
                <span className="ele2">起</span>
              </div>
            </div>
          ) : null}
        </div>
      </div>
    );
  };
  return (
    <div className="setmeal-group seckill-group">
      <div className="group-top clear">
        <div className="top-all" style={{ paddingTop: 24, paddingBottom: 20 }}>
          {showTitle ? (
            <div className="group-title activity-title fl">{title}</div>
          ) : null}
          {/* <br /> */}
          {showMore && showTitle ? (
            <div className="show-morename" onClick={() => goShowMore()}>
              查看更多{" "}
              <span className=" more-icon-01 iconfont icon-symbol_right"></span>
            </div>
          ) : null}
        </div>
        {showByProject ? (
          <div
            className={
              showByProject && projectList.lengrh > 0
                ? "show-more fl"
                : "projectList fl"
            }
            style={{ paddingBottom: 28 }}
          >
            <ul
              className="exam-list fl exam-list1"
              style={{
                width: "100%",
                display: "flex",
              }}
            >
              {projectList.map((item: any, index: Number) => {
                return (
                  <li
                    onClick={() => changeProject(item, index)}
                    key={item.projectId}
                    data-classNameify="seckill"
                    data-sign="jz1"
                    className={
                      currentTab === index
                        ? "exam-item exam-active"
                        : "exam-item"
                    }
                    // style={{ marginLeft: index == 0 ? 0 : 40 }}
                  >
                    {item.projectName}
                  </li>
                );
              })}
            </ul>
          </div>
        ) : null}
      </div>

      <div className="setmeal-swiper container" style={{ width: "100%" }}>
        <div id="seckillSwiper" className="swiper-container">
          <div
            className={
              courseCard.length > 0 ? "swiper-wrapper parent" : "empty-content"
            }
          >
            {courseCard && courseCard.length > 0 ? (
              courseCard.map((item: any, indexZ: number) => {
                // if (indexZ != courseCard.length - 1) {
                //   return ;
                // } else if (courseCard.length > 7) {
                //   return (
                //     <div
                //       key={indexZ}
                //       className={
                //         indexZ > 3
                //           ? "swiper-slide setmeal-item btn-cursor setmeal-seckill child card-all-top"
                //           : '"swiper-slide setmeal-item btn-cursor setmeal-seckill child"'
                //       }
                //       onClick={() => checkMoreByPro && checkMoreByPro()}
                //     >
                //       <div className="course-label-introduction-more">
                //         查看更多
                //         <span className="more-icon iconfont  icon-symbol_right"></span>
                //       </div>
                //     </div>
                //   );
                // }
                {
                  return showNum > indexZ ? (
                    <>{CardItem(item, indexZ)}</>
                  ) : null;
                }
              })
            ) : (
              <Empty
                description="暂无数据"
                style={{
                  minHeight: 300,
                  textAlign: "center",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              />
            )}

            {/* {courseCard.map((item: any, indexZ: number) => {
              
            })} */}
          </div>
        </div>
      </div>
    </div>
  );
};
export default FourInLine;
