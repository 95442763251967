import React, { useEffect, useState } from "react";
import { Modal } from "antd";
import "./css/courseExchangeModal.scss";
import { getCommodityProducts } from "../../apis/exchange";
interface Iprops {
  isShow: boolean; // 是否展示弹框
  onCloseQrCode: Function; // 关闭弹框回调
  courseExchangeList: any; // 列表数据
  skuId: string; // skuId
}
function CourseExchangeList(props: Iprops) {
  const { onCloseQrCode } = props;
  const {skuId}=props;
  const {courseExchangeList}=props;
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [exchangeModalList, setExchangeModalList] = useState([111,222]);



  const getExchangeModalList=async () => {
      const res = await getCommodityProducts({skuId});
        setExchangeModalList(res);
       
            
  }

  
  useEffect(() => { 
    getExchangeModalList();
    // getExchangeModalList(skuId);
    setIsModalOpen(props.isShow);
  }, );

  const handleCancel = () => {
    onCloseQrCode(false);
  };
  const startStudy = () => {
    console.log("开始学习");
  };
  return (
    <Modal
      title=""
      centered
      open={isModalOpen}
      onCancel={handleCancel}
      footer={null}
      wrapClassName={"qrcode-pop-wrap"}
      width={680}
    >
      <div className="course-list">
        {exchangeModalList.length!=0 ?
          (exchangeModalList.map((items:any, index:any) => (
            <div className="course-list-card" key={items.k}>
              <img
                className="course-list-img"
                alt="course-list-img"
                src={require("./images/course-cover.png")}
              />
              <div className="course-list-info">
                <div className="course-list-title">{items.v}</div>
                <div className="course-list-use-time">{items.signDay}</div>
                <button className="course-list-button" onClick={startStudy}>
                    开始学习
                </button>
              </div>
            </div>
            ))
          )
         :(
            <div className="recordEmptyBox">
              <div className="recordEmptyImg"></div>
            </div>
          )}
        
        {/* <div className="course-list-card">
          <img
            className="course-list-img"
            alt="course-list-img"
            src={require("./images/course-cover.png")}
          />
          <div className="course-list-info">
            <div className="course-list-title">课程名称</div>
            <div className="course-list-use-time">购买课程时间</div>
            <button className="course-list-button" onClick={startStudy}>
              开始学习
            </button>
          </div>
        </div> */}
      </div>
    </Modal>
  );
}

export default CourseExchangeList;
