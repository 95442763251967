/* eslint-disable  */
import React, { useState, useEffect } from "react";

import "../css/videoPlay.scss";
function PopSign(props:any) {
  const {handleOk,handleCancel} = props
  return (
    <div>
      <div className="pop-title">温馨提示</div>
      <div className="pop-info">尊敬的学员：<br></br>
      为保障您的消费权益，请实名签署《产品协议》，否则无法学习哦～ 
      </div>
      <div className="pop-bottom">
        <div className="pop-close" onClick={handleCancel}>关闭</div>
        <div className="pop-srue" onClick={handleOk}>签署协议</div>
      </div>
    </div>
  );
}

export default PopSign;