/* eslint-disable  */
import React, { useState } from "react";
import { LeftOutlined, RightOutlined } from "@ant-design/icons";
import "../../VideoPlay/css/videoLeft.scss";
import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration'
dayjs.extend(duration)
function ReplayLeft(props: any) {
  const [isOpen, setIsOpen] = useState(true);
  const { callback, unitName, list} = props;
  const [showOpen, setShowOpen] = useState(true);
  const [curIndex, setCurIndex] = useState(0);
  const openClose = ()=>{
    const status = !showOpen
    setShowOpen(status)
  }
  // 格式化时间
  const formatTime = (m: number) => {
    if (m < 60) {
      return m + '秒';
    }
    if (m < 3600) {
      // eslint-disable-next-line dot-notation
      return dayjs['duration'](m, 's').format('mm分钟ss秒');
    }
    // eslint-disable-next-line dot-notation
    return dayjs['duration'](m, 's').format('HH小时mm分钟ss秒');
  };
  // 直播回放点击
  const liveClick = (item:any,index:number)=>{
    setCurIndex(index)
    callback(item.playUrl)
  }
  const renderItem = (item:any,index:number)=>{
    return <div onClick={()=>{liveClick(item,index)}} className={  index==curIndex ? 'group5-child-video group5-child-active': 'group5-child-video'} key={index}>
      <div className="group6-child">
        <div className="children6-child">
          <div className="icon-videocast-white"></div>
        </div>
        <span className="text4-child-video text4-child">{item.replayName}</span>
      </div>
      <div className="group7-child">
        <div style={{display:"flex",flexDirection:'column'}}>
          <span className="text5" style={{marginBottom:'8px',color:'#3c80ef'}}>{formatTime(item.videoDuration)}</span>
          <span className="text5" style={{color:'#ffffff'}}>{`${item.startTime} 至 ${item.endTime}`}</span>
        </div>
        <div className="group12-child btn-pointer">
          <span className="text9-child">
            { index==curIndex ? <div className="iconfont icon-playing1" style={{marginRight:'4px'}}></div> :null}
          {index==curIndex ? '播放中':'播放'}</span>
        </div></div>
    </div>
  }

  return (
    <div className="video-left-box">
      {/* 回放列表 */}
      {isOpen ? (
        <div className="video-tree">
          <div className="group3Top" onClick={openClose}>
            {
              showOpen ? <div className="expand_1_1_shixue"></div> : <div className="expand_1_2_shixue"></div>
            }
            <span className="text1Wrap text1-video">{unitName}</span>
          </div>
          { showOpen ? <div>
            {
              list && list.map((item:any,index:number) => {
                return  renderItem(item,index)
              })
            }
          </div> : null }
        </div>
      ) : null}
      {/* 目录 */}
      <div className="video-left-menu">
        <div
          className={
            isOpen ? "directory-box directory-box-active" : "directory-box"
          }
          onClick={() => {
            if (isOpen) {
              setIsOpen(false);
            } else {
              setIsOpen(true);
            }
          }}
        >
          <div className="iconfont icon-directory"></div>
          <div>回放列表</div>
        </div>
      </div>
      {/* 抽屉触发 */}
      <div
        className="video-left-change"
        onClick={() => {
          if (isOpen) {
            setIsOpen(false);
          } else {
            setIsOpen(true);
          }
        }}
      >
        {isOpen ? (
          <RightOutlined></RightOutlined>
        ) : (
          <LeftOutlined></LeftOutlined>
        )}
      </div>
    </div>
  );
}
export default ReplayLeft;
