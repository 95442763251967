/*
  学习引导-查看更多
*/
import { useEffect, useState } from "react";
import BreadCrumb from "@/components/BreadCrumb";
import { useLocation } from "react-router-dom";
import bookmark from "../../components/StudyGuide/images/bookmark.png";
import { getAllStudy } from "@/apis/studyGuide";
import { useNavigate } from "react-router-dom";
import { setPlayUnit } from "@/utils/storageFactory";
import { Button } from "antd";
function AllLive() {
  const location = useLocation();
  const showItem = location.state || {};
  const [show, setshow] = useState(true);
  // 使用useEffect钩子在组件挂载后调用getAll函数
  useEffect(() => {
    getAll();
  }, []); // 空依赖数组表示仅在组件挂载时执行
  const navigate = useNavigate();
  interface BodyItem {
    averageSpeedRate: number;
    commodity: {
      id: string;
      name: string;
      skuName: string[];
      thumbnail: string;
    };
    commodityId: string;
    doneDuration: number;
    donePage: number;
    examDone: number;
    latestStudyTime: string;
    practiceDone: number;
    realPlayedTime: number;
    unitName: string;
    unitId: number;
    section: string;
    projectId: string;
    productId: string;
  }

  const [body, setBody] = useState<BodyItem[]>([]);
  const getAll = async () => {
    const res = await getAllStudy();

    if (Object.keys(res).length === 0 || res[0].isPurchase === false) {
      setshow(false);
    } else {
      setshow(true);
      // 使用 setBody 更新 body 状态
      //当数据为空或者为0时不展示
      setBody(res);
      console.log(body);
    }
  };
  const toStudy = (productId: string) => {
    setPlayUnit("", "", "", ""); //清空当前的选中
    navigate(`/videoPlay?productId=${productId}&isPurchase=true`);
  };
  const getHour = (time: number) => Math.floor(time / 3600);
  const getMin = (time: number) => Math.floor((time % 3600) / 60);
  const getSeconds = (time: number) => time % 60;
  return (
    <div className="information-content-outwrap">
      <BreadCrumb />
      {/* 内容 */}
      {body.map((item,index) =>
        show ? (
          <div className={`all-content ${index === 0 ? 'first-content' : ''}`}>
            <div className="box-card">
            <div className="good">
                  <img
                    className="good-image"
                    src={item.commodity.thumbnail}
                    alt="商品缩略图"
                  />
                  <div className="good-right">
                    <div className="good-course">
                      {item.commodity.name}
                    </div>
                    <p className="good-subject">
                      按照科目：{item.commodity.skuName}
                    </p>
                    {/* 进度条 */}
                    <div className="process">
                      <div
                        className="process-left"
                        style={{ width: `${item.averageSpeedRate * 100}%` }}
                      />
                      <div
                        className="process-label"
                        style={{
                          left: `${(828 - 50) * item.averageSpeedRate}px`,
                        }}
                      >
                        {Math.floor(item.averageSpeedRate * 100)}%{" "}
                      </div>
                    </div>
                </div>
              </div>

              {/* 数据概览 */}
              {showItem ? (
                <div>
                  {getSeconds(item.realPlayedTime) ? (
                    <div className="course">
                      {getHour(item.realPlayedTime)}
                      <span className="light-label">小时 </span>
                      {getMin(item.realPlayedTime)}
                      <span className="light-label">分 </span>
                      {getSeconds(item.realPlayedTime)}
                      <span className="light-label">秒 </span>
                      <span className="course-label">视频</span>
                    </div>
                  ) : null}
                  {item.practiceDone ? (
                    <div className="course">
                      {item.practiceDone}
                      <span className="light-label">题 </span>
                      <div className="course-label">练习</div>
                    </div>
                  ) : null}
                  {item.examDone ? (
                    <div className="course">
                      {item.examDone}
                      <span className="light-label">题 </span>
                      <div className="course-label">考试</div>
                    </div>
                  ) : null}
                  {item.donePage ? (
                    <div className="course">
                      {item.donePage}
                      <span className="light-label">页 </span>
                      <div className="course-label">资料</div>
                    </div>
                  ) : null}
                  {getSeconds(item.doneDuration) ? (
                    <div className="course">
                      {getHour(item.doneDuration)}
                      <span className="light-label">小时 </span>
                      {getMin(item.doneDuration)}
                      <span className="light-label">分 </span>
                      {getSeconds(item.doneDuration)}
                      <span className="light-label">秒 </span>
                      <div className="course-label">直播</div>
                    </div>
                  ) : null}
                </div>
              ) : null}
              {/* 底部课程 */}
              <p className="last">上次学到:</p>
              <div className="bottom">
                <div className="bottom-cotainer">
                  <img src={bookmark} className="bottom-icon" alt="书签" />
                  <h3 className="bottom-course">{item.unitName}</h3>
                </div>
                <Button
                  className="study-button"
                  onClick={() => toStudy(item.productId)}
                >
                  去学习
                </Button>
              </div>
            </div>
          </div>
        ) : null
      )}
    </div>
  );
}

export default AllLive;
