import { request } from "@/utils/request"

// 获取店铺装修模板
export const requestShopDecorateTemplate = (data: any) => {
  return request({
    url: `/base/shopDecorateTemplate/getTemplate`,
    method: "POST",
    data,
    headers: {
      isLoading: true
    }
  })
}


