import "./css/liveSingle.scss";
import { liveIngProps } from "@/utils/shopDecorateProps";
import { useEffect, useState } from "react";
import { getLiveIng, getLiveNum } from "@/apis/live";
import { setPlayUnit } from "@/utils/storageFactory";
import { useNavigate } from "react-router-dom";
import { getClientUrl } from "@/config/index";

declare const window: any;
const LiveSingle = (props:liveIngProps) => {
  const {
      showTitle,
      list,
      title,
      customStyle,
  } = props
  const navigate = useNavigate();
  const [show, setShow] = useState(false);
  const [curLive, setCurLive] = useState({
    thumbnail: '',
    name: '',
    status: '1',
    num: 0,
    prunch: false,
    code: '',
    commodityId: '',
    liveUnitId:'',
    productId:'',
  });
  const [uri, setUri] = useState('');
  const getLiveList = async(ids:any[]) => {
    const res = await getLiveIng(ids)
    if (res.livingRoomInfo) {
      setShow(true);
      const {
        coverImg,
        liveUnitName,
        liveRoomStatus,
        authorizeCode,
        ccliveRoomId,
        uid,
        // nums,
        playPass,
        commodityId,
        liveUnitId,
        productId,
      } = res.livingRoomInfo;

      const  data = {
        thumbnail: coverImg ? coverImg : require("../LiveList/images/live-tag.png"),
        name: liveUnitName,
        status: liveRoomStatus,
        num: 0,
        prunch: authorizeCode ? true : false,
        code: authorizeCode,
        commodityId,
        liveUnitId,
        productId,
      }
      setCurLive(data);
      fechLiveNum(ccliveRoomId,data)
      const url = getClientUrl()
      setUri(`${url}/h5/#/live?roomId=${ccliveRoomId}&userId=${uid}&pass=${playPass}`);
    } else {
      setShow(false);
    }
  }

  const fechLiveNum = async (ccliveRoomId:string,data:any) =>{
    const resNum = await getLiveNum([ccliveRoomId]);
    const num = resNum[0].number || 0;
    const live = {...data,num}
    setCurLive(live);
  };

  // 跳转
  const gotoList = async () => {
      if (curLive.prunch) {
        setPlayUnit('', curLive.productId, '', curLive.liveUnitId);
        window.open(
          `${window.location.origin}/#/videoPlay?commodityId=${curLive.commodityId}&isPurchase=${curLive.prunch}`
        );
      } else {
        navigate(`/courseDetail?id=${curLive.commodityId}`);
      }
  };
  useEffect(() => {
    loadData()
      // getLiveList(ids)
  }, [list]);

  const loadData = ()=>{
    const ids =
    list?.map((item) => {
        return {
          commodityId: item.commodityId,
          productId: item.productId,
          liveRoomId: item.liveRoomId,
          liveUnitId: item.liveUnitId,
        };
    }) || [];
    ids?.length > 0 && getLiveList(ids);
  }

  const liveEnd = ()=>{
    console.log('liveEnd')
    loadData()
  }
  // 监听直播结束
  useEffect(() => {
    window.addEventListener('liveEnd', liveEnd);
    return () => {
      window.removeEventListener('liveEnd', liveEnd);
    };
  }, []);

  return show ? (
        <div className="live-single-box" style={customStyle}>
          {
            showTitle ? <div  className="title-header">
            <span className="bord-16">{ title }</span>
          </div> : null
          } 
            <div className="live-sing" onClick={() => gotoList()}>
              {/* <div className="live-video">
                  <img className="img-bg" src={curLive.thumbnail}></img>
              </div> */}
              <div className="live-video">
                <iframe src={uri}></iframe>
                { curLive.prunch ?  <span className="live-btn">点击进入直播间</span> : 
                      <div className="live-cover">
                          <span className="live-btn live-btn-lock"> <img src={require("../LiveList/images/live-lock.png")} />点击购买观看直播</span>
                      </div>
                }
              </div>
              <div className="content-single-box">
                <div className="ellipsis-two live-name">{curLive.name}</div>
                <div className="info ">{curLive.num?curLive.num:0}人在线</div>
              </div>
              <div className={`tag-status tag-status-${curLive.status}`}><img src={require("../LiveList/images/live-tag.png")} />直播中</div>
            </div>
      </div>
    ):null
};
export default LiveSingle