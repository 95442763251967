import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Col, Row } from "antd";
import PayModal from "@/components/PayModal";
import userStore from "@/store/user";
import "../css/MyOrder.scss";

const statusList = ["待付款", "已付款", "已关闭", "退款中", "已退款", "拼团中"];
const expressList = ["待发货", "待收货", "已完成"];
const CountDown: React.FC<{ remainder: number }> = ({ remainder }) => {
  let timer: any = null;
  const [timeData, setTimeData] = useState({
    hour: "0",
    minute: "0",
    second: "0",
  });
  const checkTime = (i: number) => {
    let s = i.toString();
    if (i < 10) {
      s = "0" + i;
    }
    return s;
  };
  const updateTimeData = (t: number) => {
    let hour = Math.floor(t / 1000 / 60 / 60);
    let minute = Math.floor((t / 1000 / 60) % 60);
    let second = Math.floor((t / 1000) % 60);
    setTimeData({
      hour: checkTime(hour),
      minute: checkTime(minute),
      second: checkTime(second),
    });
  };
  // 开启倒计时
  const startTimer = () => {
    if (timer) {
      clearInterval(timer);
    }
    if (remainder < 1000) {
      return;
    }
    let timeDown = remainder;
    timer = setInterval(() => {
      timeDown -= 1000;
      updateTimeData(timeDown);
      if (timeDown < 1000) {
        clearInterval(timer);
      }
    }, 1000);
  };
  useEffect(() => {
    updateTimeData(remainder);
    startTimer();
    return () => {
      if (timer) {
        clearInterval(timer);
      }
    };
  }, [remainder]);
  return (
    <div className="order-count-down">
      <div style={{ marginTop: "4px", marginBottom: "4px" }}>
        <span>{timeData.hour}:</span>
        <span>{timeData.minute}:</span>
        <span>{timeData.second}</span>
      </div>
      <p>后关闭</p>
    </div>
  );
};

const OrderItem: React.FC<{
  odrer: any;
  hanleCloseOrder: Function;
  reFillAddress: Function;
}> = ({ odrer, hanleCloseOrder, reFillAddress }) => {
  const navigate = useNavigate();
  const [isShowPayModal, setIsShowPayModal] = useState<any>(false); // 打开支付弹框
  const [orderId, setOrderId] = useState(""); // 订单号
  let status = statusList[odrer.status];
  if (
    odrer.commodities &&
    odrer.commodities[0]?.type === 3 &&
    odrer.status === 1
  ) {
    status = expressList[odrer.expressStatus];
  }
  // 支付
  const goPay = ({ id = "", payAmount = 0, supplierId = "" }) => {
    userStore.setSupplierId(supplierId);
    setOrderId(id);
    setIsShowPayModal(true);
  };
  // 查看详情
  const previewDetail = (val: any) => {
    userStore.setSupplierId(val.supplierId);
    navigate(`/orderDetail/${val.id}`);
    console.log(val, "valvalval");
  };
  // 取消订单
  const cancelOrder = (val: any) => {
    userStore.setSupplierId(val.supplierId);
    // console.log(val);
    hanleCloseOrder(val);
  };
  // 立即学习
  const goStudy = (val: any) => {
    userStore.setSupplierId(val.supplierId);
    console.log(val);
    navigate("/personalCenter?topNavIndex=0");
  };
  // 签署协议
  const goSign = (val: any) => {
    userStore.setSupplierId(val.supplierId);
    navigate(
      `/Agreement?pdfurl=${val?.contractUrl}&orderContractId=${val.historyId}&isOrder=true&goodsId=${val.commodities?.[0]?.id}&redirectUrl=personalCenter?topNavIndex=2`
    );
  };
  // 关闭弹框
  const handleClose = () => {
    setIsShowPayModal(false);
  };
  return (
    <div className="order-item" key={odrer.id}>
      <div className="order-item-info flex">
        <div>
          <span style={{ marginLeft: "12px", marginRight: "20px" }}>
            订单编号:{odrer.id}
          </span>
          <span>下单时间:{odrer.createTime}</span>
        </div>
        {odrer.activityFlagMsg ? (
          <div className="add-level-title">{odrer.activityFlagMsg}</div>
        ) : null}
        {/* <div className="add-level-title">{odrer.activityFlagMsg}</div> */}
      </div>
      <Row>
        <Col span={15}>
          {odrer.commodities &&
            odrer.commodities.map((goods: any, index: number) => {
              return (
                <Row key={goods.skuId}>
                  <Col
                    className={
                      index === odrer.commodities.length - 1
                        ? "order-goods order-border-right"
                        : "order-goods order-border-right order-border-bottom"
                    }
                    span={19}
                  >
                    <img
                      className="order-goods-img"
                      src={goods.url}
                      alt=""
                    ></img>
                    <div>
                      <p style={{ marginBottom: "8px" }}>{goods.name}</p>
                      <p className="order-color-gray order-min-font">
                        {goods.skuName ? goods.skuName : ""}
                      </p>
                    </div>
                  </Col>
                  <Col
                    className={
                      index === odrer.commodities.length - 1
                        ? "order-border-right order-center order-color-gray"
                        : "order-border-right order-border-bottom order-center order-color-gray"
                    }
                    span={5}
                  >
                    <p>{goods.price}</p>
                  </Col>
                </Row>
              );
            })}
        </Col>
        <Col className="order-border-right order-center" span={3}>
          {odrer.payAmount}
        </Col>
        <Col className="order-border-right order-center" span={3}>
          <p>{status}</p>
          {odrer.remainder ? (
            <CountDown remainder={odrer.remainder}></CountDown>
          ) : null}
        </Col>
        {odrer.commodities[0]?.type !== 3 ? (
          <Col className="order-center" span={3}>
            {/* {status == "待付款" ? (
              <p className="pay-btn-order" onClick={() => goPay(odrer)}>
                立即付款
              </p>
            ) : odrer?.needSign ? (
              <p className="other-btn-order" onClick={() => goSign(odrer)}>
                签署协议
              </p>
            ) : status == "已付款" ? (
              <p className="other-btn-order" onClick={() => goStudy(odrer)}>
                立即学习
              </p>
            ) : null} */}
            {status == "待付款" ? (
              <p className="pay-btn-order" onClick={() => goPay(odrer)}>
                立即付款
              </p>
            ) : null}
            {status == "已付款" ? (
              odrer?.needSign ? (
                <p className="other-btn-order" onClick={() => goSign(odrer)}>
                  签署协议
                </p>
              ) : odrer?.activityFlag == 100 ||
                odrer?.activityFlag == 101 ||
                odrer?.activityFlag == 200 ||
                odrer?.activityFlag == 201 ? (
                ""
              ) : (
                <p className="other-btn-order" onClick={() => goStudy(odrer)}>
                  立即学习
                </p>
              )
            ) : null}
            {/* {status == "待付款" ? (
              <p className="pay-btn-order" onClick={() => goPay(odrer)}>
                立即付款
              </p>
            ) : status == "已付款" ? (
              odrer?.needSign ? (
                <p className="other-btn-order" onClick={() => goSign(odrer)}>
                  签署协议
                </p>
              ) : (
                <p className="other-btn-order" onClick={() => goStudy(odrer)}>
                  立即学习
                </p>
              )
            ) : null} */}
            <p className="other-btn-order" onClick={() => previewDetail(odrer)}>
              查看详情
            </p>
            {status == "待付款" ? (
              <p className="other-btn-order" onClick={() => cancelOrder(odrer)}>
                取消订单
              </p>
            ) : null}
            {odrer?.needAddress ? (
              <p className="pay-btn-order" onClick={() => reFillAddress(odrer)}>
                补填地址
              </p>
            ) : null}
          </Col>
        ) : null}
      </Row>
      {isShowPayModal ? (
        <PayModal onClose={handleClose} orderId={orderId} />
      ) : null}
    </div>
  );
};
export default OrderItem;
