import React, { useEffect, useState, memo } from "react";
import { useNavigate } from "react-router-dom";
import { Modal } from "antd";
import "./css/index.scss";

interface Iprops {
  type?: Number; // 当前活动类型 0-升级购
  info: any;
  toBuy: any;
}

const ActivityModal = (props: Iprops) => {
  const { type = 1, info, toBuy } = props; // 0-升级购 1-换购
  const navigate = useNavigate();
  const [isModalOpen, setIsModalOpen] = useState(true);
  const [remain, setRemain] = useState(0);
  const [timeData, setTimeData] = useState({
    day: 0,
    hour: 0,
    min: 0,
    second: 0,
  });
  useEffect(() => {
    setRemain(info?.endTime || 0);
  }, [info]);
  useEffect(() => {
    if (remain) {
      reset();
    }
  }, [remain]);
  // 时间补全 0
  const checkTime = (i: any) => {
    if (i < 10) {
      i = "0" + i;
    }
    return i;
  };
  const updateTimeData = (t: any) => {
    let day = Math.floor(t / 1000 / 60 / 60 / 24);
    let hour = Math.floor((t / 1000 / 60 / 60) % 24);
    let minute = Math.floor((t / 1000 / 60) % 60);
    let second = Math.floor((t / 1000) % 60);
    setTimeData({
      day: checkTime(day),
      hour: checkTime(hour),
      min: checkTime(minute),
      second: checkTime(second),
    });
  };

  let timer: any = null;
  // 开启倒计时
  const startTimer = () => {
    if (timer) {
      clearInterval(timer);
    }
    if (remain < 1000) {
      return;
    }
    let timeDown = remain;
    timer = setInterval(() => {
      timeDown -= 1000;
      updateTimeData(timeDown);
      if (timeDown < 1000) {
        clearInterval(timer);
      }
    }, 1000);
  };

  // 重置倒计时
  const reset = () => {
    updateTimeData(remain);
    start();
  };
  // 开始倒计时
  const start = () => {
    if (timer) {
      return;
    }
    startTimer();
  };
  const handleCancel = () => {
    console.log("取消");
    setIsModalOpen(false);
  };
  return (
    <Modal
      title=""
      centered
      className={
        type == 0
          ? "modal-activity-bg modal-activity-level"
          : "modal-activity-bg modal-activity-replace"
      }
      open={isModalOpen}
      onCancel={handleCancel}
      footer={null}
      width={590}
    >
      <div className="countdown-box">
        距结束
        <div className="activity-day time-num-box">{timeData.day}</div>天
        <div className="activity-hour time-num-box">{timeData.hour}</div>:
        <div className="activity-min time-num-box">{timeData.min}</div>:
        <div className="activity-second time-num-box">{timeData.second}</div>
      </div>
      {type == 0 ? (
        <div
          className="attend-btn"
          onClick={() => {
            toBuy();
          }}
        >
          升级购买
        </div>
      ) : (
        <div
          className="attend-btn"
          onClick={() => {
            toBuy();
          }}
        >
          立即换购
        </div>
      )}
    </Modal>
  );
};

export default ActivityModal;
