import { request } from "@/utils/request";

//获取订单列表
export function fetchOrderList(status: string, index: number, row: number) {
  return request(
    {
      url: `/trade/order/channel`,
      method: "GET",
      params: {
        type: "cash",
        status,
        index,
        row,
      },
    },
    { isTransformResponse: true }
  );
}
//获取订单列表V2
export function fetchOrderListV2(status: string, index: number, row: number) {
  return request(
    {
      url: `/trade/v2/order/channel`,
      method: "GET",
      params: {
        type: "cash",
        status,
        index,
        row,
      },
    },
    { isTransformResponse: true }
  );
}

// 获取我的订单协议列表
export function fetchAgreementList(status: number) {
  return request({
    url: `/trade/order/contract/my/history`,
    method: "GET",
    params: {
      status,
    },
  });
}
// 获取订单详情
export function getOrderDetail(id: string) {
  return request({
    url: `/trade/order/detail`,
    method: "GET",
    params: {
      id,
    },
  });
}
// 获取订单详情
export function getOrderDetailV2(id: string) {
  return request({
    url: `/trade/v2/order/detail`,
    method: "GET",
    params: {
      id,
    },
  });
}
// 取消订单
export function closeOrder(id: string) {
  return request({
    url: `/trade/order/cancel?id=${id}`,
    method: "PUT",
  });
}
// 查询订单状态
export function getOrderStatus(id: string) {
  return request({
    url: `/trade/order/cancel?id=${id}`,
    method: "GET",
  });
}
