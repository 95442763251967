import React, { useState, useEffect } from "react";
import "./css/sortExpansion.scss";

interface ParentItem {
  children: ChildItem[];
  key: string;
  type: number;
  value: string;
}

interface ChildItem {
  key: string;
  type: number;
  value: string;
}

interface Iprops {
  projectData: ParentItem[]; // 分类展开数据
  goTofirst: Function; // 跳转到一级标签的方法
  goToSecond: Function; // 跳转到二级标签的方法
  goToItem: Function; // 跳转到项目的方法
  classifyStyle: string; //收起的级数
  color: string; // 标签字体颜色
  width: string; // 展开的宽度
}

function SortExpansion(props: Iprops) {
  const { projectData, goTofirst, goToSecond, goToItem, classifyStyle, color, width } =
    props;
  const hotExamStyle = {
    display: classifyStyle === "0" ? "none" : "",
  };
  const examListStyle = {
    width: width
  }
  const tagColor = { color: color };
  return (
    <div className="sort-expansion-wrap">
      <div className="topnav-list">
        <div className="top-center-nav">
          <div className="course-classify fl">
            <ul
              className="classify-list"
              style={{
                maxHeight: "384px",
                overflowY: "auto",
                overflowX: "hidden",
              }}
            >
              {projectData && projectData.length > 0
                ? projectData.map((item: any) => {
                    // 找出没有子children的项
                    const otherChildren = item.children.filter(
                      (child: any) => !child.children
                    );
                    return (
                      <li className="classify-item" key={item.key}>
                        <div className="classify-top clear">
                          <span
                            className="classify-title fl"
                            onClick={() => goTofirst(item)}
                            style={tagColor}
                          >
                            {item.value}
                          </span>
                          <span className="iconfont icon-symbol_right fr"></span>
                        </div>
                        <div className="hot-exams clear" style={hotExamStyle}>
                          {item.childrenLevel2 && item.childrenLevel2.length > 0
                            ? item.childrenLevel2.map(
                                (child: any, childIndex: number) => {
                                  return childIndex < 2 ? (
                                    <a
                                      className="hot-exam"
                                      onClick={() => goToSecond(child)}
                                      key={child.index}
                                    >
                                      {child.value}
                                    </a>
                                  ) : null;
                                }
                              )
                            : item.projectLevel2 &&
                              item.projectLevel2.length > 0
                            ? item.projectLevel2.map(
                                (child: any, childIndex: number) => {
                                  return childIndex < 2 ? (
                                    <a
                                      className="hot-exam"
                                      onClick={() => goToItem(child)}
                                      key={child.index}
                                    >
                                      {child.value}
                                    </a>
                                  ) : null;
                                }
                              )
                            : null}
                        </div>
                        <div className="exam-list" style={examListStyle}>
                          {item.children && item.children.length > 0
                            ? item.children.map((child: any) => {
                                if (
                                  child.type !== undefined &&
                                  child.type == 0
                                ) {
                                  return (
                                    <div
                                      className="exam-items clear"
                                      key={child.key}
                                    >
                                      <div
                                        className="exam-title"
                                        onClick={() => goToSecond(child)}
                                      >
                                        {child.value}
                                      </div>
                                      <div className="exam-title2">
                                        {child.children &&
                                        child.children.length > 0
                                          ? child.children.map((list: any) => {
                                              return (
                                                <a
                                                  className="exam-item"
                                                  onClick={() => goToItem(list)}
                                                >
                                                  <span>{list.value}</span>
                                                </a>
                                              );
                                            })
                                          : null}
                                      </div>
                                    </div>
                                  );
                                }
                              })
                            : null}
                          {/* 在每个分类的末尾添加“其他”部分 */}
                          {otherChildren.length > 0 && (
                            <div className="exam-items clear">
                              <div className="exam-title">
                                其他
                              </div>
                              <div className="exam-title2">
                                {otherChildren.map((child: any) => (
                                  <a
                                    key={child.key}
                                    className="exam-item"
                                    onClick={() => goToItem(child)}
                                  >
                                    <span>{child.value}</span>
                                  </a>
                                ))}
                              </div>
                            </div>
                          )}
                        </div>
                      </li>
                    );
                  })
                : null}
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
}
export default SortExpansion;
