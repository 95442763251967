/**
 * 广告位组件
 */
import { Carousel } from "antd";
import "./css/advertisement.scss";
import { useNavigate } from "react-router-dom";
import { handleBread } from "@/utils/bread";
const Advertisement = (prop:any) => {
    const {
      backgroundColor,
      paddingStyle,
      advertisementArr,
      isShow
    } = prop.data || {}
    const {paddingBottom,paddingLeft,paddingTop,paddingRight} = paddingStyle || {}
    const style = {
        backgroundColor: backgroundColor,
        paddingTop,paddingRight,paddingLeft,paddingBottom
      };
    
    const navigate = useNavigate()
    const handleNav = (index:any)=>{
        if(!advertisementArr[index].click) return
        const  type =advertisementArr[index].click.href[0]
        const  href = advertisementArr[index].click.href[1]
        if(!type && !href) return
        switch (type) {
          case "功能链接":
            gocenter(href)
            break;
          case "微页面":
            goWeiPage(href)
            break;
          case "超链接":
            goByLink(advertisementArr[index].click.hyperLink)
            break;
          case "商品链接":
            goPageByGood(advertisementArr[index].click) 
            break;
          case "资讯":
            goInfo(advertisementArr[index].click)
            break;
          case "单页":
            goSingPage(advertisementArr[index].click)
            break;
        }
        
    }
    // 功能链接跳转函数
    const gocenter = (href:string)=>{
      switch(href){
        case '我的课程':
          navigate("/personalCenter?topNavIndex=0");
        break;
        case '课程激活':
          navigate("/personalCenter?topNavIndex=2");
        break;
        case '我的订单':
          navigate("/personalCenter?topNavIndex=3");
        break;
        case '我的协议':
          navigate("/personalCenter?topNavIndex=4");
          break;
      }
    }

    //超链接跳转函数
    const goByLink = (hyperLink:string)=>{
      console.log(hyperLink,"超链接");
      window.location.href = hyperLink
    }

    //微页面跳转函数
    const goWeiPage = (href:string)=>{
      console.log(href,'微页面');
      navigate(`/webPage/${href}`)
    }

    // 商品链接跳转
    const goPageByGood = (item:any) => {
      let type = item.goods.val[0].type;
      let id = item.goods.val[0].id;
      let supplierId = item.goods.val[0].supplierId;
      // 1 课程 3 实体商品
      if (type === 1) {
        supplierId
        ? navigate(`/courseDetail?id=${id}&supplierId=${supplierId}`)
        : navigate(`/courseDetail?id=${id}`);
      } else if (type === 3) {
        // 暂无
      }
    };
    // 资讯详情跳转
    const goInfo = (item:any)=>{
      let id = item.info.val[0].id;
      handleBread({ title: "资讯详情", href: "/infoDetail" });
      navigate("/infoDetail", { state: { id: id } });
    }

    // 资讯详情跳转
    const goSingPage = (item:any)=>{
      let id = item.singPage.val[0].id;
      handleBread({ title: "单页详情", href: "/singPage" });
      navigate("/singPage", { state: { id: id } });
    }
  return (
    <div className="container1">
      {
        
          !isShow ?(
          <div  className="content" onClick={()=>handleNav(0)} style={style}>
          <img src={advertisementArr[0].val} alt=""/>
        </div>
        ): (
          <Carousel autoplay>
          {advertisementArr.map((item:any,index:number)=>{
              return <div key={index}  className="content" onClick={()=>handleNav(index)} style={style}>
                          <img src={item.val} alt=""/>
                      </div>
          })}
        </Carousel>
        )
      }
    </div>
  );
};

export default Advertisement;
