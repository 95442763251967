import React, { useState, useEffect } from "react";
// import { StyleSheet, div, span } from 'react-native';
import "./css/RecursionLevelList.scss";
import CommonTreeChildren from "./CommonTreeChildren";
// import { p } from '@/components/p';
// import { fetchAuthorizeandSign } from '@/api/modules/course';
import { RoundedProgressBar } from "./RoundedProgressBar";
import { getTreeNodes, getStudyStatByNodes } from "@/apis/courseDetail";
// import { navigationFn } from '@/pages/course/constructs/datas/index';
// import { useNavigation } from '@react-navigation/native';
// import { getGoodsDetails } from '@/api/modules/goods';
// import { assembleData } from "@/utils/tree";
// import system from '@/store/system';

interface IPropType {
  callback?: Function; // 回调函数， 自定义跳转
  commodityId: string; // 商品ID
  isHideProgress?: boolean; // 是否隐藏学习进度
  defaultExpan?: boolean; //目前弃用
  allExpan?: boolean; //目前弃用
  styles?: object; // 自定义样式
  goSign?: any; //
  importType?: number; // 0正常树,1视屏树
  curUnitId?: string; //当前选中的单元
  treeParams?: object;
  skuId: string; //1 全部 0 商品第一个sku  具体的sku值
  supplierId?: string; //分销商id
}

export default function CommonTree(props: any) {
  //   const navigation = useNavigation<any>();
  const [list, setList] = useState([]);
  const {
    treeParams = {
      nodeId: "", //节点id 选填
      depth: "1", //节点深度 选填 默认1
      nodeType: "T", //节点类型 选填
      expandInfo: "", //展开索引集合 节点下标 节点展示的层级
      include: "", //是否包含当前节点 选填 默认包含
      limit: "", //展示数量 选填
      unitType: "", //单位类型 选填
    },
    commodityId,
    skuId,
    supplierId = "",
    isHideProgress = false,
    importType = 0,
    callback,
  } = props;

  // 章节课第一行默认展开
  // useEffect(() => {
  //   // console.log(123);
  //   if (defaultExpan && list?.length > 0) {
  //     justOpen(list[0]);
  //   }
  //   if (allExpan && list?.length > 0) {
  //     let preClickIdArr2: any = [];
  //     list.forEach((item: any) => {
  //       preClickIdArr2.push(item.name);
  //     });
  //     setPreClickIdArr(preClickIdArr2);
  //   }
  // }, [list]);
  useEffect(() => {
    if (commodityId && treeParams.nodeType) {
      initTreeData();
    }
  }, [commodityId, treeParams.unitType]);

  const [preClickIdArr, setPreClickIdArr] = useState([]);
  const openClose = async (item: any) => {
    const currentVal = item.name;
    console.log("走了");
    let preClickIdArr2 = JSON.parse(JSON.stringify(preClickIdArr));
    if (preClickIdArr2.indexOf(currentVal) === -1) {
      preClickIdArr2.push(currentVal);
      if (item.isLeaf == 0) {
        const next = await loadChildren(item);
        const staticsData = await loadChildrenStudy(item, next);
        console.log(staticsData, "staticsData---");

        if (staticsData && staticsData.length > 0) {
          item.next = next.map((nextItem: any) => {
            return assembleData(nextItem, staticsData);
          });
          console.log(list, "list22----");
        } else {
          item.next = next;
        }
      }
    } else {
      preClickIdArr2.splice(preClickIdArr2.indexOf(currentVal), 1);
    }

    setPreClickIdArr(preClickIdArr2);
  };
  // 只是打开
  const justOpen = (item: any) => {
    const currentVal = item.name;
    let preClickIdArr2 = JSON.parse(JSON.stringify(preClickIdArr));
    if (preClickIdArr2.indexOf(currentVal) === -1) {
      preClickIdArr2.push(currentVal);
    }
    console.log(item, preClickIdArr2, preClickIdArr, "打开");
    setPreClickIdArr(preClickIdArr2);
    console.log(
      preClickIdArr,
      JSON.parse(JSON.stringify(preClickIdArr)),
      "打开2"
    );
  };

  const hasPreClickId = (arr: any, name: any) => {
    if (arr.indexOf(name) > -1) {
      return true;
    } else {
      return false;
    }
  };
  const initTreeData = async () => {
    console.log(commodityId, treeParams, "initTreeData");
    const initParams = {
      commodityId: commodityId,
      ...treeParams,
      skuId: skuId,
      supplierId: supplierId,
    };
    console.log(initParams, "参数");
    const data = await getTreeNodes(initParams);
    const productNodes = data.filter((item: any) => {
      let flag = ["T", "J", "R"].includes(item.nodeType);
      return !flag;
    });
    console.log(data, "tree----");
    if (productNodes.length > 0) {
      const productNodes = data.filter((item: any) => {
        let flag = ["T", "J", "R"].includes(item.nodeType);
        return !flag;
      });
      const staticsData = await getStudyStatByNodes({
        productTreeNodes: productNodes,
      });
      console.log(data, initParams);
      const listData = data.map((item: any) => {
        return assembleData(item, staticsData ? staticsData : []);
      });

      setList(listData);
    } else {
      setList(data);
    }
  };
  // 加载子节点的学习进度
  const loadChildren = async (parentItem: any) => {
    if (parentItem.next) {
      return parentItem.next;
    } else {
      const { productId, productName, parentId, parentName, nodeType } =
        parentItem;
      let initParams: any = {
        commodityId: commodityId,
        skuId: skuId,
        supplierId: supplierId,
        nodeId: parentItem.id,
        nodeName: parentItem.id,
        productId,
        productName,
        parentId,
        parentName,
        nodeType,
      };
      if (treeParams.unitType == 0 || treeParams.unitType != "") {
        initParams.unitType = treeParams.unitType;
      }
      console.log(treeParams.productSetting, 7777);
      if (treeParams.productSettings && treeParams.productSettings.length > 0) {
        initParams.productSettings = treeParams.productSettings;
      }
      console.log(initParams, "canshu");
      const data = await getTreeNodes(initParams);
      console.log(initParams);
      const next = data;
      return next;
    }
  };
  const loadChildrenStudy = async (parentItem: any, next: any) => {
    if (parentItem.next) {
      return [];
    } else {
      const productNodes = next.filter((item: any) => {
        let flag = ["T", "J", "R"].includes(item.nodeType);
        return !flag;
      });
      if (productNodes.length > 0) {
        const staticsData = await getStudyStatByNodes({
          productTreeNodes: productNodes,
        });
        return staticsData ? staticsData : [];
      } else {
        return [];
      }
    }
  };
  // 产品树重新组装统计数据
  function assembleData(item: any, statics: any) {
    const current = statics?.find((it: any) => {
      // J-科目类型 P-产品类型 C-章类型 S-节类型 CU-学习单元类型(章) SU-学习单元类型(节)
      // console.log(item.id === it.id && it.nodeType === item.nodeType)
      // nodeType=== CU 和SU   nodetype和id匹配
      // nodeType=== S  nodeType和parentName和name匹配
      // nodeType=== C  nodeType和productId和name匹配
      // nodeType=== p  nodeType和parentId存在，parentId和id一起匹配，不存在直接用id匹配
      switch (item.nodeType) {
        case "CU":
        case "SU":
        case "RU":
          if (item.id === it.id && item.nodeType === it.nodeType) {
            return true;
          }
          break;
        case "S":
          if (
            item.name === it.name &&
            item.nodeType === it.nodeType &&
            item.parentName === it.parentName
          ) {
            return true;
          }
          break;
        case "C":
          if (
            item.name === it.name &&
            item.nodeType === it.nodeType &&
            item.parentId === it.parentId
          ) {
            return true;
          }
          break;
        case "P":
          if (item.id === it.id && item.nodeType === it.nodeType) {
            return true;
          }
          break;
        default:
          return false;
      }
    });
    if (current) {
      return {
        ...item,
        ...current,
      };
    } else {
      return item;
    }
  }

  //   const findPreview = (next: any) => {
  //     const find = (next: any) => {
  //       return next.find((item: any) => {
  //         if (item.next) {
  //           return find(item.next);
  //         }
  //         if (item.preview) {
  //           return true;
  //         }
  //       });
  //     };
  //     return !!find(next);
  //   };

  return (
    <div className="page1">
      <div className="group1">
        {list &&
          list.map((item: any, index: number) => {
            // 学习进度
            const { speedRate = 0, spnum = 0 } = item;

            return (
              <div key={item.name}>
                <div className="group2">
                  <div
                    onClick={() => {
                      openClose(item);
                    }}
                  >
                    {item.nodeType === "J" ? (
                      <div
                        className={
                          importType == 1 ? "group21_shixue" : "group21"
                        }
                        style={{
                          marginTop: index > 0 ? 20 : 0,
                        }}
                      >
                        <div
                          className={
                            importType == 1 ? "group23_shixue" : "group23"
                          }
                        >
                          <span className="text21"> </span>
                          <span
                            className={
                              importType == 1 ? "text22_shixue" : "text22"
                            }
                          >
                            {item.name}
                          </span>
                        </div>
                        {hasPreClickId(preClickIdArr, item.name) ? (
                          //   <p
                          //     name="chevron-up"
                          //     size={18}
                          //     color={"#333333"}
                          //     style={{ marginRight: 10 }}
                          //   />
                          <div
                            className="iconfont  icon-symbol_up"
                            style={{
                              marginRight: 20,
                              color:
                                importType == 1 ? "rgb(255, 255, 255)" : "",
                            }}
                          ></div>
                        ) : (
                          //   <p
                          //     name="chevron-down"
                          //     size={18}
                          //     color={"#333333"}
                          //     style={{ marginRight: 10 }}
                          //   />
                          <div
                            className="iconfont  icon-symbol_down"
                            style={{
                              marginRight: 20,
                              color:
                                importType == 1 ? "rgb(255, 255, 255)" : "",
                            }}
                          ></div>
                        )}
                      </div>
                    ) : (
                      <div
                        style={{
                          paddingTop: 16,
                          paddingRight: 10,
                          paddingLeft: 10,
                        }}
                      >
                        <div className={index == 0 ? "group3Top" : "group3"}>
                          {importType == 1 ? (
                            hasPreClickId(preClickIdArr, item.name) ? (
                              // <p name="expand_1_1" size={18} color={'#333333'} />
                              <div className="expand_1_1_shixue"></div>
                            ) : (
                              // <p name="expand_1_2" size={18} color={'#333333'} />
                              <div className="expand_1_2_shixue"></div>
                            )
                          ) : importType == 0 ? (
                            hasPreClickId(preClickIdArr, item.name) ? (
                              // <p name="expand_1_1" size={18} color={'#333333'} />
                              <div className="expand_1_1 iconfont icon-expand_1_1"></div>
                            ) : (
                              // <p name="expand_1_2" size={18} color={'#333333'} />
                              <div className="expand_1_2 iconfont icon-expand_1_2"></div>
                            )
                          ) : null}
                          <div className="text1Wrap">
                            <span
                              className={
                                importType === 1 ? "text1 text1-video" : "text1"
                              }
                            >
                              {item.name}
                            </span>
                            {item.preview &&
                            (item.seeHint || item.seeHint == 1) ? (
                              <span className="text10">试学</span>
                            ) : null}
                          </div>
                        </div>
                        {!isHideProgress ? (
                          <div className="group4">
                            {speedRate ? (
                              <RoundedProgressBar
                                speedRate={speedRate}
                                width={40}
                                height={6}
                                color="#E51600"
                                borderRadius={4}
                                trailColor={
                                  importType === 1
                                    ? "#E5E6EB"
                                    : "rgba(0,0,0,0.06)"
                                }
                              />
                            ) : null}
                            {speedRate ? (
                              <span
                                className={
                                  importType === 1
                                    ? "text2"
                                    : "text2"
                                }
                              >
                                {speedRate * 100}%
                              </span>
                            ) : null}
                            <span
                              className={
                                importType === 1
                                  ? "text3"
                                  : "text3"
                              }
                            >
                              {spnum}人已学
                            </span>
                          </div>
                        ) : (
                          <div className="group4">
                            <span
                              className={
                                importType === 1
                                  ? "text3"
                                  : "text3"
                              }
                            >
                              {spnum}人已学
                            </span>
                          </div>
                        )}
                      </div>
                    )}
                  </div>
                </div>
                {hasPreClickId(preClickIdArr, item.name) ? (
                  item.next && item.next.length > 0 ? (
                    <CommonTreeChildren
                      list={item.next}
                      callback={callback}
                      productId={item.productId}
                      isHideProgress={isHideProgress}
                      importType={importType}
                      commodityId={commodityId}
                      skuId={skuId}
                      unitType={treeParams.unitType}
                      productSettings={treeParams.productSettings}
                      supplierId={supplierId}
                      defaultExpan={false}
                      allExpan={false}
                    />
                  ) : (
                    <div className="group15" key={item.name}>
                      <div className="group16">
                        {/* <p name="search" size={26} /> */}
                        <span className="group18">课程内容正在制作中</span>
                      </div>
                    </div>
                  )
                ) : null}
              </div>
            );
          })}
      </div>
    </div>
  );
}
