import './css/index.scss'
const RichText = (com:any)=>{
    const {
        backgroundColor,
        backgroundImage,
        paddingStyle,
        editor,
        color
    } = com.data
    const {paddingBottom,paddingLeft,paddingTop,paddingRight} = paddingStyle
    const style = {
        backgroundColor: backgroundColor,
        backgroundImage: `url(${backgroundImage})`,
        color: color,
        paddingTop,paddingRight,paddingLeft,paddingBottom
      };
    return (
        <div className="richText">
             <div dangerouslySetInnerHTML={{__html:editor}} className='richContent' style={style}>
            
            </div>
        </div>
       
    )
}


export default RichText