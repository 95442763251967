import { message } from "antd";
import { RequestOptions } from "@/types/axios";
import userStore from "@/store/user";
import {
  SUCCESS_CODE,
  UNLOGIN_CODE,
  CHECK_AUTO_LOGIN,
  ORG_PREFIX,
} from "@/config/const";

const qs = require("qs");

// 生成唯一的key
export function generateUniqueKey(url: string, params: object = {}) {
  // 对参数进行排序
  const sortedParams = Object.fromEntries(
    Object.entries(params).sort(([aKey], [bKey]) => aKey.localeCompare(bKey))
  );
  const urlWithParams = url + "?" + qs.stringify(sortedParams);
  return urlWithParams;
}

export const transform: any = {
  transformRequestHook: (
    res: any,
    options: RequestOptions,
    config: { url: string; data: any }
  ) => {
    const headers = res.headers;
    if (headers && (headers["etag"] || headers["last-modified"])) {
      // 存储当前请求的ETag值
      const unikey = generateUniqueKey(config.url, config.data || {});
      localStorage.setItem(`${ORG_PREFIX}-tag-${unikey}`, headers["etag"]); // 存储e-tag
      localStorage.setItem(
        `${ORG_PREFIX}-last-modified-${unikey}`,
        headers["last-modified"]
      ); // 存储last-modified
      localStorage.setItem(
        `${ORG_PREFIX}-data-${unikey}`,
        JSON.stringify(res.data)
      ); // 存储data
    }
    const { isTransformResponse = null, isReturnNativeResponse = null } =
      options || {};
    // 是否返回原生响应头 比如：需要获取响应头时使用该属性
    if (isReturnNativeResponse) {
      return res;
    }
    const { data } = res;
    // 用于页面代码可能需要直接获取code，data，message这些信息时开启
    if (isTransformResponse) {
      return data;
    }

    if (!data) {
      throw new Error("网络崩溃了，请重新刷新页面");
    }
    const { code = 0, body = {}, msg = "请求出错", errorMsg = "" } = data;
    if (code === SUCCESS_CODE) {
      return body;
    } else if (code === UNLOGIN_CODE) {
      // token失效，清空数据让用户重新登录
      userStore.setToken("");
      localStorage.removeItem(`${CHECK_AUTO_LOGIN}`);
      userStore.setUserInfo({});
      userStore.showLoginModal();
    } else {
      message.error(errorMsg || msg);
      return;
    }
  },
  transformCatchHook: (e: string) => {
    message.error(e);
  },
};

export function isFunction(val: unknown): val is Function {
  return typeof val === "function";
}
