import { request } from "@/utils/request";

export function getNewStudy() {
  return request({
    url: `/resource/user/record/newest/study`,
    method: "GET",
  });
}

export function getAllStudy() {
  return request({
    url: `/resource/user/record/all/study`,
    method: "GET",
  });
}
