import { request } from "@/utils/request";
export function getLiveList(data: any) {
    return request(
      {
        url: `/shelves/commodity/live/list`,
        method: "POST",
        data:{params:data},
      },
    );
}

export function getLiveIng(data: any) {
    return request(
      {
        url: `/resource/unit/live/select`,
        method: "POST",
        data:{dataList:data},
      },
    );
}

export function getLiveNum(data: any) {
  return request(
    {
      url: `/resource/live-room/students`,
      method: "POST",
      data:{liveRoomIds:data},
    },
  );
}