import React, { useEffect, useState, memo } from "react";
import { useNavigate } from "react-router-dom";
import { debounce } from "lodash";
import { Modal, QRCode, message, Spin } from "antd";
import pingpp from "pingpp-js";
import { postPaymentOrder, getOrderStatus } from "@/apis/payment";
import { WX_PUB_QR, ALIPAY_PC_DIRECT } from "@/config/const";
import "./css/index.scss";
import TipImg from "./images/tip.png";

interface Iprops {
  orderId: string; // 订单号
  onClose?: Function; // 关闭弹框回调
  refreshOutSide?: Function; // 支付后刷新外部页面的回调
}

const PayModal = (props: Iprops) => {
  const { onClose, orderId = "", refreshOutSide } = props;
  const navigate = useNavigate();
  const [isModalOpen, setIsModalOpen] = useState(true);
  const [amount, setAmount] = useState(0);
  const [payType, setPayType] = useState(WX_PUB_QR); // 默认微信支付
  const [qrCodeUrl, setQrCodeUrl] = useState(""); // 支付二维码
  const [paySigns, setPaySigns] = useState<any>({
    [WX_PUB_QR]: "",
    [ALIPAY_PC_DIRECT]: "",
  }); // 支付签名字符串

  let timer: any = null;

  const pollingOrder = async (id: string) => {
    let counts = 60;
    return new Promise((resolve) => {
      timer = setInterval(async () => {
        const { status } = await getOrderStatus({ id });
        counts--;
        if (status == 1) {
          clearInterval(timer);
          resolve(true);
        }
        if (status == 5) {
          clearInterval(timer);
          resolve(true);
        }
        if (counts == 0) {
          clearInterval(timer);
          resolve(false);
        }
      }, 1000);
    });
  };

  // 支付宝支付
  const createPayment = (pingObj: any) => {
    return new Promise((resolve, reject) => {
      pingpp.createPayment(pingObj, function (result: string) {
        if (result == "success") {
          // 只有微信JSAPI (wx_pub)、微信小程序（wx_lite）、QQ 公众号 (qpay_pub)、支付宝小程序（alipay_lite）支付成功的结果会在这里返回，其他的支付结果都会跳转到 extra 中对应的 URL
          resolve(true);
        } else if (result == "fail") {
          // Ping++ 对象 object 不正确或者微信JSAPI/微信小程序/QQ公众号支付失败时会在此处返回
          resolve(false);
        } else if (result == "cancel") {
          // 微信JSAPI、微信小程序、QQ 公众号、支付宝小程序支付取消支付
          resolve(false);
        }
      });
    });
  };

  // 获取支付参数
  const getPaymentParam = async () => {
    if (!paySigns[payType]) {
      const { origin, pathname } = window.location;
      let { amount = 0, payParam = "" } = await postPaymentOrder({
        orderId,
        payChannel: payType,
        extra: {
          success_url: `${origin}${pathname}/#/paymentStatus/${orderId}`,
        },
      });
      return { amount, payParam };
    } else {
      return {
        amount,
        payParam: paySigns[payType],
      };
    }
  };

  // 创建订单二维码
  const createOrderQrcode = debounce(async () => {
    let orderResult = null;
    const { amount, payParam } = await getPaymentParam();
    setAmount(amount);
    setPaySigns((state: any) => {
      return {
        ...state,
        [payType]: payParam,
      };
    });
    if (payType === WX_PUB_QR) {
      // 微信支付
      try {
        const pingObj = JSON.parse(payParam);
        setQrCodeUrl(pingObj.credential.wx_pub_qr);
        // TODO 轮询订单状态
        orderResult = await pollingOrder(orderId);
      } catch (error) {
        message.error({
          content: "获取支付二维码失败，请重试...",
          duration: 3,
        });
      }
    } else if (payType === ALIPAY_PC_DIRECT) {
      // 支付宝支付
      orderResult = await createPayment(payParam);
    }
    if (orderResult) {
      // 支付成功
      message.success({
        content: "支付成功，正在跳转中...",
        duration: 3,
      });
    } else {
      // 支付失败
      message.error({
        content: "支付失败，正在跳转中...",
        duration: 3,
      });
    }
    setIsModalOpen(false);
    refreshOutSide && refreshOutSide();
    // 跳转到支付结果页
    setTimeout(() => {
      navigate(`/paymentStatus/${orderId}`);
    }, 3000);
  }, 500);

  useEffect(() => {
    // TODO: 接口报错了，等联调
    createOrderQrcode();
    return () => {
      // 清除定时器
      timer && clearInterval(timer);
    };
  }, [payType]);

  const handleCancel = () => {
    // 取消支付跳转到支付状态页
    navigate(`/paymentStatus/${orderId}`);
    onClose && onClose(false);
  };

  // 切换支付类型
  const handleChangePaytype = (type: string) => {
    setPayType(type);
  };

  // 微信
  const WechatBottom = () => {
    return (
      <div>
        <div className="pay-qrcode">
          {qrCodeUrl ? (
            <QRCode value={qrCodeUrl} />
          ) : (
            <div className="pay-qrcode-spin">
              <Spin />
            </div>
          )}
        </div>
        <div className="pay-scan">
          打开手机{payType === ALIPAY_PC_DIRECT ? "支付宝" : "微信"} 扫一扫付款
        </div>
      </div>
    );
  };
  // 支付宝
  const AliPayBottom = () => {
    return (
      <div className="alipay-tip">
        <img src={TipImg} alt="支付提示" />
        <div style={{ width: 30 }}></div>
        <div className="aplipay-text-tip">请您在新打开的页面上完成付款</div>
      </div>
    );
  };

  return (
    <Modal
      title="选择支付方式"
      centered
      className="pay-type-common-modal"
      open={isModalOpen}
      onCancel={handleCancel}
      footer={null}
      width={680}
    >
      <div className="pay-order-component-space"></div>
      <div className="pay-order-component-wrap">
        <div className="pay-type">
          <div
            className={`wechat-pay ${payType === WX_PUB_QR ? "active" : ""}`}
            onClick={() => {
              handleChangePaytype(WX_PUB_QR);
            }}
          >
            <img
              className="wechat-img"
              src="https://resource.wangxiao.net/pc/images/wechat.png"
              alt=""
            />
            <span className="space"></span>
            <span>微信支付</span>
            {payType === WX_PUB_QR ? (
              <img
                className="exclude"
                src="https://resource.wangxiao.net/pc/images/exclude.png"
                alt=""
              />
            ) : null}
          </div>
          <div
            className={`aplipay-pay ${
              payType === ALIPAY_PC_DIRECT ? "active" : ""
            }`}
            onClick={() => {
              handleChangePaytype(ALIPAY_PC_DIRECT);
            }}
          >
            <img
              src="https://resource.wangxiao.net/pc/images/zhifubao.png"
              alt=""
            />
            <span className="space"></span>
            <span>支付宝支付</span>
            {payType === ALIPAY_PC_DIRECT ? (
              <img
                className="exclude"
                src="https://resource.wangxiao.net/pc/images/exclude.png"
                alt=""
              />
            ) : null}
          </div>
        </div>
        <div className="pay-tip">扫一扫付款（元）</div>
        <div className="pay-amount">{amount.toFixed(2)}</div>
        {payType === WX_PUB_QR ? <WechatBottom /> : <AliPayBottom />}
      </div>
    </Modal>
  );
};

export default memo(PayModal);
