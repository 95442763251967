import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { Pagination } from "antd";
// import Breadcrumb from "@/components/BreadCrumb";
import { handleBread } from "@/utils/bread";
import { useNavigate } from "react-router-dom";
import { getCouponRange } from "@/apis/coupon";
// import getOtherCourse from "@/apis/allCourseName";
import "./css/index.scss";

function AllCourseCoupon() {
  const params: any = useParams(); //用于获取url
  const navigate = useNavigate();
  const [current, setcurrent] = useState(1); //当前页码
  const [total, setTotal] = useState(0); //总页面数
  const [cardList, setCardList] = useState([]);
  const [pageSize, setPageSize] = useState(12); //pagesize
  useEffect(() => {
    getCourseById(params?.id, 0, 12);
  }, [params]);

  //page当前的页码、infotype信息类型
  const changePage = (page: number, pageRow: number) => {
    setPageSize(pageRow);
    setcurrent(page);
    getCourseById(params?.id, page - 1, pageRow);
  };

  // 获取指定商品
  const getCourseById = async (id: any, page: any, pageRow: number) => {
    const res = await getCouponRange({ id, index: page, row: pageRow });
    setCardList(res?.data || []);
    // setcurrent(res.current);
    setTotal(res.total);
  };

  // 课程详情
  const goToDetail = (item: any) => {
    handleBread({
      title: `${item.name}`,
      href: `/courseDetail?id=${item.id}&supplierId=${item.supplierId || ""}`,
    });
    navigate(`/courseDetail?id=${item.id}&supplierId=${item.supplierId || ""}`);
  };

  return (
    <div className="allcourse-box">
      {/* <Breadcrumb /> */}

      {/* 全部课程 */}
      <div className="allcourse-content">
        <div className="allcourse-all">
          <div className="allcourse-top">
            <div className="allcourse-name">全部课程</div>
            <div className="allcourse-total">共找到{total}+结果</div>
          </div>
          <div className="allcourse-list">
            {cardList?.length > 0 &&
              cardList.map((item: any) => {
                return (
                  <li
                    className="allcourse-item"
                    onClick={() => goToDetail(item)}
                  >
                    <div className="item-imgs">
                      <img src={item.thumbnail} alt="" />
                      <div className="sellingnumber">
                        {item.salesVolume ? item.salesVolume : 0}人已关注
                      </div>
                    </div>
                    <div className="bottom-box">
                      <div className="item-name">{item.name}</div>
                      <div className="item-sellpoint">{item.sellingPoint}</div>
                      <div className="item-bottom">
                        <div className="item-teacherList">
                          {item.teachers
                            ? item.teachers.map((info: any, index: number) => {
                                return index < 3 ? (
                                  <div className="teacher-item">
                                    <img src={info.photo} alt="" />
                                    <p>{info.name}</p>
                                  </div>
                                ) : null;
                              })
                            : null}
                        </div>
                        <div className="item-pricebox">
                          <p className="item-lineprice">
                            <span className="lineprice-icon">￥</span>
                            {item.linePrice}起
                          </p>
                          <p className="item-realPrice">
                            <span className="price-icon">￥</span>
                            {item.price}起
                          </p>
                        </div>
                      </div>
                    </div>
                  </li>
                );
              })}
          </div>
          <div className="pagination-box">
            <Pagination
              defaultCurrent={1}
              total={total}
              current={current}
              pageSize={pageSize}
              pageSizeOptions={[10, 12, 20, 50, 100]}
              onChange={changePage}
              hideOnSinglePage={true}
            />
          </div>
        </div>
      </div>

      {/* 底部栏 */}
    </div>
  );
}
export default function AllCoursePage() {
  return <AllCourseCoupon></AllCourseCoupon>;
}
