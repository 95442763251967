/*
 * @Author: liuweixing
 * @Date: 2024-05-13 10:03:31
 * @Description: 一行两个优惠券
 */
import { Empty } from "antd";
import { useState } from "react";
import ExModal from "@/pages/OrderSettlement/comps/ExplainModal";
import "../css/index.scss";
interface Iprops {
  data: any;
  index: number;
  handlerClick: any;
}
const AllCouponItem = (props: Iprops) => {
  const { data, index, handlerClick } = props;
  const [exModalOpen, setExModalOpen] = useState(false); // 说明弹窗的显示与隐藏
  const [curExplain, setCurExplain] = useState<string>("使用说明");
  const showModal = (text: string) => {
    setCurExplain(text);
    setExModalOpen(true);
  };
  return (
    <div className="coupon-item-2">
      <div
        // className={
        //   index % 2 == 0 ? "card-box-coupon-2 right-24" : " card-box-coupon-2"
        // }
        className={
          index % 2 == 0
            ? data?.viewStatus == 2
              ? "card-box-coupon-2 bg-use-big right-24"
              : data.viewStatus == 3
              ? "card-box-coupon-2 bg-lose-big right-24"
              : data.viewStatus == 4
              ? "card-box-coupon-2 bg-time-big right-24"
              : "card-box-coupon-2 bg-active-big right-24"
            : data?.viewStatus == 2
            ? "card-box-coupon-2 bg-use-big"
            : data.viewStatus == 3
            ? "card-box-coupon-2 bg-lose-big"
            : data.viewStatus == 4
            ? "card-box-coupon-2 bg-time-big"
            : "card-box-coupon-2 bg-active-big"
        }
      >
        <div className="left-card-coupon-2">
          {data.type == 0 ? (
            <div className="left-type-0-2">
              <div className="coupon-price-box-2">
                <div className="coupon-price-box-icon-2">￥</div>
                <div className="coupon-price-box-num-2">{data.discount}</div>
              </div>
              <div className="type-0-text-2">{data.content}</div>
            </div>
          ) : (
            <div className="left-type-1-2">
              <div className="coupon-discount-box-2">
                <div className="coupon-discount-box-num-2">{data.discount}</div>
                <div className="coupon-discount-box-icon-2">折</div>
              </div>
              <div className="type-1-text-2">{data.content}</div>
            </div>
          )}
          {data?.viewStatus == 2 ||
          data?.viewStatus == 3 ||
          data.viewStatus == 4 ? null : (
            <div
              onClick={() =>
                handlerClick(data.id, data?.viewStatus, data?.usableRange)
              }
              className="coupon-btn-2"
            >
              {data?.viewStatus == 1 ? "去使用" : "点击领取"}
            </div>
          )}
        </div>
        <div className="right-card-coupon-2">
          <div className="coupon-name-2">{data?.name}</div>
          <div
            className={
              data?.viewStatus == 2 ||
              data?.viewStatus == 3 ||
              data.viewStatus == 4
                ? "coupon-time-2-white ellipsis-two"
                : "coupon-time-2 ellipsis-two"
            }
          >
            {data?.useTime}
          </div>
          <div className="coupon-explain-box-2">
            <div
              className={
                data?.viewStatus == 2 ||
                data?.viewStatus == 3 ||
                data.viewStatus == 4
                  ? "coupon-explain-text-2-white"
                  : "coupon-explain-text-2"
              }
            >
              使用说明
            </div>
            <div
              className={
                data?.viewStatus == 2 ||
                data?.viewStatus == 3 ||
                data.viewStatus == 4
                  ? "coupon-explain-icon-2-white"
                  : "coupon-explain-icon-2"
              }
              onClick={() => showModal(data?.instructions)}
            ></div>
          </div>
        </div>
      </div>
      <ExModal
        isShow={exModalOpen}
        onClose={() => {
          setExModalOpen(false);
        }}
        text={curExplain}
      />
    </div>
  );
};
export default AllCouponItem;
