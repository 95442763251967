import { action, observable, makeObservable } from "mobx";
import {
  TOKEN_NAME,
  LOGIN_USER_INFO,
  CHECK_AUTO_LOGIN,
  TOKEN_EXPIRE,
  TOKEN_TEMP_EXPIRE,
  TARGET_TENANT_ID,
} from "@/config/const";

export interface UserInfoType {
  avatar: string;
  nickname: string;
  uid: string;
  org: any;
  gender?: any;
  tenantId: string;
  wxAuth: boolean;
  mobile: string | number;
  headerComp: object;
  footerComp:object
}

const InitInfo: UserInfoType = {
  avatar: "https://acc.wangxiao.net/image/app/woman0517.png", //默认头像地址
  nickname: "", // 默认状态
  uid: "", // 默认状态
  org: "",
  tenantId: "",
  wxAuth: false,
  mobile: "",
  headerComp: {},
  footerComp: {},
};

export class UserStore {
  /**
   * 用户信息
   */
  @observable
  public userInfo: UserInfoType = InitInfo;

  /**
   * 用户信息
   */
  @observable
  public supplierId: string = "";

  /**
   * 用户信息
   */
  @observable
  public isLoginModalOpen: boolean = false;

  /**设置弹窗开 */
  @action
  public showLoginModal = () => {
    this.isLoginModalOpen = true;
  };

  /**设置弹窗关 */
  @action
  public hideLoginModal = () => {
    this.isLoginModalOpen = false;
  };

  /**设置用户信息 */
  @action
  public setUserInfo = (userInfo: UserInfoType | any) => {
    localStorage.setItem(`${LOGIN_USER_INFO}`, JSON.stringify(userInfo));
    this.userInfo = userInfo;
  };

  /**获取用户信息 */
  @action
  public getUserInfo = () => {
    const userinfo = localStorage.getItem(`${LOGIN_USER_INFO}`);
    return JSON.parse(userinfo ?? "{}");
  };

  /**设置供应商id */
  @action
  public setSupplierId = (supplierId: string) => {
    localStorage.setItem(TARGET_TENANT_ID, supplierId);
    this.supplierId = supplierId;
  };

  /**获取供应商id */
  @action
  public getSupplierId = () => {
    return localStorage.getItem(TARGET_TENANT_ID);
  };

  /** token信息 */
  @observable
  public token: string = "";

  /**设置token */
  @action
  public setToken = (token: string, expire: number = new Date().getTime()) => {
    if (token) {
      localStorage.setItem(
        `${TOKEN_NAME}`,
        JSON.stringify({
          token,
          expire,
        })
      );
    } else {
      localStorage.removeItem(`${TOKEN_NAME}`);
    }
  };
  /**获取token */
  @action
  public getToken = () => {
    const { expire, token } = JSON.parse(
      localStorage.getItem(`${TOKEN_NAME}`) || "{}"
    );
    if (expire && expire - new Date().getTime() > 0) {
      // token续期
      const expire2 =
        localStorage.getItem(`${CHECK_AUTO_LOGIN}`) === "true"
          ? TOKEN_EXPIRE
          : TOKEN_TEMP_EXPIRE;
      localStorage.setItem(
        `${TOKEN_NAME}`,
        JSON.stringify({
          token,
          expire: new Date().getTime() + expire2,
        })
      );
      return token;
    } else {
      // token过期了
      localStorage.removeItem(`${TOKEN_NAME}`);
      return "";
    }
  };
  constructor() {
    makeObservable(this);
  }
  // 设置页眉配置
  @action
  public setHeaderComp = (headerInfo: object) => {
    localStorage.setItem(
      "headerComp",
      JSON.stringify({
        headerInfo,
      })
    );
  };
  // 获取页眉配置
  @action
  public getHeaderComp = () => {
    return JSON.parse(localStorage.getItem("headerComp")||'{}') ;
  };
  // 设置页尾配置
  @action
  public setFooterComp = (footerInfo: object) => {
    localStorage.setItem(
      "FooterComp",
      JSON.stringify({
        footerInfo,
      })
    );
  };
  // 获取页尾配置
  @action
  public getFooterComp= () => {
    return JSON.parse(localStorage.getItem("FooterComp")||'{}') ;
  };

}
export default new UserStore();
