import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Col, Row } from "antd";
import "../css/index.scss";

const OrderDeatailItem: React.FC<{ commodityInfo: any }> = ({
  commodityInfo,
}) => {
  const navigate = useNavigate();
  // 商品去查看
  const toLook = () => {
    navigate("/personalCenter?topNavIndex=0");
  };
  return (
    <div className="order-info-item" key={commodityInfo.id}>
      <Row>
        <Col className="order-info-border-right" span={12}>
          <div className="order-info-commodity">
            <img src={commodityInfo.img} className="order-info-img" />
            <div className="order-commodity-name">
              <div className="commodity-name">{commodityInfo.giftName}</div>
              <div className="commodity-skuName">
                {commodityInfo.instructions}
              </div>
            </div>
          </div>
        </Col>
        <Col
          className="order-info-border-right order-info-center gray"
          span={4}
        >
          ￥{commodityInfo.giftLinePrice}
        </Col>
        <Col className="order-info-border-right order-info-center" span={4}>
          ￥{commodityInfo.giftPrice}
        </Col>
        {commodityInfo?.commodityType == 1 ? (
          <Col
            className="order-info-center"
            style={{
              cursor: "pointer",
            }}
            span={4}
            onClick={toLook}
          >
            去查看
          </Col>
        ) : (
          <Col className="order-info-center" span={4}></Col>
        )}
      </Row>
    </div>
  );
};
export default OrderDeatailItem;
