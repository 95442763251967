/* eslint-disable  */
import React, { useState, useEffect, useRef } from "react";
import { useSearchParams, useNavigate } from "react-router-dom";
import { Document, Page, pdfjs } from "react-pdf";
import { Base64 } from "js-base64";
import userStore from "@/store/user";
import VideoTop from "./comps/videoTop";
import VideoLeft from "./comps/videoLeft";
import PdfPreview from "./comps/pdfPreview";
import PopSign from "./comps/popSign";
import LivePlay from "./comps/livePlay";
import QrcodeModal from "@/components/QrcodeModal";
import {
  getAuthorizeAndSign,
  getUnitDetail,
  getUnitList,
  getReplayList,
  heartbeatApi,
  getGoodsDeatil,
  getMyLessons,
} from "@/apis/courseDetail";
import { Modal } from "antd";
import {
  getGoodsDeatils,
  getGoodsUseLimit,
  getActivityInfo,
} from "@/apis/courseDetail";
import { setPlayUnit, getPlayUnit } from "@/utils/storageFactory";
import { getSiteId } from "@/config/index";
import "./css/videoPlay.scss";
declare const window: any;
function VideoPlay(props: any) {
  const [params] = useSearchParams();
  const navigate = useNavigate();
  const commodityId: any = params.getAll("commodityId")[0]; //商品id
  const productId: any = params.getAll("productId")[0]; //产品id
  const isPurchase = params.getAll("isPurchase")[0]; //是否购买
  const from = params.getAll("from")[0] || "0"; //来源：0其他，1课程

  const [courseData, setCourseData] = useState<any>({}); //商品详情
  const token = userStore.getToken();
  const [qrCodeUrl, setQrCodeUrl] = useState(""); // 设置二维码的url
  const [isModalOpen, setIsModalOpen] = useState(false); // 学习弹框
  const [isSignOpen, setIsSignOpen] = useState(false); //签署协议弹窗

  const [crumbList, setCrumbList] = useState([
    {
      title: "首页",
      herf: "/",
    },
  ]);
  // 当前选中的单元数据(无默认可不传)
  const playUnit = getPlayUnit();
  const pramesProductName = playUnit.productName || "";
  const [curProductName, setCurProductName] = useState(pramesProductName); //单元名称
  const pramesProductId = playUnit.productId || "";
  const [curProductId, setCurProductId] = useState(pramesProductId); //产品id

  const pramesUnitName = playUnit.unitName || "";
  const [curUnitName, setCurUnitName] = useState(pramesUnitName); //单元名称
  const pramesUnitId = playUnit.unitId || "";
  const [curUnitId, setCurUnitId] = useState(pramesUnitId); //单元id

  const [curUnitType, setCurUnitType] = useState(1);
  const [unitDetail, setUnitDetail] = useState(); //单元详情
  const [curPdf, setCurPdf] = useState(); //当前下载的pdf路径
  const [contractInfo, setContractInfo] = useState<any>(null); //协议信息
  const [liveUrl, setLiveUrl] = useState(); //直播的路径
  const videoInstance = useRef<VideoPlayer>(); //视频实例
  const [curCode, setCurCode] = useState("");
  const [curVid, setCurVid] = useState("");

  interface VideoPlayer {
    getPosition(): number;
  }
  useEffect(() => {
    // 组件挂载时，创建script标签
    const myScript = document.createElement("script");
    // 设置标签的src属性
    myScript.src =
      "https://p.bokecc.com/player?siteid=43F3486107E0C9EF&newversion=true";
    // 明确设置为同步加载
    myScript.async = false;
    // 追加到body标签的最下面
    document.body.appendChild(myScript);
    return () => {
      // 组件即将卸载时，移除标签
      document.body.removeChild(myScript);
      window.cc_js_Player && window.cc_js_Player.destroy();
    };
  }, []);
  //     <script>
  //     function onCCH5PlayerLoaded() {
  //       window.ccH5PlayerJsLoaded = true;
  //     }
  //   </script>
  // 获取商品详情
  useEffect(() => {
    commodityId && getDetilData(commodityId);
  }, [commodityId]);

  const getDetilData = async (id: String) => {
    const res = await getGoodsDeatil(id);
    setCourseData(res);
  };

  // 根据产品获取树的详情---更换新产品树此处取消获取产品树详情
  // useEffect(() => {
  //   productId && getDetilDataByPro(productId);
  // }, [productId]);
  // const getDetilDataByPro = async (id: String) => {
  //   const res = await getMyLessons({ id });
  //   setCourseData({ productTrees: [res] });
  // };

  const startTime = new Date().getTime();
  useEffect(() => {
    if (!curCode || curCode == "" || !curVid || curVid == "") {
      return;
    }
    const timer = setInterval(() => {
      heartbeat(curVid, curCode, startTime);
    }, 1000 * 1 * 60);
    return () => {
      // 组件即将卸载时，移除定时器
      clearInterval(timer);
    };
  }, [curVid, curCode, token]);
  async function heartbeat(vid: string, code: string, startTime: number) {
    // if (isPreview.value) {
    //   return;
    // }
    console.log(vid, "vid");
    let realPlayedTime = 0;
    console.log(videoInstance, "videoInstancevideoInstance");

    if (videoInstance.current) {
      realPlayedTime = videoInstance.current?.getPosition();
    }
    await heartbeatApi({
      vid,
      code,
      playedTime: Math.floor(realPlayedTime ? realPlayedTime : 0),
      startTime,
      realPlayedTime: Math.floor((new Date().getTime() - startTime) / 1000), ///s
    });
  }

  // 头部导航
  useEffect(() => {
    configTopCrumbList();
  }, [curUnitId, courseData]);
  // 初始化播放数据
  useEffect(() => {
    if (curUnitId && curUnitId != "") {
      setPlayerCallBack();
      getVideoPrames();
    }
  }, [curUnitId]);
  const getVideoPrames = async () => {
    const res = await getAuthorizeAndSign({
      productId: curProductId,
      unitId: curUnitId,
    });
    const { code, errCode, contractInfoList } = res;
    if (errCode === 2352) {
      setIsSignOpen(true);
      if (contractInfoList && contractInfoList.length > 0) {
        setContractInfo(contractInfoList[0]);
      }
      return;
    }
    const unitDetailRes = await getUnitDetail({ code });
    console.log(unitDetailRes, "详情");
    setCurUnitType(unitDetailRes.type);
    setCurPdf(unitDetailRes.material);
    setUnitDetail({ ...unitDetailRes, isPurchase });
    console.log(curPdf, "pdf");
    if (unitDetailRes.type == 0) {
      // 资料
    } else if (unitDetailRes.type == 1) {
      setCurCode(code);
      setCurVid(unitDetailRes.videoId);
      // 视频
      if (isPurchase == "true") {
        // 已购买
        const unitListRes = await getUnitList({ code });
        const pdf = unitListRes.material;
        const lastPoint = unitListRes.lastPoint;
        const videoId = unitListRes.videoId;
        const vc = Base64.toBase64(
          JSON.stringify({ token, unit: curUnitId, product: curProductId })
        );
        setCurPdf(pdf);
        if (window.ccH5PlayerJsLoaded) {
          createPlayer(videoId, vc, lastPoint);
        }
      } else {
        if (window.ccH5PlayerJsLoaded) {
          const vc = Base64.toBase64(
            JSON.stringify({ token, unit: curUnitId, product: curProductId })
          );
          console.log(
            unitDetailRes.videoId,
            vc,
            unitDetailRes.lastPoint,
            token,
            "播放参数"
          );
          createPlayer(unitDetailRes.videoId, vc, unitDetailRes.lastPoint);
        }
      }
    } else if (unitDetailRes.type == 4) {
      setCurCode(code);
      // 直播
      if (unitDetailRes.status === 0 || unitDetailRes.status === 1) {
        setLiveUrl(unitDetailRes.indexUrl);
      } else {
        const res = await getReplayList({
          productId: curProductId,
          liveRoomId: unitDetailRes.liveRoomId,
        });
        // 回看
        if (res.length > 1) {
          // 回放列表
          window.open(
            `${window.location.origin}/#/liveReplay?productId=${curProductId}&liveRoomId=${unitDetailRes.liveRoomId}&unitName=${curUnitName}`
          );
        } else {
          const url = res.length == 0 ? unitDetailRes.indexUrl : res[0].playUrl;
          setLiveUrl(url);
        }
      }
    }
  };
  const handleCallback = async (unit: any) => {
    console.log(unit, "当前点击");
    if (
      (unit.type === 1 || unit.type === 0 || unit.type === 4) &&
      (unit.isPurchase || unit.preview)
    ) {
      if (unit.id == curUnitId && contractInfo) {
        setIsSignOpen(true);
      }
      // 视频和资源
      setPlayUnit(unit.productName, unit.productId, unit.name, unit.id);
      setCurProductName(unit.productName);
      setCurProductId(unit.productId);
      setCurUnitName(unit.name);
      setCurUnitId(unit.id);
    } else {
      if (unit.isPurchase || unit.preview) {
        setQrCodeUrl(`${window.location.origin}/#/`);
        setIsModalOpen(true);
      } else {
        handleBuyCallback(unit);
      }
    }
  };
  const configTopCrumbList = () => {
    let topCrumbs = [
      {
        title: "首页",
        herf: "/",
      },
    ];
    if (from == "1") {
      topCrumbs.push({
        title: "我的课程",
        herf: "/personalCenter",
      });
    } else {
      if (courseData) {
        topCrumbs.push({
          title: courseData.name,
          herf: `/courseDetail?id=${courseData.id}`,
        });
      }
    }
    // if(curUnitName){
    //     topCrumbs.push(
    //         {
    //             title:curProductName,
    //             herf:''
    //         }
    //     );
    //     topCrumbs.push(
    //         {
    //             title:curUnitName,
    //             herf:''
    //         }
    //     );
    // }
    console.log(topCrumbs);
    setCrumbList(topCrumbs);
  };
  // 处理二维码弹框
  const handleCloseQrCode = () => {
    setIsModalOpen(false);
  };
  // 签署协议处理
  const handleOk = () => {
    // 签署协议跳转
    const redirectUrl = `/videoPlay?commodityId=${commodityId}&isPurchase=${isPurchase}`;
    navigate(
      `/Agreement?pdfurl=${contractInfo?.contractUrl}&orderContractId=${contractInfo?.contractHistoryId}&redirectUrl=${redirectUrl}`
    );
  };
  const handleCancel = () => {
    setIsSignOpen(false);
  };
  // 播放器相关
  function setPlayerCallBack() {
    window.onCCH5PlayerLoaded = onCCH5PlayerLoaded();
    window.on_CCH5player_play = on_CCH5player_play;
    window.on_player_volumechange = onPlayerVolumeChange;
  }
  function onCCH5PlayerLoaded() {
    window.ccH5PlayerJsLoaded = true;
  }
  function on_CCH5player_play(video: any, vid: any) {
    const volume = localStorage.getItem(`volume`);
    console.log("播放回调:  ", window, vid, video, volume);
    window.cc_js_Player.setVolume(volume ? parseFloat(volume) : 0.3);
  }
  function onPlayerVolumeChange(volume: any, vid: any) {
    const volumeFlag = localStorage.getItem(`volumeFlag`);
    console.log("设置音量回调:  ", volume, vid, volumeFlag);
    if (volumeFlag == "1") {
      localStorage.setItem(`volume`, volume.toString());
    } else {
      localStorage.setItem(`volumeFlag`, "1");
    }
  }
  function createPlayer(vid: string, vc: string, lastPoint: string) {
    // const baseurl=   import.meta.env.APP_SITE_ID
    const siteid = getSiteId();
    console.log(siteid, "siteid");
    localStorage.setItem(`volumeFlag`, "0");
    window.createCCH5Player &&
      window.createCCH5Player({
        parentNode: document.getElementById("video-box"),
        vid,
        siteid,
        playtype: "1",
        autoStart: true,
        realAutoPlay: true,
        width: "100%",
        height: "100%",
        isShare: "false",
        watchStartTime: lastPoint == "undefined" ? 0 : lastPoint,
        mobileFullsMarquee: "false",
        isShowMoreBtn: "false",
        allowFullScreen: true,
        vc,
        freeTimeEndTip: "试看结束",
      });
  }

  // 购买跳转
  const [timeList, setTimeList] = useState<any>([]); //根据已选skuId获取对应使用期限
  const [goodsInfo, setGoodsInfo] = useState<any>({});
  const [open, setOpen] = useState<boolean>(false);
  const [numPages, setNumPages] = useState(null);
  const onDocumentLoadSuccess = (e: any) => {
    setNumPages(e.numPages);
  };
  const handleBuyCallback = async (item: any) => {
    const { id: unitId, skuId = "", mode = 1 } = item;
    const goodsData = await getGoodsDeatils(commodityId);
    const ret = await getActivityInfo(commodityId);
    if (ret?.activityId) {
      goodsData.activityType = ret.type;
      if (ret?.activityStatus == 0) {
        goodsData.activityId = ret.activityId;
        goodsData.skuList = ret?.skuData;
      } else {
        goodsData.startTimeStr = ret.startTimeStr;
      }
      goodsData.activityStatus = ret?.activityStatus;
      goodsData.groupNum = ret?.number || 0;
      goodsData.inDate = ret.inDate;
      // setActivityId(ret?.activityId);
      // setAmount(ret?.amount);
      if (goodsData.activityType == 4) {
        goodsData.skuList.forEach((item: any) => {
          item.stock = 10000;
        });
      }
    }
    console.log(goodsData, "goodsData");
    setGoodsInfo(goodsData);
    //判断是否有使用期限
    const timeLimit = goodsData?.skuOptions.filter(
      (item: any) => item.type === 1
    );
    if (skuId) {
      const result = goodsData?.skuList?.find(
        (sku: any) => +sku.skuId === +skuId
      );
      let currentSkuObj = result ? result : goodsData?.skuList[0];
      nabigateByMode(goodsData, mode, timeLimit, currentSkuObj);
    } else {
      nabigateByMode(goodsData, mode, timeLimit, goodsData?.skuList[0]);
    }
  };
  const nabigateByMode = async (
    goodsData: any,
    mode: number,
    timeLimit: Array<any>,
    currentSkuObj: any
  ) => {
    console.log(mode === 2 && goodsData?.contract, "zoule");
    if (timeLimit.length === 0) {
      mode === 2 && goodsData?.contract
        ? setOpen(true)
        : navigate("/orderSettlement", {
            state: {
              selectList: [
                {
                  ...currentSkuObj,
                  number: 1,
                  thumbnail: goodsData?.thumbnail,
                  name: goodsData?.name,
                  price: currentSkuObj.price,
                },
              ],
              id: goodsData.id,
              contract: goodsData?.contract || {},
              activityId: goodsData?.activityId || "",
            },
          });
    } else {
      let res = await getGoodsUseLimit(currentSkuObj?.skuId);
      setTimeList(res);
      mode === 2 && goodsData?.contract
        ? setOpen(true)
        : navigate("/orderSettlement", {
            state: {
              selectList: [
                {
                  ...currentSkuObj,
                  number: 1,
                  thumbnail: goodsData?.thumbnail,
                  name: goodsData?.name,
                  price: currentSkuObj.price,
                },
              ],
              id: goodsData.id,
              contract: goodsData?.contract || {},
              activityId: goodsData?.activityId || "",
              timeList: res,
            },
          });
    }
  };
  const signFinish = () => {
    setOpen(false);
    //判断是否有使用期限
    const timeLimit = goodsInfo?.skuOptions.filter(
      (item: any) => item.type === 1
    );
    //无时间期限规格 直接跳转确认订单页
    if (timeLimit.length === 0) {
      navigate("/orderSettlement", {
        state: {
          selectList: [
            {
              ...goodsInfo?.skuList[0],
              name: goodsInfo.name,
              number: 1,
              thumbnail: goodsInfo?.thumbnail,
              price: goodsInfo?.skuList[0].price,
            },
          ],
          id: goodsInfo.id,
          contract: goodsInfo?.contract || {},
          activityId: goodsInfo?.activityId || "",
        },
      });
    } else {
      navigate("/orderSettlement", {
        state: {
          selectList: [
            {
              ...goodsInfo?.skuList[0],
              number: 1,
              thumbnail: goodsInfo?.thumbnail,
              name: goodsInfo?.name,
              price: goodsInfo?.skuList[0].price,
            },
          ],
          id: goodsInfo.id,
          contract: goodsInfo?.contract || {},
          activityId: goodsInfo?.activityId || "",
          timeList,
        },
      });
    }
  };

  return (
    <div>
      <VideoTop crumbList={crumbList}></VideoTop>
      <div className="video-play-box">
        {curUnitType == 4 ? (
          <LivePlay url={liveUrl}></LivePlay>
        ) : (
          <div className="video-content-box">
            {curUnitType == 0 ? (
              <PdfPreview unitDetail={unitDetail}></PdfPreview>
            ) : null}
            {curUnitType == 1 ? (
              <div id="video-box" className="video-box"></div>
            ) : null}
          </div>
        )}
        <VideoLeft
          courseData={courseData}
          commodityId={commodityId}
          unitType={curUnitType}
          pdf={curPdf}
          unitId={curUnitId}
          callback={handleCallback}
        ></VideoLeft>
      </div>
      <QrcodeModal
        isShow={isModalOpen}
        type={"study"}
        onCloseQrCode={handleCloseQrCode}
      />
      <Modal
        title=""
        className="sign-modal-wrap"
        centered
        closable={false}
        open={isSignOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={null}
      >
        <PopSign handleOk={handleOk} handleCancel={handleCancel}></PopSign>
      </Modal>
      <Modal
        open={open}
        onCancel={() => setOpen(false)}
        centered
        className="xy-modal"
        footer={null}
        width={680}
      >
        <div className="modal-title">查看协议</div>
        <div className="video-pdf-box">
          <Document
            file={goodsInfo?.contract?.url}
            className="document-pdf"
            onLoadSuccess={onDocumentLoadSuccess}
          >
            {Array.from(new Array(numPages), (el, index) => (
              <Page
                key={`page_${index + 1}`}
                pageNumber={index + 1}
                renderAnnotationLayer={false}
                renderTextLayer={false}
                scale={1.5}
              />
            ))}
          </Document>
        </div>
        <div className="modal-footer">
          <div className="sure-button" onClick={signFinish}>
            我已阅读并同意此协议
          </div>
        </div>
      </Modal>
    </div>
  );
}

export default VideoPlay;
