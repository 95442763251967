import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { getAllInfoClassify, getInfoList } from "@/apis/information";
import { handleBread } from "@/utils/bread";
import "./css/information.scss";
import Info from "@/components/Information";
function Information(props: any) {
  const { title, showTitle, showMore, tabList, customStyle, showNum } =
    props.data;
  const [inforTab, setInfoTab] = useState<any>([]);
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const changeTab = (id: any) => {
    const options = tabList.find((item: any) => item.id == id);
    getInfoListbyCategory(options.id, Number(options.sizes));
  };
  const goInfoDetail = (id: any) => {
    handleBread({ title: "资讯详情", href: "/infoDetail" });
    navigate("/infoDetail", { state: { id: id } });
  };
  const getInfoListbyCategory = async (id: Number, sizes: any) => {
    const res = await getInfoList({
      classifyId: id,
      index: 0,
      row: sizes ? sizes : showNum,
    });
    setData(res.body || []);
    // setData([]);
  };
  useEffect(() => {
    const getInfoTab = async () => {
      // const { body = [] } = await getAllInfoClassify();
      // setInfoTab(body);
      // const [cid = {}] = body;
      const { id, sizes } = tabList[0] || {};
      if (id && sizes) {
        getInfoListbyCategory(id, Number(sizes));
      }
    };
    getInfoTab();
  }, []);
  const goAllInformation = () => {
    handleBread({ title: "全部资讯", href: "/AllInformation/" });
    navigate("/AllInformation/", {
      state: {
        tabList: tabList,
      },
    });
  };
  return (
    <>
      <div className="informationBoxHome" style={customStyle}>
        <div className="informationBox">
          <div className="info-top">
            {showTitle ? <div className="info-title">{title}</div> : null}
            {showMore && showTitle ? (
              <div className="more-btn" onClick={() => goAllInformation()}>
                {"查看更多 "}
                <span className="iconfont icon-symbol_right"></span>
              </div>
            ) : null}
          </div>

          <Info
            tabList={tabList}
            infoList={data}
            changeTab={changeTab}
            showNum={showNum}
            goInfoDetail={goInfoDetail}
          />
        </div>
      </div>
    </>
  );
}

export default Information;
