import "./css/loginWelcome.scss";
interface Iprops {
  appName: string; //机构名称
  handleToLogin: Function; // 点击去登录的方法
}
function LoginWelcome(props: Iprops) {
  const { appName, handleToLogin } = props;
  return (
    <div className="login-welcome">
      <div className="welcome-title1">
        <div className="img"></div>
        <div className="welcome-text1">
          <p className="welcome-name1">亲爱的学员</p>
          {appName ? <p className="welcome-name2">欢迎来到{appName}</p> : null}
        </div>
      </div>
      <div className="welcome-img"></div>
      <button onClick={() => handleToLogin()}>登录/注册</button>
    </div>
  );
}
export default LoginWelcome;
