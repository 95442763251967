import { request } from "@/utils/request";

// 获取该商品下用户已领取的优惠券(包含可用-不可用)
export function getPayCoupon(data: any) {
  return request({
    url: `/util/coupon/usable/list?commodityId=${data.id}&amount=${data.amount}`,
    method: "GET",
  });
}

// 创建订单 /trade/order
export function createOrder(data: any) {
  return request({
    url: `/trade/order`,
    method: "POST",
    data,
  });
}
// 创建订单V2  /trade/v2/order 营销活动
export function createOrderV2(data: any) {
  return request({
    url: `/trade/v2/order`,
    method: "POST",
    data,
  });
}
// 获取协议记录状态 /trade/order/contract/info
export function getSignStatus(recordId: string) {
  return request({
    url: `/trade/order/contract/info?orderContractId=${recordId}`,
    method: "GET",
  });
}
// 签署协议 /trade/order/contract/sign
export function signRecord(data: any) {
  return request({
    url: `/trade/order/contract/sign`,
    method: "POST",
    data,
  });
}
// 获取渠道id用于拼接协议地址
export function getChannelId() {
  return request({
    url: "/base/app/application",
    method: "GET",
  });
}
//获取机构信息
export function getTenantInfo() {
  return request({
    url: "/base/app/tenant/info",
    method: "GET",
  });
}
//获取机构独立域名信息
export function getTenantDomain() {
  return request({
    url: "/manage/tenant/pc/independent/domain",
    method: "GET",
  });
}
// 获取订单实付金额
export function getRealPrice(data: any) {
  return request({
    url: `/trade/v2/order/count`,
    method: "POST",
    data,
  });
}
