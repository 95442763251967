/*
 *横向滑动商品组件
 */
import { Empty } from "antd";
import "./css/scrollList.scss";
import { useEffect, useState } from "react";
import ExModal from "../../pages/OrderSettlement/comps/ExplainModal";
interface Iprops {
  data: any[];
  showMore: Boolean;
  title: string;
  toShowMore: any;
  showTitle: Boolean;
  showNum: Number;
  hideLossEfficacy: Boolean;
  handlerClick: any;
  customStyle: any;
}

const HorizontalScroll = (props: Iprops) => {
  const {
    showMore,
    data,
    title,
    toShowMore,
    showTitle,
    showNum,
    hideLossEfficacy,
    handlerClick,
    customStyle,
  } = props;
  const [scrollPosition, setScrollPosition] = useState(0);
  const [exModalOpen, setExModalOpen] = useState(false); // 说明弹窗的显示与隐藏
  const [curExplain, setCurExplain] = useState<string>("使用说明");
  const [showData, setShowData] = useState<any[]>([]); // 实际展示数据
  const handleScrollRight = () => {
    const element = document.getElementById("scroll-list");
    let x = element?.scrollWidth;
    if (x && x - scrollPosition > 472 * 2) {
      setScrollPosition(scrollPosition + 472);
    }
  };
  const showModal = (text: string) => {
    setCurExplain(text);
    setExModalOpen(true);
  };
  const handleScrollLeft = () => {
    if (scrollPosition === 0) {
      return;
    } else {
      setScrollPosition(scrollPosition - 472);
    }
  };
  // 处理展示的数据
  useEffect(() => {
    if (data?.length > 0) {
      let result: any[] = [];
      if (hideLossEfficacy) {
        const temp =
          data.filter((x) => x.viewStatus == 0 || x.viewStatus == 1) || [];
        temp?.length > 0 &&
          temp.map((item, index: any) => {
            if (index < showNum) {
              result.push(item);
            }
          });
      } else {
        data.map((item, index: any) => {
          if (index < showNum) {
            result.push(item);
          }
        });
      }
      setShowData(result);
    } else {
      setShowData([]);
    }
  }, [data, hideLossEfficacy, showNum]);
  return (
    <div className="comp-content" style={customStyle}>
      {showTitle ? (
        <div className="comp-top">
          <div className="comp-title">{title}</div>
          {showMore ? (
            <div className="look-more" onClick={() => toShowMore()}>
              查看更多{" "}
              <span className=" more-icon-01 iconfont icon-symbol_right"></span>
            </div>
          ) : null}
        </div>
      ) : null}
      <div className="scroll-main">
        {data.length > 2 ? (
          <div className="left-button" onClick={handleScrollLeft}>
            <i className="iconfont icon-symbol_left"></i>
          </div>
        ) : null}
        {data.length > 2 ? (
          <div className="right-button" onClick={handleScrollRight}>
            <i className="iconfont icon-symbol_right"></i>
          </div>
        ) : null}
        <div
          className="scroll-list"
          id="scroll-list"
          style={{ transform: `translateX(-${scrollPosition}px)` }}
        >
          {showData && showData?.length > 0 ? (
            showData.map((item: any, indexS: number) => {
              return (
                <div
                  className={
                    item?.viewStatus == 2
                      ? "card-box-coupon bg-use-small"
                      : item.viewStatus == 3
                      ? "card-box-coupon bg-lose-small"
                      : item.viewStatus == 4
                      ? "card-box-coupon bg-time-small"
                      : "card-box-coupon bg-active-small"
                  }
                  key={indexS}
                >
                  <div className="left-card-coupon">
                    {item.type == 0 ? (
                      <div className="left-type-0">
                        <div className="coupon-price-box">
                          <div className="coupon-price-box-icon">￥</div>
                          <div className="coupon-price-box-num">
                            {item.discount}
                          </div>
                        </div>
                        <div className="type-0-text">{item.content}</div>
                      </div>
                    ) : (
                      <div className="left-type-1">
                        <div className="coupon-discount-box">
                          <div className="coupon-discount-box-num">
                            {item.discount}
                          </div>
                          <div className="coupon-discount-box-icon">折</div>
                        </div>
                        <div className="type-1-text">{item.content}</div>
                      </div>
                    )}
                    {item?.viewStatus == 2 ||
                    item?.viewStatus == 3 ||
                    item?.viewStatus == 4 ? null : (
                      <div
                        onClick={() =>
                          handlerClick(
                            item.id,
                            item?.viewStatus,
                            item?.usableRange
                          )
                        }
                        className="coupon-btn"
                      >
                        {item?.viewStatus == 1 ? "去使用" : "点击领取"}
                      </div>
                    )}
                  </div>
                  <div className="right-card-coupon">
                    <div className="coupon-name">{item?.name}</div>
                    <div
                      className={
                        item?.viewStatus == 2 ||
                        item?.viewStatus == 3 ||
                        item?.viewStatus == 4
                          ? "coupon-time-white ellipsis-two"
                          : "coupon-time ellipsis-two"
                      }
                    >
                      {item?.useTime}
                    </div>
                    <div className="coupon-explain-box">
                      <div
                        className={
                          item?.viewStatus == 2 ||
                          item?.viewStatus == 3 ||
                          item?.viewStatus == 4
                            ? "coupon-explain-text coupon-explain-text-white"
                            : "coupon-explain-text"
                        }
                      >
                        使用说明
                      </div>
                      <div
                        className={
                          item?.viewStatus == 2 ||
                          item?.viewStatus == 3 ||
                          item?.viewStatus == 4
                            ? "coupon-explain-icon-white"
                            : "coupon-explain-icon"
                        }
                        onClick={() => showModal(item?.instructions)}
                      ></div>
                    </div>
                  </div>
                </div>
              );
            })
          ) : (
            <Empty
              description="暂无数据"
              style={{
                minHeight: 300,
                width: 1200,
                textAlign: "center",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
            />
          )}
        </div>
      </div>
      <ExModal
        isShow={exModalOpen}
        onClose={() => {
          setExModalOpen(false);
        }}
        text={curExplain}
      />
    </div>
  );
};
export default HorizontalScroll;
