/* eslint-disable  */
import React, { useState, useEffect } from "react";
// import { useParams } from "react-router-dom";
import { Col, Row } from "antd";
import OrderDeatailItem from "./OrderDeatailItem";
import "../css/gift.scss";

function Gift(props: any) {
  const {
    info,
    curSelectGifiActivityList,
    fromType = "order",
    orderStatus = 1,
  } = props;
  const [giftInfo, setGiftInfo] = useState<any>({});
  const [giftNum, setGiftNum] = useState(0);
  console.log(info, "infoinfoinfoinfoinfoinfo-00");

  // 获取赠品信息
  useEffect(() => {
    let goods: any[] = [],
      exchangeList: any[] = [],
      couponList: any[] = [];
    info?.length > 0 &&
      fromType == "pay" &&
      info.map((item: any) => {
        if (item.giftType == 1) {
          if (curSelectGifiActivityList?.length > 0) {
            if (
              JSON.stringify(curSelectGifiActivityList).includes(
                JSON.stringify(item)
              )
            ) {
              for (let child of item.giftCouponInfos) {
                couponList.push(child);
              }
            }
          } else {
            for (let child of item.giftCouponInfos) {
              couponList.push(child);
            }
          }
        } else if (item.giftType == 2) {
          if (curSelectGifiActivityList?.length > 0) {
            if (
              JSON.stringify(curSelectGifiActivityList).includes(
                JSON.stringify(item)
              )
            ) {
              for (let child of item.giftRedeemCodeInfos) {
                exchangeList.push(child);
              }
            }
          } else {
            for (let child of item.giftRedeemCodeInfos) {
              exchangeList.push(child);
            }
          }
        } else if (item.giftType == 3) {
          if (curSelectGifiActivityList?.length > 0) {
            if (
              JSON.stringify(curSelectGifiActivityList).includes(
                JSON.stringify(item)
              )
            ) {
              for (let child of item.giftSkuInfos) {
                goods.push({ ...child, number: 1 });
              }
            }
          } else {
            for (let child of item.giftSkuInfos) {
              goods.push({ ...child, number: 1 });
            }
          }
        }
      });
    // 订单详情的处理
    if (info?.length > 0 && fromType == "order") {
      goods = info.filter((item: any) => {
        return item.giftType == 3;
      });
      if (goods?.length > 0) {
        goods.forEach((item: any) => {
          item.thumbnail = item.img;
          item.name = item.giftName;
          item.skuName = item.instructions;
          item.number = 1;
          item.price = item.giftPrice;
          item.linePrice = item.giftLinePrice;
        });
      }
      exchangeList = info.filter((item: any) => {
        return item.giftType == 2;
      });
      exchangeList.length > 0 &&
        exchangeList.forEach((item: any) => {
          if (orderStatus == 1) {
            item.name = item.giftName;
          } else {
            item.name = item.codeName;
          }
        });
      couponList = info.filter((item: any) => {
        return item.giftType == 1;
      });
    }
    let obj = {
      goods,
      exchangeList,
      couponList,
    };
    setGiftInfo(obj);
    setGiftNum(
      obj?.goods?.length + obj?.couponList?.length + obj?.exchangeList?.length
    );
  }, [info, curSelectGifiActivityList, fromType, orderStatus]);
  // 渲染赠品中的商品
  const renderGiftGoods = (data: any) => {
    return (
      <div>
        <Row className="order-info-header">
          <Col span={12}>商品</Col>
          <Col span={4}>原价</Col>
          <Col span={4}>现价</Col>
          <Col span={4}>数量</Col>
        </Row>
        {data.map((item: any, index: number) => {
          return (
            <OrderDeatailItem
              clearGroupAndSeckill={false}
              commodityInfo={item}
              key={index}
            ></OrderDeatailItem>
          );
        })}
      </div>
    );
  };
  // 渲染赠品中的兑换码
  const renderGiftExchange = (data: any) => {
    return (
      <div
        className={
          giftInfo?.goods?.length > 0
            ? "gift-exchange-order-box top-32"
            : "gift-exchange-order-box"
        }
      >
        {data.map((item: any, index: number) => {
          return (
            <div className="gift-exchange-order-one flex" key={index}>
              <div className="gift-exchange-order-one-left">兑换码</div>
              <div className="gift-exchange-order-one-right">
                {item?.name || item?.giftName}
              </div>
            </div>
          );
        })}
      </div>
    );
  };
  // 渲染赠品中的优惠券
  const renderGiftCoupon = (data: any) => {
    return (
      <div
        className={
          giftInfo?.goods?.length > 0 || giftInfo?.exchangeList?.length > 0
            ? "gift-coupon-order-box top-32"
            : "gift-coupon-order-box"
        }
      >
        {data.map((item: any, index: number) => {
          return (
            <div className="gift-coupon-order-one flex" key={index}>
              <div className="gift-coupon-order-one-left">优惠券</div>
              <div className="gift-coupon-order-one-right">
                {" "}
                {item?.name || item?.giftName}
              </div>
            </div>
          );
        })}
      </div>
    );
  };

  return (
    <div className="order-settlement-gift">
      <div className="box-title-gift">
        赠品 ( 共<span className="box-title-gift-nums">{giftNum}</span>件 )
      </div>
      <div className="box-content-gift">
        {giftInfo?.goods?.length > 0 ? renderGiftGoods(giftInfo.goods) : null}
        {giftInfo?.exchangeList?.length > 0
          ? renderGiftExchange(giftInfo.exchangeList)
          : null}
        {giftInfo?.couponList?.length > 0
          ? renderGiftCoupon(giftInfo.couponList)
          : null}
      </div>
    </div>
  );
}

export default Gift;
