// import { useEffect, useState } from "react";
import "./learnInfoGoodsShow.scss";

function LearnInfoGoodsShow(props: any) {
  const { progressInfo = {}, currentType = "" } = props;
  const speedRate = (+progressInfo.speedRate * 100).toFixed();
  // useEffect(() => {
  //   // const { doneMap = {} } =
  //   //   statics?.find((item: any) => item.nodeType === "TUD") || {};
  // }, [currentType]);
  const subjectName = progressInfo.subjectName;
  console.log(props, "props-----");

  // useEffect(() => {
  //   if (progressInfo.speedRate) {
  //     const percent = Math.ceil((+progressInfo.speedRate * 100) / 5) * 5;
  //     // return `https://assets.wangxiao.cn/images/circle-progress/${percent}.png`;
  //     setProgressUrl(
  //       `https://assets.wangxiao.cn/images/circle-progress/${percent}.png`
  //     );
  //   } else {
  //     return "";
  //   }
  // }, [progressInfo.speedRate]);

  // 格式化时间
  const secondsToTime = (sec: number) => {
    console.log(sec, "sec-----sec");

    // 计算小时
    const hours = Math.floor(sec / 3600);
    // 计算分钟
    const minutes = Math.floor((sec % 3600) / 60);
    // 构造结果字符串
    let result = "";
    if (hours > 0) {
      result += hours + "时";
    } else {
      result += "0时";
    }
    result += minutes + "分";
    console.log(result, "result----");

    return result;
  };

  return (
    <div className="chapter-content-wrap">
      <div className="chapter-progress-wrap">
        <div className="chapter-progress-text">
          <div className="progress-info">
            <span className="text-color">{speedRate}</span>
            <span className="percent-sign">%</span>
          </div>

          <div className="progress-text-add">{"学习进度"} </div>
        </div>
        <img
          className="chapter-progress-img"
          src={`https://assets.wangxiao.cn/images/circle-progress/${
            Math.ceil((+progressInfo.speedRate * 100) / 5) * 5
          }.png`}
          alt="学习进度"
        />
      </div>
      <div className="chapter-right-content-wrap">
        {/* <div className="chapter-detail-title">{subjectName}</div> */}
        <div className="chapter-bottom-content-wrap">
          <div className="chapter-learned-status">
            <text className="doneText">已学</text>
            <text className="doneText-Num">
              {currentType != 1 ? progressInfo.done : null}
              {currentType == 2 || currentType == 3
                ? "道"
                : currentType == 4 || currentType == 0
                ? "个"
                : currentType == 1
                ? secondsToTime(progressInfo.done ? progressInfo.done : 0)
                : ""}
            </text>
          </div>
          <div className="chapter-middle-line"></div>
          <div className="chapter-learned-duration">
            <text className="totalText">
              {currentType == 2 || currentType == 3
                ? "总习题"
                : currentType == 4
                ? "总直播数"
                : currentType == 0
                ? "总资料数"
                : currentType == 1
                ? "总学时"
                : ""}
            </text>
            <text className="totalText-Num">
              {currentType != 1 ? progressInfo.num || 0 : null}
              {currentType == 2 || currentType == 3
                ? "道"
                : currentType == 4 || currentType == 0
                ? "个"
                : currentType == 1
                ? secondsToTime(progressInfo.num ? progressInfo.num : 0)
                : ""}
            </text>
          </div>
        </div>
      </div>
    </div>
  );
}

export default LearnInfoGoodsShow;
