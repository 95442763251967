import React, { useEffect, useState } from "react";
import { Modal, QRCode } from "antd";
import channelStore from "@/store/channel";
import "./css/index.scss";

interface Iprops {
  isShow: boolean; // 是否展示弹框
  type: string; // study-学习 | purchase-购买 | group-拼团 | address-补填地址
  onCloseQrCode: Function; // 关闭弹框回调
  groupUrl?: string; // 拼团的地址
  addressUrl?: string; // 订单补填地址url
}

const QrcodeModal = (props: Iprops) => {
  const { type, onCloseQrCode, groupUrl, addressUrl = "" } = props;
  const [isModalOpen, setIsModalOpen] = useState(false);
  console.log(
    channelStore?.channelInfo?.h5Domain,
    "channelStore?.channelInfo?.h5Domain"
  );

  useEffect(() => {
    setIsModalOpen(props.isShow);
  }, [props.isShow]);

  const handleCancel = () => {
    onCloseQrCode(false);
  };
  return (
    <Modal
      title=""
      centered
      open={isModalOpen}
      onCancel={handleCancel}
      footer={null}
      wrapClassName={
        type == "group" ? "qrcode-pop-wrap-radius" : "qrcode-pop-wrap"
      }
      width={680}
    >
      <div className="qrcode-content-wrap">
        {type === "purchase" ? (
          <img
            src="https://resource.wangxiao.net/pc/images/buy.png"
            alt=""
            style={{ width: "100%" }}
          />
        ) : type === "group" ? (
          <img
            src={require("./imgs/group-invite.png")}
            alt=""
            style={{ width: "100%" }}
          />
        ) : type === "address" ? (
          <img
            src={require("./imgs/address-modal.png")}
            alt=""
            style={{ width: "100%" }}
          />
        ) : (
          <img
            src="https://resource.wangxiao.net/pc/images/study.png"
            alt=""
            style={{ width: "100%" }}
          />
        )}
        {channelStore?.channelInfo?.h5Domain ? (
          <QRCode
            className="qr-image"
            value={
              type === "group"
                ? `${channelStore?.channelInfo?.h5Domain}/#${groupUrl}`
                : type === "address"
                ? `${channelStore?.channelInfo?.h5Domain}/#${addressUrl}`
                : channelStore?.channelInfo?.h5Domain
            }
          />
        ) : null}
      </div>
    </Modal>
  );
};

export default QrcodeModal;
