import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Col, Row } from "antd";
import "../css/index.scss";

const OrderDeatailItem: React.FC<{
  commodityInfo: any;
  clearGroupAndSeckill: any;
}> = ({ commodityInfo, clearGroupAndSeckill = false }) => {
  const navigate = useNavigate();

  return (
    <div className="order-info-item" key={commodityInfo.id}>
      <Row>
        <Col className="order-info-border-right" span={12}>
          <div className="order-info-commodity">
            <img src={commodityInfo.thumbnail} className="order-info-img" />
            <div className="order-commodity-name">
              <div className="commodity-name">{commodityInfo.name}</div>
              <div className="commodity-skuName">{commodityInfo.skuName}</div>
            </div>
          </div>
        </Col>
        <Col
          className="order-info-border-right order-info-center gray"
          span={4}
        >
          ￥{commodityInfo.linePrice}
        </Col>
        <Col className="order-info-border-right order-info-center" span={4}>
          ￥
          {clearGroupAndSeckill && commodityInfo.price1
            ? commodityInfo.price1
            : commodityInfo.price}
        </Col>
        <Col className="order-info-center" span={4}>
          {commodityInfo.number}
        </Col>
      </Row>
    </div>
  );
};
export default OrderDeatailItem;
