import { useLocation } from "react-router-dom";
import "./css/index.scss";
import BreadCrumb from "@/components/BreadCrumb";
import { getInfoDetail } from "@/apis/information";
import { useEffect, useRef, useState } from "react";
function InfoDetail() {
  const {
    state: { id },
  } = useLocation();
  const initialized = useRef(false);
  const getContent = async (id: any) => {
    const res = await getInfoDetail(id);
    setContent(res.content);
    setTitle(res.title);
    setSummary(res.summary);
    setPublishTime(res.publishTime);
    setIssuer(res.issuer);
    setReadingAmount(res.readingAmount);
    res.subhead ? setSubhead(res.subhead) : setSubhead("");
  };
  const [content, setContent] = useState<any>(); //资讯内容
  const [title, setTitle] = useState<any>(); //标题
  const [summary, setSummary] = useState<any>(); //概述
  const [publishTime, setPublishTime] = useState<any>(); //发布时间
  const [issuer, setIssuer] = useState<any>(); //发布人
  const [readingAmount, setReadingAmount] = useState<any>(); //阅读量
  const [subhead, setSubhead] = useState<any>(); //副标题
  useEffect(() => {
    if (!initialized.current) {
      initialized.current = true;
      getContent(id);
    }

    // setContent(res.content)
  }, [id]);

  return (
    <div className="bg-content">
      <BreadCrumb />
      <div className="info-box">
        <div className="info-title">{title}</div>
        {subhead ? <div className="info-subhead">{subhead}</div> : null}
        <div className="info-mess">
          <div className="info-time">
            <span>{publishTime}</span>
            <span className="info-issuer">{issuer}</span>
          </div>
          <div className="info-reading">
            <span className="iconfont icon-a-studentID"></span>
            {readingAmount}
          </div>
        </div>
        <div className="info-summary">
          <img
            src={require("./images/biaodian.png")}
            alt="biaodian"
            className="biaodian"
          />
          <div>{summary}</div>
        </div>
        <div
          className="iframe-box"
          dangerouslySetInnerHTML={{ __html: content }}
        >
          {/* <iframe
          src={infoUrl}
          width={1200}
          className="inframcontent"
          id="info_iframe"
        /> */}
        </div>
      </div>
    </div>
  );
}
export default InfoDetail;
