import React, { useState, useEffect } from "react"
import { useNavigate } from "react-router-dom"
import { Empty } from "antd"
import "../css/MyAgreement.scss"
import { Tabs, Pagination } from "antd"
import { fetchAgreementList } from "@/apis/order"
const MyOrder: React.FC = () => {
  const [tabs] = useState([
    { name: "未签署", key: "0" },
    { name: "已签署", key: "1" },
  ])
  const [activeTab, setActiveTab] = useState(0)
  const [pageIndex, setPageIndex] = useState(1)
  const [pageRow] = useState(5)
  const [pageTotal, setPageTotal] = useState(0)
  const [tabData, setTabData] = useState([])
  const navigate = useNavigate()
  const changeTab = (id: any) => {
    setPageIndex(1)
    setActiveTab(id)
  }
  const onChange = (index: number) => {
    setPageIndex(index)
  }
  useEffect(() => {
    const fetchTabs = async () => {
      // const res = await fetchOrderList(activeTab, pageIndex, pageRow);
      const res = await fetchAgreementList(activeTab)
      setTabData(res)
      // setTabData([])
      if (pageTotal !== res.page?.total) {
        setPageTotal(res.page?.total)
      }
    }
    fetchTabs()
  }, [activeTab, pageIndex, pageRow, pageTotal])

  // 点击去签署协议
  function toTheAgreement(status: number, id: String, pdfurl: String) {
    console.log("去签署协议")
    console.log("status", status)
    if (status === 0) {
      navigate(
        `/Agreement/?orderContractId=${id}&pdfurl=${pdfurl}&redirectUrl=personalCenter?topNavIndex=3`
      )
    } else {
      navigate(`/Agreement/?pdfurl=${pdfurl}`)
    }
  }

  return (
    <div className='agreement-my-content'>
      <div className='agreement-tab-list'>
        <Tabs
          tabPosition={"top"}
          style={{ height: 220 }}
          indicatorSize={(origin) => origin - 16}
          items={tabs.map((item: any) => {
            return {
              label: `${item.name}`,
              key: `${item.key}`,
            }
          })}
          onChange={changeTab}
        />
      </div>
      <div className='agreement-content'>
        {tabData && tabData.length > 0 ? (
          tabData.map((data: any, index: number) => {
            return (
              <div
                className='agreement-item'
                key={index}
                onClick={() => {
                  toTheAgreement(
                    data.status,
                    data.id,
                    data.exclusiveContractUrl
                  )
                }}
              >
                <div className='iconfont icon-myagreement agreement-fl'></div>
                <div className='agreement-itemTitle'>
                  {data.exclusiveContractName}
                </div>
                <div className='iconfont icon-symbol_right agreement-fr'></div>
              </div>
            )
          })
        ) : (
          <Empty
            style={{ marginTop: 100 }}
            image={
              <img src={require("../images/agreement-empty.png")} alt=''></img>
            }
            imageStyle={{ height: 172 }}
            description={<span style={{ color: "#9FA1A4" }}>暂无协议</span>}
          ></Empty>
        )}
        {tabData && tabData.length > 0 ? (
          <Pagination
            onChange={onChange}
            total={pageTotal}
            showSizeChanger={false}
            pageSize={pageRow}
            current={pageIndex}
          />
        ) : null}
      </div>
    </div>
  )
}

export default MyOrder
