import { request } from "@/utils/request"

//实名认证签署协议
export const postSignAgreement = (data: any) => {
  return request({
    url: `/trade//order/contract/authentication`,
    method: "POST",
    data,
  })
}

//身份证信息识别
export const idCardRecognize = (data: FormData) => {
  return request({
    url: `/upload/idCard/recognize`,
    method: "POST",
    data,
    headers: {
      "Content-Type": "multipart/form-data",
    },
  })
}
