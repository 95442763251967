import { request } from "@/utils/request";
// 获取项目分类展开结构
export function fetchClassifyExpansion(params: any) {
  return request({
    url: `/base/construct/tag/scene`,
    method: "GET",
    params,
  });
}

// 获取广告位信息
export function getHomeAdvert() {
  return request({
    url: `/base/inner/app/channel/get/advert`,
    method: "GET",
  });
}
