import { action, observable, makeObservable } from "mobx";

/**
 * 存储-渠道信息
 */
export class ChannelStore {
  @observable
  public channelInfo: any = {};

  constructor() {
    makeObservable(this);
  }
  // 设置渠道信息
  @action
  public setChannelInfo = (ChannelInfo: any) => {
    this.channelInfo = ChannelInfo;
  };

  /**获取渠道信息 */
  @action
  public getChannelInfo = () => {
    return this.channelInfo;
  };
}
export default new ChannelStore();
