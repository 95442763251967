import React, { useEffect, useState } from "react";
import Router from "./router/index";
import channelStore from "@/store/channel";
import { getChannelId, getTenantInfo } from "./apis/settlement";

function App() {
  const [logoUrl, setLogoUrl] = useState(""); // logo
  const [appName, setAppName] = useState("");

  const fetchLogo = async () => {
    try {
      const response = await getChannelId();
      console.log("APP组件调用的response", response);
      if (response && response.appLogo && response.companyName) {
        setLogoUrl(response.appLogo);
        setAppName(response.companyName);
      }
    } catch (error) {
      console.error("调用失败", error);
    }
  };

  // 获取渠道信息
  const getChannelInfo = async () => {
    const response = await getTenantInfo();
    channelStore.setChannelInfo(response);
  };

  useEffect(() => {
    fetchLogo();
    getChannelInfo();
  }, []);

  // 监听 appName 和 logoUrl 的变化来更新浏览器的标题和图标
  useEffect(() => {
    document.title = appName; // 更新页面标题

    // 更新 favicon
    let link = document.querySelector(
      "link[rel*='icon']"
    ) as HTMLLinkElement | null;
    if (!link) {
      link = document.createElement("link");
      link.rel = "icon";
      document.getElementsByTagName("head")[0].appendChild(link);
    }
    link.type = "image/x-icon";
    link.href = logoUrl;
  }, [appName, logoUrl]);

  return <Router />;
}

export default App;
