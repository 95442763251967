/* eslint-disable  */
import React, { useState, useEffect } from "react";
// import { useParams } from "react-router-dom";
import "../css/activity-banner.scss";

function ActivityBanner(props: any) {
  const [remain, setRemain] = useState(0);
  const [timeData, setTimeData] = useState({
    day: 0,
    hour: 0,
    min: 0,
    second: 0,
  });
  const { info, toBuy } = props;
  useEffect(() => {
    setRemain(info?.endTime || 0);
  }, [info]);
  useEffect(() => {
    if (remain) {
      reset();
    }
  }, [remain]);
  // 时间补全 0
  const checkTime = (i: any) => {
    if (i < 10) {
      i = "0" + i;
    }
    return i;
  };
  const updateTimeData = (t: any) => {
    let day = Math.floor(t / 1000 / 60 / 60 / 24);
    let hour = Math.floor((t / 1000 / 60 / 60) % 24);
    let minute = Math.floor((t / 1000 / 60) % 60);
    let second = Math.floor((t / 1000) % 60);
    setTimeData({
      day: checkTime(day),
      hour: checkTime(hour),
      min: checkTime(minute),
      second: checkTime(second),
    });
  };

  let timer: any = null;
  // 开启倒计时
  const startTimer = () => {
    if (timer) {
      clearInterval(timer);
    }
    if (remain < 1000) {
      return;
    }
    let timeDown = remain;
    timer = setInterval(() => {
      timeDown -= 1000;
      updateTimeData(timeDown);
      if (timeDown < 1000) {
        clearInterval(timer);
      }
    }, 1000);
  };

  // 重置倒计时
  const reset = () => {
    updateTimeData(remain);
    start();
  };
  // 开始倒计时
  const start = () => {
    if (timer) {
      return;
    }
    startTimer();
  };
  return (
    <div className="banner-box" onClick={() => toBuy()}>
      <img
        src={
          info?.displayRules == 1
            ? info.pcImgUrl
            : info.type == 7
            ? require("../imgs/addLevelBanner.png")
            : require("../imgs/replaceBanner.png")
        }
        className="activity-banner-box"
      ></img>
      {info?.displayRules == 0 ? (
        <div className="banner-content-box">
          <div
            className={
              info.type == 7
                ? "banner-content-title"
                : "banner-content-title-replace"
            }
          >
            满<div className="banner-content-title-num">{info.threshold}元</div>
            {info.type == 7 ? " 享优惠升级购" : "享受优惠换购"}
          </div>
          <div
            className={
              info.type == 7
                ? "banner-content-time"
                : "banner-content-time-replace"
            }
          >
            距结束
            <div className="banner-time-box banner-time-day">
              {timeData.day}
            </div>
            天
            <div className="banner-time-box banner-time-hour">
              {timeData.hour}
            </div>
            :
            <div className="banner-time-box banner-time-min">
              {timeData.min}
            </div>
            :
            <div className="banner-time-box banner-time-second">
              {timeData.second}
            </div>
          </div>
        </div>
      ) : null}
    </div>
  );
}

export default ActivityBanner;
