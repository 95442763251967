import { Carousel } from "antd";
import { CarouselRef } from "antd/es/carousel";
import { useNavigate } from "react-router-dom";
import { useState, useRef } from "react";
import "./css/advertisement.scss";
interface Iprops {
  adImg: []; // 广告图
  width: string;
  autoCarousel: Boolean;
}
function Advertisement(props: Iprops) {
  const navigate = useNavigate();
  const { adImg, width, autoCarousel } = props;
  const advertisementStyle = {
    width: width,
    borderRadius: width === "1200px" ? "12px" : "0 12px 12px 0",
  };
  const advertisementStyle1 = {
    height: width === "1200px" ? "384px" : "352px",
    width: width === "910px" ? "878px" : width === "646px" ? "614px" : width,
    borderRadius: "12px",
    margin: width === "1200px" ? "0px" : "16px",
  };
  // 点击图片实现跳转
  const handleClick = (item: any) => {
    console.log("link--------", item);
    switch (item.href[0]) {
      case "功能链接":
        gocenter(item.href[1]);
        break;
      case "微页面":
        navigate(`/webPage/${item.href[1]}`);
        break;
      case "超链接":
        goByLink(item.hyperLink);
        break;
    }
  };
  // 功能链接跳转函数
  const gocenter = (href: string) => {
    switch (href) {
      case "我的课程":
        navigate("/personalCenter?topNavIndex=0");
        break;
      case "课程激活":
        navigate("/personalCenter?topNavIndex=2");
        break;
      case "我的订单":
        navigate("/personalCenter?topNavIndex=3");
        break;
      case "我的协议":
        navigate("/personalCenter?topNavIndex=4");
        break;
    }
  };
  //超链接跳转函数
  const goByLink = (hyperLink: string) => {
    window.location.href = hyperLink;
  };
  const [activeIndex, setActiveIndex] = useState(0);
  return (
    <div className="advertisement" style={advertisementStyle}>
      {adImg.length > 0 ? (
        <Carousel
          autoplay={autoCarousel ? true : false}
          dots
          afterChange={(index) => setActiveIndex(index)}
        >
          {adImg.map((item: any, index: any) => {
            return (
              <div
                className="advertisementImg"
                style={{ width: width }}
                key={index}
              >
                <div onClick={() => handleClick(item)}>
                  <img src={item.url} alt="" style={advertisementStyle1} />
                </div>
              </div>
            );
          })}
        </Carousel>
      ) : null}
    </div>
  );
}
export default Advertisement;
