import React, { useEffect } from "react";
import { HashRouter, Route, Routes } from "react-router-dom";
import Home from "@/pages/Home";
import CourseDetail from "@/pages/CourseDetail";
import AllInformation from "@/pages/AllInformation";
import PersonalCenter from "@/pages/PersonalCenter";
import InfoDetail from "@/pages/InfoDetail";
import SingPage from "@/pages/SingPage";
import AllCourse from "@/pages/AllCourse/allCourse";
import AllCourseCoupon from "@/pages/AllCourseCoupon";
import MainLayout from "@/pages/Layout/index";
import VideoPlay from "@/pages/VideoPlay/videoPlay";
import LiveReplay from "@/pages/LiveReplay/liveReplay";
import OrderDetail from "@/pages/OrderDetail";
import Agreement from "@/pages/Agreement";
import SignAgreement from "@/pages/Agreement/comps/SignAgreement";
import OrderSettlement from "@/pages/OrderSettlement";
import PaymentStatus from "@/pages/PaymentStatus"; // 订单支付成功
import SignFinish from "../pages/Agreement/comps/SignFinish";
import Protocol from "@/pages/Protocol";
import LoginModal from "@/components/LoginModal"; //登陆弹窗
import AllCoupon from "@/pages/AllCoupon";
import AllLive from "@/pages/AllLive";
import AllStudy from "@/pages/AllStudyCourse";
import Float from "@/pages/Float"; // 在线客服悬浮条
import WebPage from "@/pages/WebPage"; // 微页面
import GoodsShowMore from "@/pages/GoodsShowMore"; // 商品组件-查看更多
import ActivityList from "@/pages/ActivityList";

function Router() {
  return (
    <HashRouter>
      <LoginModal />
      <Routes>
        {/* 商品展示组件，查看更多 */}
        <Route
          path="/goodsShowMore"
          element={
            <MainLayout>
              <GoodsShowMore />
            </MainLayout>
          }
        />
        <Route path="/videoPlay" element={<VideoPlay />} />
        <Route path="/liveReplay" element={<LiveReplay />} />
        <Route
          path="/agreement"
          element={
            <MainLayout>
              <Agreement />
              <Float />
            </MainLayout>
          }
        />
        <Route
          path="/signAgreement"
          element={
            <MainLayout>
              <SignAgreement />
              <Float />
            </MainLayout>
          }
        />
        <Route
          path="/signFinish"
          element={
            <MainLayout>
              <SignFinish />
              <Float />
            </MainLayout>
          }
        />
        <Route
          path="/courseDetail"
          element={
            <MainLayout>
              <CourseDetail />
              <Float />
            </MainLayout>
          }
        />
        <Route
          path="/allInformation"
          element={
            <MainLayout>
              <AllInformation />
              <Float />
            </MainLayout>
          }
        />
        <Route
          path="/infoDetail"
          element={
            <MainLayout styles={{ backgroundColor: "#F4F6F8" }}>
              <InfoDetail />
              <Float />
            </MainLayout>
          }
        />
        <Route
          path="/singPage"
          element={
            <MainLayout styles={{ backgroundColor: "#F4F6F8" }}>
              <SingPage />
              <Float />
            </MainLayout>
          }
        />
        <Route
          path="/personalCenter"
          element={
            <MainLayout styles={{ backgroundColor: "#F4F6F8" }}>
              <PersonalCenter />
              <Float />
            </MainLayout>
          }
        />
        <Route
          path="/allCourse/:type/:id"
          element={
            <MainLayout>
              <AllCourse />
              <Float />
            </MainLayout>
          }
        />
        <Route
          path="/allCourseCoupon/:type/:id"
          element={
            <MainLayout>
              <AllCourseCoupon />
              <Float />
            </MainLayout>
          }
        />
        <Route
          path="/allLive"
          element={
            <MainLayout>
              <AllLive />
              <Float />
            </MainLayout>
          }
        />
        <Route
          path="/allCoupon"
          element={
            <MainLayout>
              <AllCoupon />
              <Float />
            </MainLayout>
          }
        />
        <Route
          path="/allStudy"
          element={
            <MainLayout>
              <AllStudy />
              <Float />
            </MainLayout>
          }
        />
        <Route
          path="/orderDetail/:id/:groupId?"
          element={
            <MainLayout styles={{ backgroundColor: "#F4F6F8" }}>
              <OrderDetail />
              <Float />
            </MainLayout>
          }
        />
        <Route
          path="/orderSettlement"
          element={
            <MainLayout styles={{ backgroundColor: "#F4F6F8" }}>
              <OrderSettlement />
              <Float />
            </MainLayout>
          }
        />
        {/* 订单支付状态页 */}
        <Route
          path="/activityList"
          element={
            <MainLayout styles={{ backgroundColor: "#F4F6F8" }}>
              <ActivityList />
              <Float />
            </MainLayout>
          }
        />
        {/* 活动列表页 */}
        <Route
          path="/paymentStatus/:orderId"
          element={
            <MainLayout styles={{ backgroundColor: "#F4F6F8" }}>
              <PaymentStatus />
              <Float />
            </MainLayout>
          }
        />
        {/* 协议 */}
        <Route
          path="/protocol/:channelId/:protocolName"
          element={
            <MainLayout styles={{ backgroundColor: "#F4F6F8" }}>
              <Protocol />
              <Float />
            </MainLayout>
          }
        />
        {/* 微页面 */}
        <Route
          path="/webPage/:id"
          element={
            <>
              <WebPage />
            </>
          }
        />
        {/* 首页 */}
        <Route
          path="*"
          element={
            <>
              <Home />
              <Float />
            </>
          }
        />
      </Routes>
    </HashRouter>
  );
}

export default Router;
