/* eslint-disable  */
import React, {
  useState,
  useEffect,
  forwardRef,
  useImperativeHandle,
} from "react";
// import { useParams } from "react-router-dom";
import { Col, Row } from "antd";
import OrderDeatailItem from "./OrderDeatailItem";
import ExModal from "./ExplainModal";
import "../css/index.scss";

const Counp = forwardRef((props: any, ref: any) => {
  const { couponList, callback } = props; //课程商品状态 0-待付款 1-已付款 2-已关闭 3-退款中 4-已退款 5-拼团中
  const [curCoupon, setCurCoupon] = useState<any>({}); // 选中的优惠券
  const [exModalOpen, setExModalOpen] = useState(false); // 说明弹窗的显示与隐藏
  const [curExplain, setCurExplain] = useState<string>("");
  const checkCoupon = (val: any) => {
    if (curCoupon && curCoupon == val) {
      setCurCoupon({});
      callback && callback({})
    } else {
      callback && callback(val)
      setCurCoupon(val);
    }
  };
  const clearSelect = () => {
    setCurCoupon({});
  };
  useImperativeHandle(ref, () => ({
    clearSelect,
  }));
  // useEffect(() => {
  //   callback && callback(curCoupon);
  // }, [curCoupon]);
  const showExModal = (e: any, val: any) => {
    e.stopPropagation();
    setCurExplain(val);
    setExModalOpen(true);
  };
  return (
    <div className="counp-box">
      <div className="box-title">优惠券</div>
      <div className="box-content">
        {couponList.length > 0 &&
          couponList.map((item: any, index: number) => {
            return (
              <div key={index}>
                {item.usable ? (
                  <div
                    key={index}
                    className={
                      (index + 1) % 4 == 0 && item == curCoupon
                        ? "counp-one active-counp"
                        : item == curCoupon
                        ? "counp-one counp-one-right active-counp "
                        : (index + 1) % 4 == 0
                        ? "counp-one"
                        : "counp-one counp-one-right"
                    }
                    onClick={() => checkCoupon(item)}
                  >
                    <div className="counp-top">
                      <div className="coupon-content">
                        <div className="coupon-discount">
                          {item.type == 0 ? (
                            <div className="discount-unit">￥</div>
                          ) : null}
                          <div className="discount-val"> {item.discount}</div>
                          {item.type == 1 ? (
                            <div className="discount-unit-after">折</div>
                          ) : null}
                        </div>
                        <div className="coupon-requirement">{item.content}</div>
                      </div>
                      <div className="coupon-useTime">{item.useTime}</div>
                    </div>
                    <div
                      className={
                        item.usable ? "counp-bottom" : "counp-bottom-dis"
                      }
                    >
                      <div className="counp-name">{item.name}</div>
                      <div className="counp-ex">
                        使用说明
                        <div
                          className="iconfont  icon-wenhao ex-icon"
                          onClick={(e) => showExModal(e, item.instructions)}
                        ></div>
                      </div>
                    </div>
                    {curCoupon == item ? (
                      <div className="check-coupn-icon iconfont icon-exclude "></div>
                    ) : null}
                  </div>
                ) : (
                  <div
                    key={index}
                    className={
                      (index + 1) % 4 == 0
                        ? "counp-one-dis"
                        : "counp-one-dis counp-one-right"
                    }
                  >
                    <div className="counp-top-dis">
                      <div className="coupon-content">
                        <div className="coupon-discount">
                          {item.type == 0 ? (
                            <div className="discount-unit">￥</div>
                          ) : null}
                          <div className="discount-val"> {item.discount}</div>
                          {item.type == 1 ? (
                            <div className="discount-unit-after">折</div>
                          ) : null}
                        </div>
                        <div className="coupon-requirement">{item.content}</div>
                      </div>
                      <div className="coupon-useTime">{item.useTime}</div>
                    </div>
                    <div
                      className={
                        item.usable ? "counp-bottom" : "counp-bottom-dis"
                      }
                    >
                      <div className="counp-name">{item.name}</div>
                      <div className="counp-ex">
                        使用说明
                        <div
                          className="iconfont  icon-wenhao ex-icon"
                          onClick={(e) => showExModal(e, item.instructions)}
                        ></div>
                      </div>
                    </div>
                    <div className="dis-reason-box">
                      <div className="reason-title">不可用原因:</div>
                      <div className="reason-val"> {item.reason}</div>
                    </div>
                  </div>
                )}
              </div>
              // <div
              //   key={index}
              //   className={
              //     item.usable && (index + 1) % 4 == 0
              //       ? "counp-one"
              //       : item.usable
              //       ? "counp-one counp-one-right"
              //       : (index + 1) % 4 == 0
              //       ? "counp-one-dis"
              //       : "counp-one-dis counp-one-right"
              //   }
              // >
              //   <div className={item.usable ? "counp-top" : "counp-top-dis"}>
              //     <div className="coupon-content">
              //       <div className="coupon-discount">
              //         {item.type == 0 ? (
              //           <div className="discount-unit">￥</div>
              //         ) : null}
              //         <div className="discount-val"> {item.discount}</div>
              //         {item.type == 1 ? (
              //           <div className="discount-unit-after">折</div>
              //         ) : null}
              //       </div>
              //       <div className="coupon-requirement">{item.content}</div>
              //     </div>
              //     <div className="coupon-useTime">{item.useTime}</div>
              //   </div>
              //   <div
              //     className={item.usable ? "counp-bottom" : "counp-bottom-dis"}
              //   >
              //     <div className="counp-name">{item.name}</div>
              //     <div className="counp-ex">使用说明</div>
              //   </div>
              //   {item.usable == 0 ? (
              //     <div className="dis-reason-box">
              //       <div className="reason-title">不可用原因:</div>
              //       <div className="reason-val"> {item.reason}</div>
              //     </div>
              //   ) : null}
              // </div>
            );
          })}
      </div>
      <ExModal
        isShow={exModalOpen}
        onClose={() => {
          setExModalOpen(false);
        }}
        text={curExplain}
      />
    </div>
  );
});

export default Counp;
