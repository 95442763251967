/*
 * @Author: liuweixing
 * @Date: 2024-05-13 10:03:31
 * @Description: 一行两个优惠卷
 */
import { useState } from "react";
import ExModal from "@/pages/OrderSettlement/comps/ExplainModal";
import "../css/MyCoupon.scss";
interface Iprops {
  data: any;
  index: number;
  handlerClick: any;
  couponStatus: any;
}
const CouponItem = (props: Iprops) => {
  const { data, index, handlerClick, couponStatus } = props;
  const [exModalOpen, setExModalOpen] = useState(false); // 说明弹窗的显示与隐藏
  const [curExplain, setCurExplain] = useState<string>("使用说明");
  const showModal = (text: string) => {
    setCurExplain(text);
    setExModalOpen(true);
  };
  return (
    <div
      className={
        index % 2 == 0 ? "coupon-item-person left-index" : "coupon-item-person"
      }
    >
      <div
        className={
          couponStatus == 2
            ? "card-box-coupon bg-use-small"
            : couponStatus == 4
            ? "card-box-coupon bg-lose-small"
            : couponStatus == 3
            ? "card-box-coupon bg-time-small"
            : "card-box-coupon bg-active-small"
        }
        key={index}
      >
        <div className="left-card-coupon">
          {data.type == 0 ? (
            <div className="left-type-0">
              <div className="coupon-price-box">
                <div className="coupon-price-box-icon">￥</div>
                <div className="coupon-price-box-num">{data.discount}</div>
              </div>
              <div className="type-0-text">{data.content}</div>
            </div>
          ) : (
            <div className="left-type-1">
              <div className="coupon-discount-box">
                <div className="coupon-discount-box-num">{data.discount}</div>
                <div className="coupon-discount-box-icon">折</div>
              </div>
              <div className="type-1-text">{data.content}</div>
            </div>
          )}
          {couponStatus != 1 ? null : (
            <div
              onClick={() =>
                handlerClick(data.id, data?.userStatus, data?.usableRange)
              }
              className="coupon-btn"
            >
              去使用
            </div>
          )}
        </div>
        <div className="right-card-coupon">
          <div className="coupon-name">{data?.name}</div>
          <div
            className={
              couponStatus != 1
                ? "coupon-time-white ellipsis-two"
                : "coupon-time ellipsis-two"
            }
          >
            {data?.useTime}
          </div>
          <div className="coupon-explain-box">
            <div
              className={
                couponStatus != 1
                  ? "coupon-explain-text coupon-explain-text-white"
                  : "coupon-explain-text"
              }
            >
              使用说明
            </div>
            <div
              className={
                couponStatus != 1
                  ? "coupon-explain-icon-white"
                  : "coupon-explain-icon"
              }
              onClick={() => showModal(data?.instructions)}
            ></div>
          </div>
        </div>
      </div>
      <ExModal
        isShow={exModalOpen}
        onClose={() => {
          setExModalOpen(false);
        }}
        text={curExplain}
      />
    </div>
  );
};
export default CouponItem;
