/* eslint-disable  */
import React, { useState, useEffect } from "react";
import { Col, Row } from "antd";
import OrderDeatailItem from "./OrderDeatailItem";
import "../css/gift.scss";
import { DownOutlined, UpOutlined } from "@ant-design/icons";
import ExModal from "@/pages/OrderSettlement/comps/ExplainModal";
import { useNavigate } from "react-router-dom";
function Gift(props: any) {
  const { giftList } = props;
  const [giftInfo, setGiftInfo] = useState<any>({});
  const [showAll, setShowAll] = useState(false);
  const [exModalOpen, setExModalOpen] = useState(false); // 说明弹窗的显示与隐藏
  const [curExplain, setCurExplain] = useState<string>("使用说明");
  const navigate = useNavigate();
  const showModal = (text: string) => {
    setCurExplain(text);
    setExModalOpen(true);
  };
  // 获取赠品信息
  useEffect(() => {
    let obj = {
      goods: giftList.filter((item: any) => {
        return item.giftType == 3;
      }),
      exchangeList: giftList.filter((item: any) => {
        return item.giftType == 2;
      }),
      couponList: giftList.filter((item: any) => {
        return item.giftType == 1;
      }),
    };
    setGiftInfo(obj);
  }, [giftList]);
  // 渲染赠品中的商品
  const renderGiftGoods = (data: any) => {
    return (
      <div>
        <Row className="pay-gift-header">
          <Col span={12}>商品</Col>
          <Col span={4}>原价</Col>
          <Col span={4}>现价</Col>
          <Col span={4}>操作</Col>
        </Row>
        {data.map((item: any, index: number) => {
          return (!showAll && index < 1) || showAll ? (
            <OrderDeatailItem
              commodityInfo={item}
              key={index}
            ></OrderDeatailItem>
          ) : null;
        })}
      </div>
    );
  };
  // 渲染赠品中的兑换码 混合模式与单一模式
  const renderGiftExchange = (data: any) => {
    return giftInfo?.goods?.length > 0 || giftInfo?.couponList?.length > 0 ? (
      <div>
        {showAll ? (
          <div className="gift-exchange-all">
            <div className="gift-exchange-all-title">兑换码</div>
            <div className="gift-exchange-all-content">
              {data.map((item: any, index: number) => {
                return renderGiftExchangeOne(item, index);
              })}
            </div>
          </div>
        ) : giftInfo?.goods?.length > 0 ? null : (
          <div className="gift-exchange-all">
            <div className="gift-exchange-all-title">兑换码</div>
            <div className="gift-exchange-all-content">
              {data.map((item: any, index: number) => {
                return index < 2 ? renderGiftExchangeOne(item, index) : null;
              })}
            </div>
          </div>
        )}
      </div>
    ) : (
      <div className="gift-exchange-only">
        <div className="gift-exchange-only-title">兑换码</div>
        <div className="gift-exchange-only-content">
          {data.map((item: any, index: number) => {
            return (!showAll && index < 1) || showAll
              ? renderGiftExchangeOne(item, index)
              : null;
          })}
        </div>
      </div>
    );
  };
  const renderGiftExchangeOne = (item: any, index: number) => {
    return (
      <div className="gift-exchange-one-common" key={index}>
        <div className="gift-exchange-one-common-left">
          <div className="gift-exchange-one-common-val">{item.giftName}</div>
          <div className="gift-exchange-one-common-tip">
            复制兑换码或截图保存兑换码
          </div>
        </div>
        <div className="gift-exchange-one-common-right" onClick={useExchange}>
          去兑换
        </div>
      </div>
    );
  };
  // 渲染赠品中的优惠券
  const renderGiftCoupon = (data: any) => {
    return giftInfo?.goods?.length > 0 || giftInfo?.exchangeList?.length > 0 ? (
      showAll ? (
        <div className="gift-coupon-all">
          <div className="gift-coupon-all-title">优惠券</div>
          <div className="gift-coupon-all-content">
            {data.map((item: any, index: number) => {
              return renderGiftCouponOne(item, index);
            })}
          </div>
        </div>
      ) : null
    ) : (
      <div className="gift-coupon-only">
        <div className="gift-coupon-only-title">优惠券</div>
        <div className="gift-coupon-only-content">
          {data.map((item: any, index: number) => {
            return showAll || (!showAll && index < 1)
              ? renderGiftCouponOne(item, index)
              : null;
          })}
        </div>
      </div>
    );
  };
  const renderGiftCouponOne = (data: any, index: number) => {
    return (
      <div className="gift-coupon-one-common" key={index}>
        <div className="left-card-coupon">
          {data.couponType == 0 ? (
            <div className="left-type-0">
              <div className="coupon-price-box">
                <div className="coupon-price-box-icon">￥</div>
                <div className="coupon-price-box-num">{data.discount}</div>
              </div>
              <div className="type-0-text">
                {data.requirement == 0
                  ? "无门槛"
                  : `满${data.requirement}元可用`}
              </div>
            </div>
          ) : (
            <div className="left-type-1">
              <div className="coupon-discount-box">
                <div className="coupon-discount-box-num">{data.discount}</div>
                <div className="coupon-discount-box-icon">折</div>
              </div>
              <div className="type-1-text">
                {data.requirement == 0
                  ? "无门槛"
                  : `满${data.requirement}元可用`}
              </div>
            </div>
          )}

          <div onClick={() => useCoupon(data)} className="coupon-btn">
            去使用
          </div>
        </div>
        <div className="right-card-coupon">
          <div className="coupon-name">{data?.giftName}</div>
          <div className="coupon-time ellipsis-two">{data?.timeDesc}</div>
          <div className="coupon-explain-box">
            <div className="coupon-explain-text">使用说明</div>
            <div
              className="coupon-explain-icon"
              onClick={() => showModal(data?.instructions)}
            ></div>
          </div>
        </div>
      </div>
    );
  };
  // 兑换码去兑换
  const useExchange = () => {
    navigate("/personalCenter?topNavIndex=2");
  };
  // 优惠券去使用
  const useCoupon = (item: any) => {
    const { usableRange, id } = item;
    if (usableRange == 1) {
      navigate(`/allCourseCoupon/${usableRange}/${id}`);
    } else {
      navigate("/");
    }
  };

  return (
    <div className="order-pay-gift">
      <div className="pay-gift-title">
        <img
          className="gift-title-left"
          src={require("../images/gift-title-left.png")}
        />
        <div className="gift-title-text">恭喜您获得以下赠品</div>
        <img
          className="gift-title-right"
          src={require("../images/gitf-title-right.png")}
        />
      </div>
      <div className="pay-gift-content">
        {giftInfo?.goods?.length > 0 ? renderGiftGoods(giftInfo.goods) : null}
        {giftInfo?.exchangeList?.length > 0
          ? renderGiftExchange(giftInfo.exchangeList)
          : null}
        {giftInfo?.couponList?.length > 0
          ? renderGiftCoupon(giftInfo.couponList)
          : null}
      </div>
      {showAll ? (
        <div className="express-box" onClick={() => setShowAll(false)}>
          收起 <UpOutlined className="retract-btn-icon" />
        </div>
      ) : (
        <div className="express-box" onClick={() => setShowAll(true)}>
          展开全部 <DownOutlined className="express-btn-icon" />
        </div>
      )}
      <ExModal
        isShow={exModalOpen}
        onClose={() => {
          setExModalOpen(false);
        }}
        text={curExplain}
      />
    </div>
  );
}

export default Gift;
