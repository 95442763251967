/*
 * @Author: liuweixing
 * @Date: 2024-05-13 10:03:31
 * @Description: 一行两个优惠券
 */
import { Empty } from "antd";
import { useState, useEffect } from "react";
import ExModal from "@/pages/OrderSettlement/comps/ExplainModal";
import "./css/twoLine.scss";
interface Iprops {
  data: any[];
  showMore: any;
  toShowMore: any;
  showTitle: Boolean;
  title: string;
  showNum: Number;
  hideLossEfficacy: Boolean;
  handlerClick: any;
  customStyle: any;
}
const TwoInLine = (props: Iprops) => {
  const {
    showMore,
    data,
    toShowMore,
    showTitle,
    title,
    showNum,
    hideLossEfficacy,
    handlerClick,
    customStyle,
  } = props;
  const [exModalOpen, setExModalOpen] = useState(false); // 说明弹窗的显示与隐藏
  const [curExplain, setCurExplain] = useState<string>("使用说明");
  const [showData, setShowData] = useState<any[]>([]); // 实际展示数据
  const showModal = (text: string) => {
    setCurExplain(text);
    setExModalOpen(true);
  };
  // 处理展示的数据
  useEffect(() => {
    if (data?.length > 0) {
      let result: any[] = [];
      if (hideLossEfficacy) {
        const temp =
          data.filter((x) => x.viewStatus == 0 || x.viewStatus == 1) || [];
        temp?.length > 0 &&
          temp.map((item, index: any) => {
            if (index < showNum) {
              result.push(item);
            }
          });
      } else {
        data.map((item, index: any) => {
          if (index < showNum) {
            result.push(item);
          }
        });
      }
      setShowData(result);
    } else {
      setShowData([]);
    }
  }, [data, hideLossEfficacy, showNum]);
  return (
    <div className="comp-content-coupon-2" style={customStyle}>
      {showTitle ? (
        <div className="comp-top">
          <div className="comp-title">{title}</div>
          {showMore ? (
            <div className="look-more" onClick={() => toShowMore()}>
              查看更多{" "}
              <span className=" more-icon-01 iconfont icon-symbol_right"></span>
            </div>
          ) : null}
        </div>
      ) : null}
      <div className="scroll-main-coupon-2">
        {showData && showData?.length > 0 ? (
          showData.map((item: any, indexS: number) => {
            return (
              <div
                className={
                  indexS % 2 == 0
                    ? item.viewStatus == 2
                      ? "card-box-coupon-2 bg-use-big right-24"
                      : item.viewStatus == 3
                      ? "card-box-coupon-2 bg-lose-big right-24"
                      : item.viewStatus == 4
                      ? "card-box-coupon-2 bg-time-big right-24"
                      : "card-box-coupon-2 bg-active-big right-24"
                    : item?.viewStatus == 2
                    ? "card-box-coupon-2 bg-use-big"
                    : item.viewStatus == 3
                    ? "card-box-coupon-2 bg-lose-big"
                    : item.viewStatus == 4
                    ? "card-box-coupon-2 bg-time-big"
                    : "card-box-coupon-2 bg-active-big"
                }
              >
                <div className="left-card-coupon-2">
                  {item.type == 0 ? (
                    <div className="left-type-0-2">
                      <div className="coupon-price-box-2">
                        <div className="coupon-price-box-icon-2">￥</div>
                        <div className="coupon-price-box-num-2">
                          {item.discount}
                        </div>
                      </div>
                      <div className="type-0-text-2">{item.content}</div>
                    </div>
                  ) : (
                    <div className="left-type-1-2">
                      <div className="coupon-discount-box-2">
                        <div className="coupon-discount-box-num-2">
                          {item.discount}
                        </div>
                        <div className="coupon-discount-box-icon-2">折</div>
                      </div>
                      <div className="type-1-text-2">{item.content}</div>
                    </div>
                  )}
                  {item?.viewStatus == 2 ||
                  item?.viewStatus == 3 ||
                  item?.viewStatus == 4 ? null : (
                    <div
                      onClick={() =>
                        handlerClick(
                          item.id,
                          item?.viewStatus,
                          item?.usableRange
                        )
                      }
                      className="coupon-btn-2"
                    >
                      {item?.viewStatus == 1 ? "去使用" : "点击领取"}
                    </div>
                  )}
                </div>
                <div className="right-card-coupon-2">
                  <div className="coupon-name-2">{item?.name}</div>
                  <div
                    className={
                      item?.viewStatus == 2 ||
                      item?.viewStatus == 3 ||
                      item?.viewStatus == 4
                        ? "coupon-time-2-white ellipsis-two"
                        : "coupon-time-2 ellipsis-two"
                    }
                  >
                    {item?.useTime}
                  </div>
                  <div className="coupon-explain-box-2">
                    <div
                      className={
                        item?.viewStatus == 2 ||
                        item?.viewStatus == 3 ||
                        item?.viewStatus == 4
                          ? "coupon-explain-text-2-white"
                          : "coupon-explain-text-2"
                      }
                    >
                      使用说明
                    </div>
                    <div
                      className={
                        item?.viewStatus == 2 ||
                        item?.viewStatus == 3 ||
                        item?.viewStatus == 4
                          ? "coupon-explain-icon-2-white"
                          : "coupon-explain-icon-2"
                      }
                      onClick={() => showModal(item?.instructions)}
                    ></div>
                  </div>
                </div>
              </div>
            );
          })
        ) : (
          <Empty
            description="暂无数据"
            style={{
              minHeight: 300,
              width: 1200,
              textAlign: "center",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          />
        )}
      </div>
      <ExModal
        isShow={exModalOpen}
        onClose={() => {
          setExModalOpen(false);
        }}
        text={curExplain}
      />
    </div>
  );
};
export default TwoInLine;
