import { Empty } from "antd";
import "./css/index.scss";
import "./css/listmode.scss";
interface Iprops {
  data: any;
  goShowMore?: any;
  showMore: boolean;
  changeProject?: any;
  goCourseDetail: any;
  checkMoreByPro?: Function;
  title: string;
  showNum: number;
  showByProject?: boolean;
  showTitle: boolean;
  fields: any;
}
const PlainList = (props: Iprops) => {
  const {
    goShowMore,
    showMore,
    changeProject,
    goCourseDetail,
    checkMoreByPro,
    title,
    showNum,
    showByProject,
    showTitle,
    fields,
  } = props;
  const { projectList, currentTab, courseCard } = props.data;
  console.log(courseCard, "333333");

  return (
    <div className="list-content">
      {showTitle ? <div className="top-title">{title}</div> : null}
      <div className={showByProject ? "topnav flexstart" : "topnav flexend"}>
        {showByProject ? (
          <div className="exam-list fl">
            <ul>
              {projectList &&
                projectList?.length > 0 &&
                projectList.map((item: any, index: Number) => {
                  return (
                    <li
                      onClick={() => changeProject(item, index)}
                      key={item.projectId}
                      data-classNameify="free"
                      data-sign="gongcheng"
                      className={
                        currentTab === index
                          ? "nav-item nav-active"
                          : "nav-item"
                      }
                    >
                      {item.projectName}
                    </li>
                  );
                })}
            </ul>
          </div>
        ) : null}
        {showMore && showTitle ? (
          <div className="more-button" onClick={() => goShowMore()} style={{}}>
            查看更多{" "}
            <span className="  iconfont icon-symbol_right"></span>
          </div>
        ) : null}
      </div>
      <div className="main-content">
        {courseCard && courseCard.length > 0 ? (
          courseCard.map((item: any, index: number) => {
            {
              return showNum > index ? (
                <div
                  className="goods-content"
                  onClick={() => goCourseDetail(item)}
                  style={{ marginBottom: "32px" }}
                >
                  <div className="goods-img">
                    <img
                      src={item.thumbnail}
                      alt=""
                      className="goods-thumbnail"
                    />
                    {fields.includes("peoples") ? (
                      <div className="sellingnumber">
                        {item.salesVolume ? item.salesVolume : 0}人已关注
                      </div>
                    ) : null}
                  </div>
                  <div className="sellpoint-box">
                    {fields.includes("name") ? (
                      <div className="goods-name">{item.name}</div>
                    ) : null}
                    {fields.includes("sellPoint") ? (
                      <div className="goods-sellpoint">{item.sellingPoint}</div>
                    ) : null}
                  </div>
                  <div className="teacher-box">
                    {fields.includes("teachers") ? (
                      <div className="item-teacherList">
                        {item.teachers
                          ? item.teachers.map((info: any, index: number) => {
                              return index < 3 ? (
                                <div className="teacher-item">
                                  <img src={info.photo} alt="" />
                                  <p className="teacher-name">{info.name}</p>
                                </div>
                              ) : null;
                            })
                          : null}
                      </div>
                    ) : null}
                    {fields.includes("price") ? (
                      <div className="item-pricebox">
                        <p className="item-lineprice">
                          <span className="lineprice-icon">￥</span>
                          {item.linePrice}起
                        </p>
                        <p className="item-realPrice">
                          <span className="price-icon">￥</span>
                          <span className="price-number">{item.price}</span>起
                        </p>
                      </div>
                    ) : null}
                  </div>
                </div>
              ) : null;
            }
          })
        ) : (
          <Empty
            description="暂无数据"
            style={{
              minHeight: 300,
              textAlign: "center",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          />
        )}
      </div>
    </div>
  );
};
export default PlainList;
