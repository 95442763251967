// 格式化店铺装修数据-工具

// 分类导航
export const formatCategoryNavigation = (data: any) => {
  interface PaddingStyles {
    paddingTop?: string;
    paddingRight?: string;
    paddingBottom?: string;
    paddingLeft?: string;
  }
  const paddingStyle: PaddingStyles = {};
  const k_v: { [key: string]: keyof PaddingStyles } = {
    "padding-top": "paddingTop",
    "padding-right": "paddingRight",
    "padding-bottom": "paddingBottom",
    "padding-left": "paddingLeft",
  };
  data.style.map((item: any) => {
    const key = k_v[item.attr];
    if (key) {
      paddingStyle[key] = item.val + "px";
    }
  });

  const { val: layout } = data?.style.find(
    (item: any) => item.attr === "layout"
  );
  const { val: classifyStyle } = data?.style.find(
    (item: any) => item.attr === "classifyStyle"
  );
  const { val: addBanner } = data?.content.find(
    (item: any) => item.attr === "addBanner"
  );
  const { val: autoCarousel } = data?.content.find(
    (item: any) => item.attr === "autoCarousel"
  );
  const { val: color } = data?.style.find((item: any) => item.attr === "color");

  const { val: backgroundColor } = data?.style.find(
    (item: any) => item.attr === "background-color"
  );
  const { val: backgroundImage } = data?.style.find(
    (item: any) => item.attr === "background-image"
  );

  return Object.assign({
    layout,
    classifyStyle,
    autoCarousel,
    bannerList: addBanner,
    color,
    backgroundColor,
    backgroundImage: `url(${backgroundImage})`,
    paddingStyle,
  });
};

// 广告位
export const formatAdvertisement = (data: any) => {
  interface PaddingStyles {
    paddingTop?: string;
    paddingRight?: string;
    paddingBottom?: string;
    paddingLeft?: string;
  }
  const paddingStyle: PaddingStyles = {};
  const k_v: { [key: string]: keyof PaddingStyles } = {
    "padding-top": "paddingTop",
    "padding-right": "paddingRight",
    "padding-bottom": "paddingBottom",
    "padding-left": "paddingLeft",
  };
  data.style.map((item: any) => {
    const key = k_v[item.attr];
    if (key) {
      paddingStyle[key] = item.val + "px";
    }
  });
  const { val: backgroundColor } = data?.style.find(
    (item: any) => item.attr === "background-color"
  );
  const { config: advertisementArr } = data?.content.find(
    (item: any) => item.type === "add-advertisement"
  );
  const { val: isShow } = data?.content.find(
    (item: any) => item.attr === "showSeacher"
  );
  return Object.assign({
    backgroundColor,
    paddingStyle,
    advertisementArr,
    isShow,
  });
};

// 富文本
export const formatEditor = (data: any) => {
  interface PaddingStyles {
    paddingTop?: string;
    paddingRight?: string;
    paddingBottom?: string;
    paddingLeft?: string;
  }
  const paddingStyle: PaddingStyles = {};
  const k_v: { [key: string]: keyof PaddingStyles } = {
    "padding-top": "paddingTop",
    "padding-right": "paddingRight",
    "padding-bottom": "paddingBottom",
    "padding-left": "paddingLeft",
  };
  data.style.map((item: any) => {
    const key = k_v[item.attr];
    if (key) {
      paddingStyle[key] = item.val + "px";
    }
  });
  const { val: backgroundColor } = data?.style.find(
    (item: any) => item.attr === "background-color"
  );
  const { val: backgroundImage } = data?.style.find(
    (item: any) => item.attr === "background-image"
  );
  const { val: editor } = data?.content.find(
    (item: any) => item.attr === "editor"
  );
  const { val: color } = data?.style.find((item: any) => item.attr === "color");

  return Object.assign({
    backgroundColor,
    backgroundImage,
    paddingStyle,
    editor,
    color,
  });
};
// 页眉
export const formatHeader = (data: any) => {
  interface PaddingStyles {
    paddingTop?: string;
    paddingRight?: string;
    paddingBottom?: string;
    paddingLeft?: string;
  }
  const paddingStyle: PaddingStyles = {};
  const k_v: { [key: string]: keyof PaddingStyles } = {
    "padding-top": "paddingTop",
    "padding-right": "paddingRight",
    "padding-bottom": "paddingBottom",
    "padding-left": "paddingLeft",
  };
  data.style.map((item: any) => {
    const key = k_v[item.attr];
    if (key) {
      paddingStyle[key] = item.val + "px";
    }
  });
  const { val: backgroundColor } = data?.style.find(
    (item: any) => item.attr === "background-color"
  );
  const { val: addNav } = data?.content.find(
    (item: any) => item.attr === "addNav"
  );
  const { val: isShow } = data?.content.find(
    (item: any) => item.attr === "showSeacher"
  );
  const { val: color } = data?.style.find((item: any) => item.attr === "color");
  return Object.assign({
    backgroundColor,
    addNav,
    color,
    paddingStyle,
    isShow,
  });
};

// 页脚
export const formatFooter = (data: any) => {
  interface PaddingStyles {
    paddingTop?: string;
    paddingRight?: string;
    paddingBottom?: string;
    paddingLeft?: string;
  }
  const paddingStyle: PaddingStyles = {};
  const k_v: { [key: string]: keyof PaddingStyles } = {
    "padding-top": "paddingTop",
    "padding-right": "paddingRight",
    "padding-bottom": "paddingBottom",
    "padding-left": "paddingLeft",
  };
  data.style.map((item: any) => {
    const key = k_v[item.attr];
    if (key) {
      paddingStyle[key] = item.val + "px";
    }
  });

  const { val: backgroundColor } = data?.style.find(
    (item: any) => item.attr === "background-color"
  );
  const { val: color } = data?.style.find((item: any) => item.attr === "color");
  const { val: copyrightName } = data?.content.find(
    (item: any) => item.attr === "copyright"
  );

  return Object.assign({
    backgroundColor,
    paddingStyle,
    copyrightName,
    color,
  });
};

// 优惠券
export const formatCouponData = (data: any) => {
  const showTitleObj = data?.content?.find((x: any) => x.attr == "showTitle");
  const showTitle = showTitleObj?.val;
  const titleObj = data?.content?.find((x: any) => x.attr == "title");
  const title = titleObj?.val;
  const showMoreObj = data?.content?.find((x: any) => x.attr == "showMore");
  const showMore = showMoreObj?.val;
  const showNumObj = data?.content?.find((x: any) => x.attr == "showNum");
  const showNum = showNumObj?.val;
  const hideLossObj = data?.content?.find(
    (x: any) => x.attr == "hideLossEfficacy"
  );
  const dataListObj = data?.content?.find((x: any) => x.attr == "dataList");
  const dataList = dataListObj?.val;
  const ids: any[] = [];
  dataList?.length > 0 &&
    dataList.map((item: any) => {
      ids.push(item.id);
    });
  const { val: layout } = data?.style.find(
    (item: any) => item.attr === "layout"
  );
  const customStyle = configCustomStyleCoupon(data);
  const hideLossEfficacy = hideLossObj?.val;
  return {
    title,
    showTitle,
    showMore,
    showNum,
    ids,
    hideLossEfficacy,
    layout,
    customStyle,
  };
};
// 资讯
export const formatInfoData = (data: any) => {
  const { val: showTitle } = data?.content.find(
    (item: any) => item.attr === "showTitle"
  );
  const { val: title } = data?.content.find(
    (item: any) => item.attr === "title"
  );
  const { val: showNum } = data?.content.find(
    (item: any) => item.attr === "showNum"
  );
  const { val: showMore } = data?.content.find(
    (item: any) => item.attr === "showMore"
  );
  const { val: info } = data?.content.find(
    (item: any) => item.attr === "infoClass"
  );
  const customStyle = getStyleByTeamplate(data.style || []);

  return Object.assign({
    showTitle,
    title,
    showNum,
    showMore,
    tabList: info,
    customStyle,
  });
};
// 商品
export const formatGoodsData = (data: any) => {
  let goodsData = [];
  const { val: showTitle } = data?.content.find(
    (item: any) => item.attr === "showTitle"
  );
  const { val: title } = data?.content.find(
    (item: any) => item.attr === "title"
  );
  const { val: showMore } = data?.content.find(
    (item: any) => item.attr === "showMore"
  );
  const { val: checkType } = data?.content.find(
    (item: any) => item.attr === "checkType"
  );
  const { val: showNum } = data?.content.find(
    (item: any) => item.attr === "showNum"
  );
  const { val: showByProject } = data?.content.find(
    (item: any) => item.attr === "showByProject"
  );
  const { val: fields } = data?.content.find(
    (item: any) => item.attr === "fields"
  );
  if (checkType == "0") {
    const { val: goodsList } = data?.content.find(
      (item: any) => item.attr === "tableList"
    );
    goodsData = goodsList;
  } else {
    const { val: goodsList } = data?.content.find(
      (item: any) => item.attr === "dataList"
    );
    goodsData = goodsList;
  }
  const { val: mode } = data?.style.find((item: any) => item.attr === "layout");
  const customStyle = getStyleByTeamplate(data.style || []);
  return Object.assign({
    showTitle,
    title,
    showMore,
    goodsData,
    showNum,
    showByProject,
    fields,
    mode,
    customStyle,
  });
};

// 直播列表
export const formatLiveListData = (data: any) => {
  const { val: showTitle } = data?.content.find(
    (item: any) => item.attr === "showTitle"
  );
  const { val: title } = data?.content.find(
    (item: any) => item.attr === "title"
  );
  const { val: showMore } = data?.content.find(
    (item: any) => item.attr === "showMore"
  );
  const { val: list } = data?.content.find(
    (item: any) => item.attr === "dataList"
  );
  const { val: showNum } = data?.content.find(
    (item: any) => item.attr === "showNum"
  );
  const { val: showStatus } = data?.content.find(
    (item: any) => item.attr === "status"
  );
  const { val: layout } = data?.style.find(
    (item: any) => item.attr === "layout"
  );
  const customStyle = getStyleByTeamplate(data.style || []);
  return {
    showMore,
    showTitle,
    showNum,
    list,
    title,
    layout,
    showStatus,
    customStyle,
  };
};
//学习引导
export const formatStudyGuide = (data: any) => {
  const { val: showTitle } = data?.content.find(
    (item: any) => item.attr === "showTitle"
  );
  const { val: title } = data?.content.find(
    (item: any) => item.attr === "title"
  );
  const { val: showMore } = data?.content.find(
    (item: any) => item.attr === "showMore"
  );
  const { val: showItem } = data?.content.find(
    (item: any) => item.attr === "showItem"
  );
  const { val: showBefore } = data?.content.find(
    (item: any) => item.attr === "showBefore"
  );
  const { val: goods } = data?.content.find(
    (item: any) => item.addType === "goods"
  );

  const customStyle = getStyleByTeamplate(data.style || []);
  return {
    showMore,
    showTitle,
    showItem,
    showBefore,
    title,
    goods,
    customStyle,
  };
};
// 正在直播
export const formatLiveIngData = (data: any) => {
  const { val: showTitle } = data?.content.find(
    (item: any) => item.attr === "showTitle"
  );
  const { val: title } = data?.content.find(
    (item: any) => item.attr === "title"
  );
  const { val: list } = data?.content.find(
    (item: any) => item.attr === "dataList"
  );
  const customStyle = getStyleByTeamplate(data.style || []);
  return {
    showTitle,
    list,
    title,
    customStyle,
  };
};

export const configCustomStyleCoupon = (data: any) => {
  const { val: bgVal } = data.style?.find(
    (item: any) => item.attr === "background-color"
  );
  const { val: pt } = data.style?.find(
    (item: any) => item.attr === "padding-top"
  );
  const { val: pb } = data.style?.find(
    (item: any) => item.attr === "padding-bottom"
  );
  const { val: ftColor } = data.style?.find(
    (item: any) => item.attr === "color"
  );
  const { val: bjUrl } = data.style?.find(
    (item: any) => item.attr === "background-image"
  );

  return {
    backgroundColor: bgVal,
    paddingTop: pt,
    paddingBottom: pb,
    color: ftColor,
    backgroundImage: `url(${bjUrl})`,
  };
};

const k_v: any = {
  "padding-top": "paddingTop",
  "padding-right": "paddingRight",
  "padding-bottom": "paddingBottom",
  "padding-left": "paddingLeft",
  "background-color": "backgroundColor",
  "background-image": "backgroundImage",
  color: "color",
};
/**
 * 根据模板获取样式
 * @returns
 */
export const getStyleByTeamplate = (temp: any[]) => {
  const styles: any = {};
  temp.forEach((item) => {
    const key = k_v[item.attr];
    if (key == "backgroundImage") {
      styles[key] = `url(${item.val})`;
    } else if (key) {
      styles[key] = item.val;
    }
  });
  return styles;
};
