import { request } from "@/utils/request";

// 支付订单
export function postPaymentOrder(data: any) {
  return request({
    url: `/trade/v2/payment/request`,
    method: "POST",
    data,
  });
}

// 获取订单状态
export function getOrderStatus(params: any) {
  return request({
    url: `/trade/order/status`,
    method: "GET",
    params,
  });
}

// 获取订单详情
export function fetchOrderDetail(params: any) {
  return request({
    url: `/trade/order/detail`,
    method: "GET",
    params,
  });
}

// 获取订单详情v2
export function fetchOrderDetailV2(params: any) {
  return request({
    url: `/trade/v2/order/detail`,
    method: "GET",
    params,
  });
}
