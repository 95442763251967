/* eslint-disable  */
// import React, { useState, useEffect } from "react";
// import { useNavigate } from "react-router-dom";
import { Col, Row } from "antd";
import OrderDeatailItem from "./OrderDeatailItem";
import "../css/index.scss";

function OrderInfo(props: any) {
  const {
    infoData,
    contract,
    signStatus,
    fileUrl,
    callback,
    clearGroupAndSeckill = false,
  } = props; //课程商品状态 0-待付款 1-已付款 2-已关闭 3-退款中 4-已退款 5-拼团中
  // const navigate = useNavigate();
  // console.log(infoData, "infoData");

  // 签署协议
  const goSign = () => {
    if (signStatus == 0) {
      callback();
    } else {
      window.open(fileUrl);
    }
    // navigate(
    //   `/Agreement?pdfurl=${
    //     fileUrl ? fileUrl : contract.url
    //   }&orderContractId=${recordId}&isOrder=false&goodsId=${commodityId}`
    // );
  };
  return (
    <div className="order-info-box">
      <div className="order-info-title">订单信息</div>
      <div className="order-info-content">
        <div className="order-info-table">
          {infoData && infoData.length > 0 ? (
            <Row className="order-info-header">
              <Col span={12}>商品</Col>
              <Col span={4}>原价</Col>
              <Col span={4}>现价</Col>
              <Col span={4}>数量</Col>
            </Row>
          ) : null}
          {infoData && infoData.length > 0
            ? infoData.map((commodityInfo: any, index: number) => {
                return (
                  <OrderDeatailItem
                    commodityInfo={commodityInfo}
                    key={index}
                    clearGroupAndSeckill={clearGroupAndSeckill}
                  ></OrderDeatailItem>
                );
              })
            : null}
          {contract &&
          contract?.id &&
          (contract?.signTime == 1 || contract?.signTime == 2) ? (
            <div className="contract-box">
              <div>
                <div className="constrct-all">
                  <div className="constract-name">《产品协议》: </div>
                  <div className="constract-btn-box" onClick={() => goSign()}>
                    <div className="status-text">
                      {signStatus === 0 ? "未签署" : "已签署"}
                    </div>
                    <div className="iconfont icon-arrow1_1 icon-symbol_right consract-icon"></div>
                  </div>
                </div>
              </div>
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
}

export default OrderInfo;
