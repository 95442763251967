/* eslint-disable  */
import React, {
  useState,
  useEffect,
  forwardRef,
  useImperativeHandle,
} from "react";

import "../css/activityList.scss";

const ActivityList = forwardRef((props: any, ref: any) => {
  const { info, getCurGiftActivity, addBuyInfo = {} } = props;
  const [curGiftActivityList, setCurGiftActivityList] = useState<any[]>([]);
  // 自身选中
  const clickGift = (item: any) => {
    let temp: any[] = JSON.parse(JSON.stringify(curGiftActivityList));
    if (temp.length > 0) {
      if (JSON.stringify(temp).includes(JSON.stringify(item))) {
        temp = temp.filter((child) => {
          return JSON.stringify(child) != JSON.stringify(item);
        });
      } else {
        temp.push(item);
      }
    } else {
      temp.push(item);
    }
    setCurGiftActivityList(temp);
    getCurGiftActivity(temp);
  };
  // 父级选中传入
  const propSelect = (list: any) => {
    setCurGiftActivityList(list || []);
  };
  // 清空选择
  const clearSelect = () => {
    setCurGiftActivityList([]);
  };
  // 清空选择中的不叠加的赠品活动
  const clearSelectOnly = () => {
    let result: any[] = [];
    let temp = JSON.parse(JSON.stringify(curGiftActivityList));
    if (temp.length > 0) {
      temp.map((item: any) => {
        if (item.overlayScreen == 1) {
          result.push(item);
        }
      });
    }
    setCurGiftActivityList(result);
  };
  useImperativeHandle(ref, () => ({
    clearSelect,
    clearSelectOnly,
    propSelect,
  }));
  return (
    <div className="activityList-box-orderSettlement">
      <div className="activityList-box-title">优惠活动</div>
      <div className="activityList-box-content flex">
        {info?.length > 0 &&
          info.map((item: any, index: number) => {
            return (
              <div
                className={
                  JSON.stringify(curGiftActivityList).includes(
                    JSON.stringify(item)
                  )
                    ? "activityList-one activityList-one-active"
                    : "activityList-one activityList-one-on-active"
                }
                onClick={() => clickGift(item)}
                key={index}
              >
                <div className="activityList-one-left">赠品</div>
                <div className="activityList-one-right">
                  <div className="activityList-one-name">{item.name}</div>
                  <div className="activityList-one-reason">
                    {item.overlayScreen == 0
                      ? "不可与拼团/秒杀/加价购/优惠券叠加"
                      : addBuyInfo?.overlayScreen == 0
                      ? "不可与加价购活动叠加"
                      : null}
                  </div>
                </div>
                {JSON.stringify(curGiftActivityList).includes(
                  JSON.stringify(item)
                ) ? (
                  <div className="check-coupn-icon iconfont icon-exclude "></div>
                ) : null}
              </div>
            );
          })}
      </div>
    </div>
  );
});

export default ActivityList;
