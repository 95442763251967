import { request } from "@/utils/request";

//根据id数组查优惠券详情数组
export const getCouponDeatilList = (data: any) => {
  return request({
    url: `/util/coupon/list/ids`,
    method: "POST",
    data,
  });
};
export const getCouponRange = (data: any) => {
  return request({
    url: `/util/coupon/page/range/channel`,
    method: "POST",
    data,
  });
};

// 根据优惠券状态查询列表
export const getCouponListByStatus = (data: any) => {
  return request({
    url: `/util/coupon/received/records`,
    method: "POST",
    data,
  });
};
