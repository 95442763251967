import React, { ReactNode } from "react";
import { Layout, Flex } from "antd";
// import LayoutFooter from "@/pages/Layout/Footer";
// import LayoutHeader from "@/pages/Layout/Header";
import LayoutHeader from "@/components/Header";
import LayoutFooter from "@/components/Footer";
import UserStore from "@/store/user";
import './css/layout.scss'; 
const { Header, Footer, Sider, Content } = Layout;

const headerStyle: React.CSSProperties = {
  color: "#4D5055",
  height: 98,
  backgroundColor: "#ffffff",
};

// const contentStyle: React.CSSProperties = {
//   width: "100%",
//   margin: "0 auto",
//   borderTop: "1px solid #E5E6EB",

// };

const footerStyle: React.CSSProperties = {
  margin: "0 auto",
  padding: 0,
  // backgroundColor: '#1F2833',
  background:'transparent'
};

const layoutStyle = {
  borderRadius: 8,
  backgroundColor: "#ffffff",
  minHeight:'100vh'
};

interface MainLayoutProps {
  children: ReactNode;
  styles?: any;
}

function MainLayout({ children, styles = {} }: MainLayoutProps) {
  return (
    <Layout style={layoutStyle}>
      <Header style={headerStyle}>
        <LayoutHeader data={UserStore.getHeaderComp().headerInfo} />
      </Header>
      <Content className="contentStyle" style={{ ...styles }}>{children}</Content>
      <Footer style={footerStyle}>
        <LayoutFooter data={UserStore.getFooterComp().footerInfo} />
      </Footer>
    </Layout>
    // 
  );
}

export default MainLayout;
