import React, { useEffect, useState } from "react";
import { observer } from "mobx-react";
import { useNavigate } from "react-router-dom";
import "@/components/ClassifyNavigation/css/topnav.scss";
import userStore from "@/store/user";
import { Modal } from "antd";
import Login from "@/components/Login"; // 登录
import { handleBread } from "@/utils/bread";
// import { fetchUserInfo } from "@/apis/user";
import { fetchChannelDetail } from "@/apis/user";
import { fetchClassifyExpansion, getHomeAdvert } from "@/apis/coursesort";
import SortExpansion from "@/components/ClassifyNavigation/SortExpansion";
import Advertisement from "@/components/ClassifyNavigation/Advertisement";
import LoginAlready from "@/components/ClassifyNavigation/LoginAlready";
import LoginWelcome from "@/components/ClassifyNavigation/LoginWelcome";
function TopNav(props: any) {
  const {
    layout,
    classifyStyle,
    autoCarousel,
    bannerList,
    color,
    backgroundColor,
    backgroundImage,
    paddingStyle,
  } = props.data;
  console.log(props.data, "props.dataprops.dataprops.data");

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [appName, setAppName] = useState("");
  const [adImg, setAdImg] = useState<any>([]); // 广告位信息
  //layout: 0-分类+轮播 1-仅轮播 2-分类+轮播+学习导航
  //classifyStyle: 0-收起二级 1-收起一级
  //autoCarousel: true自动轮播
  const style = {
    backgroundColor: backgroundColor,
    backgroundImage,
    color: color,
  };
  // 获取广告位信息
  const getAdInfo = async () => {
    const imgurl = await getHomeAdvert();
    console.log(imgurl);
    setAdImg(imgurl);
  };

  useEffect(() => {
    getAdInfo();
  }, []);

  useEffect(() => {
    const fetchName = async () => {
      try {
        const response = await fetchChannelDetail();
        if (response && response.name) {
          setAppName(response.name);
        }
      } catch (error) {
        console.error("获取失败", error);
      }
    };
    fetchName();
  }, []);
  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };
  // 点击后打开登录弹窗
  function handleToLogin() {
    setIsModalOpen(true);
  }

  const navigate = useNavigate();
  // 获取首页的项目展开的数据
  const [projectData, setProjectData] = useState<any>([]);
  const getClassifyExpansion = async () => {
    const result = await fetchClassifyExpansion({});
    // 处理第二层数据
    if (result?.length > 0) {
      result.forEach((item: any) => {
        if (item.children?.length > 0) {
          item.childrenLevel2 = item.children.filter((x: any) => {
            return x?.type == 0;
          });
        }
        if (item?.childrenLevel2?.length == 0) {
          item.projectLevel2 = item.children.filter((x: any) => {
            return x?.type == 1;
          });
        }
      });
    }
    setProjectData(result);
  };
  //修改其他
  const processedData = projectData.map((category: any) => {
    // 找出所有直接子项（type: 1），这些子项没有自己的 children
    const otherItems = category.children.filter(
      (child: any) => child.type === 1
    );
    if (otherItems.length > 0) {
      return {
        ...category,
        otherItems: otherItems,
      };
    }
    return category;
  });

  useEffect(() => {
    getClassifyExpansion();
  }, []);

  // 跳转到对应的一级标签
  const goTofirst = (item: any) => {
    console.log(item.key);
    handleBread({
      title: item.value,
      href: `/allCourse/1/${item.key}`,
    });
    navigate(`/allCourse/1/${item.key}`);
  };
  // 跳转到对应的二级标签
  const goToSecond = (item: any) => {
    handleBread({
      title: item.value,
      href: `/allCourse/2/${item.key}`,
    });
    navigate(`/allCourse/2/${item.key}`);
  };
  // 跳转到对应的项目
  const goToItem = (item: any) => {
    // console.log(key);
    handleBread({
      title: item.value,
      href: `/allCourse/3/${item.key}`,
    });
    navigate(`/allCourse/3/${item.key}`);
  };
  const renderLayout = (val: string) => {
    switch (val) {
      case "0":
        return (
          <div className="topnav-list" style={paddingStyle}>
            <div className="top-center-nav">
              <SortExpansion
                projectData={projectData || []}
                classifyStyle={classifyStyle}
                goTofirst={goTofirst}
                goToSecond={goToSecond}
                goToItem={goToItem}
                color={color}
                width="918px"
              />
              <Advertisement
                adImg={bannerList}
                width="910px"
                autoCarousel={autoCarousel}
              />
            </div>
          </div>
        );
      case "1":
        return (
          <div className="topnav-list" style={paddingStyle}>
            <div className="top-center-nav">
              <Advertisement
                adImg={bannerList}
                width="1200px"
                autoCarousel={autoCarousel}
              />
            </div>
          </div>
        );
      case "2":
        return (
          <div className="topnav-list" style={paddingStyle}>
            <div className="top-center-nav">
              <SortExpansion
                projectData={projectData || []}
                classifyStyle={classifyStyle}
                goTofirst={goTofirst}
                goToSecond={goToSecond}
                goToItem={goToItem}
                color={color}
                width="656px"
              />
              <Advertisement
                adImg={bannerList}
                width="646px"
                autoCarousel={autoCarousel}
              />
            </div>
            {userStore.getToken() ? (
              <LoginAlready />
            ) : (
              <LoginWelcome
                appName={appName || ""}
                handleToLogin={handleToLogin}
              />
            )}
          </div>
        );
      default:
        return (
          <div className="topnav-list" style={paddingStyle}>
            <div className="top-center-nav">
              <SortExpansion
                projectData={projectData || []}
                classifyStyle={classifyStyle}
                goTofirst={goTofirst}
                goToSecond={goToSecond}
                goToItem={goToItem}
                color={color}
                width="918px"
              />
              <Advertisement
                adImg={bannerList}
                width="910px"
                autoCarousel={autoCarousel}
              />
            </div>
          </div>
        );
    }
  };

  return (
    <div className="topnav-wrap" style={style}>
      {renderLayout(layout)}
      <Modal
        title=""
        className="header-modal-wrap"
        centered
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={null}
      >
        <Login />
      </Modal>
    </div>
  );
}

export default observer(TopNav);
