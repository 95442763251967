/*
 * @Author: liuweixing
 * @Date: 2024-05-13 15:43:04
 * @Description:
 */
import { useState, useEffect } from "react";
import { Pagination } from "antd";
import Breadcrumb from "@/components/BreadCrumb";
import AllCouponItem from "./comps/AllCouponItem";
import { useLocation, useNavigate } from "react-router-dom";
import { getCouponDeatilList } from "@/apis/coupon";
import { receiveCoupon } from "@/apis/courseDetail";
import { message } from "antd";
import "./css/index.scss";
function AllCoupon(props: any) {
  //   const navigate = useNavigate();
  const {
    state: { ids, hideLossEfficacy },
  } = useLocation();
  const navigate = useNavigate();
  const [current, setcurrent] = useState(1); //当前页码
  const [total, setTotal] = useState(0); //总页面数
  const [cardList, setCardList] = useState<any[]>([]);
  const [showData, setShowData] = useState<any[]>([]); // 实际展示数据
  const [messageApi, contextHolder] = message.useMessage();
  useEffect(() => {
    getCouponList(ids);
    console.log(ids, "idsssssss");
  }, [ids]);

  //page当前的页码
  const changePage = (page: number) => {
    setcurrent(page);
    getCouponList(page);
  };
  //获取优惠券列表
  const getCouponList = async (ids: any) => {
    const res = await getCouponDeatilList({
      couponIds: ids,
    });
    setCardList(res);
    // setcurrent(res.page.current);
    // setTotal(res.page.total);
  };
  // 处理展示的数据
  useEffect(() => {
    if (cardList?.length > 0) {
      let result: any[] = [];
      if (hideLossEfficacy) {
        const temp =
          cardList.filter((x) => x.viewStatus == 0 || x.viewStatus == 1) || [];
        temp?.length > 0 &&
          temp.map((item) => {
            result.push(item);
          });
      } else {
        cardList.map((item) => {
          result.push(item);
        });
      }
      setShowData(result);
    } else {
      setShowData([]);
    }
  }, [cardList, hideLossEfficacy]);
  const handlerClick = async (
    id: string,
    viewStatus: any,
    usableRange: any
  ) => {
    if (viewStatus == 1) {
      console.log("去使用");
      navigate(`/allCourseCoupon/${usableRange}/${id}`);
    } else {
      console.log("领取-俩了", id);
      const res = await receiveCoupon(id);
      if (res) {
        success();
        getCouponList(ids);
      }
    }
  };
  const success = () => {
    messageApi.open({
      type: "success",
      content: "领取优惠券成功",
    });
  };
  return (
    <div className="allcoupon-box">
      <Breadcrumb />
      {/* 全部课程 */}
      <div className="allcoupon-content">
        <div className="allcoupon-all">
          <div className="allcoupon-top">
            <div className="allcoupon-name">全部优惠券</div>
            <div className="allcoupon-total">
              共找到{showData?.length || 0}+结果
            </div>
          </div>
          <div className="allcoupon-list">
            <div className="allcoupon-list-content">
              {showData.length > 0 &&
                showData.map((item, index) => {
                  return (
                    <AllCouponItem
                      data={item}
                      index={index}
                      handlerClick={handlerClick}
                    />
                  );
                })}
            </div>
          </div>
          <div className="pagination-box">
            <Pagination
              defaultCurrent={1}
              total={total}
              current={current}
              pageSize={12}
              onChange={changePage}
              hideOnSinglePage={true}
            />
          </div>
          {contextHolder}
        </div>
      </div>

      {/* 底部栏 */}
    </div>
  );
}
export default function AllCouponPage() {
  return <AllCoupon></AllCoupon>;
}
