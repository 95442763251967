import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Empty } from "antd";
import "../css/MyAgreement.scss";
import { Tabs, Pagination } from "antd";
import { getCouponListByStatus } from "@/apis/coupon";
import CouponItem from "./CouponItem";
const MyOrder: React.FC = () => {
  const [tabs] = useState([
    { name: "未使用", key: 1 },
    { name: "已使用", key: 2 },
    { name: "已过期", key: 3 },
  ]);
  const [activeTab, setActiveTab] = useState(1);
  const [pageIndex, setPageIndex] = useState(0);
  const [pageRow] = useState(10);
  const [pageTotal, setPageTotal] = useState(0);
  const [tabData, setTabData] = useState<any[]>([]);
  const navigate = useNavigate();
  const changeTab = (id: any) => {
    setPageIndex(0);
    setActiveTab(id);
  };
  const onChange = (index: number) => {
    setPageIndex(index);
  };
  useEffect(() => {
    const fetchTabs = async () => {
      const ret = await getCouponListByStatus({
        index: pageIndex,
        row: pageRow,
        table: Number(activeTab),
      });
      setTabData(ret.data || []);
      if (pageTotal !== ret?.total) {
        setPageTotal(ret?.total);
      }
    };
    fetchTabs();
  }, [activeTab, pageIndex, pageRow, pageTotal]);

  const handlerClick = async (
    id: string,
    userStatus: any,
    usableRange: any
  ) => {
    if (userStatus == 0) {
      if (usableRange == 1) {
        navigate(`/allCourseCoupon/${usableRange}/${id}`);
      } else {
        navigate("/");
      }
    }
  };

  return (
    <div className="coupon-my-content">
      <div className="coupon-tab-list">
        <Tabs
          tabPosition={"top"}
          style={{ height: 220 }}
          indicatorSize={(origin) => origin - 16}
          items={tabs.map((item: any) => {
            return {
              label: `${item.name}`,
              key: `${item.key}`,
            };
          })}
          onChange={changeTab}
        />
      </div>
      <div className="coupon-my-list">
        {tabData && tabData.length > 0 ? (
          tabData.map((data: any, index: number) => {
            return (
              <CouponItem
                data={data}
                index={index}
                handlerClick={handlerClick}
                couponStatus={activeTab}
              />
            );
          })
        ) : (
          <div className="empty-box-coupon-my">
            <Empty
              image={
                <img src={require("../images/empty-coupon1.png")} alt=""></img>
              }
              imageStyle={{ height: 172 }}
              description={<span style={{ color: "#9FA1A4" }}>暂无优惠券</span>}
            ></Empty>
          </div>
        )}
      </div>
      {tabData && tabData.length > 0 ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            paddingBottom: "12px",
          }}
        >
          <Pagination
            onChange={onChange}
            total={pageTotal}
            showSizeChanger={false}
            pageSize={pageRow}
            current={pageIndex}
            hideOnSinglePage={true}
          />
        </div>
      ) : null}
    </div>
  );
};

export default MyOrder;
