import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { getStudyFollowNumber } from "@/apis/courseDetail";
import { message } from "antd";
import { skuOne } from "./type";
import "../css/SelectSkuList.scss";
import { Modal } from "antd";
import Login from "@/components/Login"; // 登录
import userStore from "@/store/user";

function SelectSkuList(props: any) {
  const {
    id,
    activeSku,
    courseData,
    originalOrderId = "",
    originalActivityId = "",
  } = props;
  const {
    thumbnail,
    name,
    skuOptions,
    activityType,
    isLimit,
    limitCount,
    skuList,
  } = courseData;
  const [studyNumber, setstudyNumber] = useState(0); // 已学人数
  const [selectList, setSelectList] = useState<skuOne[]>([]); // 清单列表
  const [selectPrice, setSelectPrice] = useState<number>(0); //已选价格
  const [selectLinePrice, setSelectLinePrice] = useState<number>(0); //已选价格
  const [selectNum, setSelectNum] = useState<number>(0); //已选件数
  const [messageApi, contextHolder] = message.useMessage();
  const navigate = useNavigate();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };
  // 获取关注人数
  const getStudyNumber = async (commodityId: string) => {
    const res = await getStudyFollowNumber(commodityId);
    setstudyNumber(res.salesVolume || 0);
  };
  useEffect(() => {
    if (id) {
      getStudyNumber(id);
    }
  }, [id]);
  const addList = () => {
    if (!userStore.getToken()) {
      setIsModalOpen(true);
    } else {
      const checkResult = checkData();
      if (!checkResult) {
        return;
      }
      // 拼接规格名
      let skuName = "";
      const tempSkuCode = activeSku.skuCode.split(":");
      skuOptions.map((item: any, index: number) => {
        tempSkuCode.map((child: number, idx: number) => {
          if (index == idx) {
            skuName = skuName + item.values[child] + " ";
          }
        });
      });
      let result = { ...activeSku, thumbnail, name, skuName, number: 1 };
      const tempArr: any = JSON.parse(JSON.stringify(selectList));
      // 判断当前规格是否已在表格中
      let flag = false;
      if (tempArr.length > 0) {
        tempArr.forEach((item: skuOne) => {
          if (item.skuCode == result.skuCode) {
            if (activityType == 3 && item.number == item.stock) {
              flag = true;
              showError("已达最大值,不可增加");
              return;
            } else {
              item.number++;
              flag = true;
            }
          }
        });
      }
      if (!flag) {
        tempArr.push(result);
      }
      setSelectList(tempArr);
    }
  };

  // 效验数据
  const checkData = () => {
    if (!activeSku || activeSku?.stock == 0) {
      showError("请先选择规格");
      return false;
    }
    // if (isLimit && limitCount && selectNum == limitCount) {
    //   showError("已达最大值,不可增加");
    //   return false;
    // }
    if (isLimit) {
      showError("该商品已达限购值,无法购买");
      return false;
    }
    const noSellOut = skuList.find((item: skuOne) => item?.stock > 0);
    if (!noSellOut) {
      showError("该商品已售罄");
      return false;
    }
    return true;
  };
  // 立即结算
  const handleSettlement = () => {
    if (!userStore.getToken()) {
      setIsModalOpen(true);
    } else {
      // 当直接点击立即结算
      if (selectList?.length == 0) {
        // 商品已达限购值
        if (isLimit) {
          showError("该商品已达限购值,无法购买");
          return;
        }
        // 全部规格已经售罄
        const noSellOut = skuList.find((item: skuOne) => item?.stock > 0);
        if (!noSellOut) {
          showError("该商品已售罄");
          return false;
        }
        // 当前规格售罄
        if (activeSku?.stock == 0) {
          showError("请选择规格");
          return;
        }
        // 拼接规格名
        let skuName = "";
        const tempSkuCode = activeSku.skuCode.split(":");
        skuOptions.map((item: any, index: number) => {
          tempSkuCode.map((child: number, idx: number) => {
            if (index == idx) {
              skuName = skuName + item.values[child] + " ";
            }
          });
        });
        let result = { ...activeSku, thumbnail, name, skuName, number: 1 };
        navigate("/orderSettlement", {
          state: {
            selectList: [result],
            id,
            contract: courseData?.contract || {},
            activityId: courseData?.activityId || "",
            originalOrderId: originalOrderId || "",
            originalActivityId: originalActivityId || "",
          },
        });
      } else {
        navigate("/orderSettlement", {
          state: {
            selectList,
            id,
            contract: courseData?.contract || {},
            activityId: courseData?.activityId || "",
            originalOrderId: originalOrderId || "",
            originalActivityId: originalActivityId || "",
          },
        });
      }
    }
  };
  // 添加个数
  const handleAdd = (curVal: any) => {
    const tempArr: skuOne[] = JSON.parse(JSON.stringify(selectList));
    tempArr.forEach((item: skuOne) => {
      if (item.skuCode == curVal.skuCode) {
        if (item.number == item.stock || isLimit) {
          showError("已达最大值,不可增加");
          return;
        } else {
          item.number++;
        }
      }
    });
    setSelectList(tempArr);
  };
  // 减少个数
  const handleReduce = (curVal: any) => {
    const tempArr: skuOne[] = JSON.parse(JSON.stringify(selectList));
    tempArr.forEach((item: skuOne) => {
      if (item.skuCode == curVal.skuCode) {
        item.number--;
      }
    });
    const result: skuOne[] = tempArr.filter((item: skuOne) => {
      return item.number > 0;
    });
    setSelectList(result);
  };
  // 处理统计数据
  const handleALLData = (val: skuOne[]) => {
    let num = 0,
      price = 0,
      linePrice = 0;
    val.map((item: skuOne) => {
      num = num + item.number;
      price = Number(
        (
          Number(price.toFixed(2)) +
          Number((item.price * item.number).toFixed(2))
        ).toFixed(2)
      );
      linePrice = Number(
        Number(
          Number(linePrice.toFixed(2)) +
            Number((item.linePrice * item.number).toFixed(2))
        ).toFixed(2)
      );
    });
    setSelectNum(num);
    setSelectPrice(price);
    setSelectLinePrice(linePrice);
  };
  // 提示已达限购次数
  const showError = (msg: string) => {
    messageApi.open({
      type: "warning",
      content: msg,
    });
  };
  useEffect(() => {
    selectList.length > 0 && handleALLData(selectList);
  }, [selectList]);
  return (
    <div className="sku-list-select">
      {selectList.length === 0 ? (
        <div className="price-box">
          <div className="price-box-left-box">
            {courseData?.activityType == 7 || courseData?.activityType == 8 ? (
              activeSku?.differencePrice == 1 ? (
                <div className="add-level-text-box-0">
                  <div className="add-level-text">
                    {courseData?.activityType == 7 ? "补差升购" : "补差换购"}
                  </div>
                  <div className="add-level-text-num">
                    您已购买 ￥ {courseData?.orderPayAmount}
                  </div>
                </div>
              ) : (
                <div className="add-level-text-box-1">
                  {courseData?.activityType == 7 ? "升购价" : "换购价"}
                </div>
              )
            ) : null}

            <div
              className={
                courseData?.activityType == 7 || courseData?.activityType == 8
                  ? "price-box-left-activty"
                  : "price-box-left"
              }
            >
              <div className="price-real">
                <span className="price-icon">￥</span>
                {activeSku
                  ? activeSku?.stock != 0
                    ? activeSku?.price
                    : "XX"
                  : "XX"}
              </div>
              <div className="price-old">
                ￥
                {activeSku
                  ? activeSku?.stock != 0
                    ? activeSku?.linePrice
                    : "XX"
                  : "XX"}
              </div>
            </div>
          </div>
          <div className="price-box-right">
            <div className="interest-box">
              {/* <span style={{ color: "#FE4E47" }}>{courseData?.salesVolume}</span> */}
              <span style={{ color: "#FE4E47" }}>
                {studyNumber > 9999 ? "9999+" : studyNumber}
              </span>
              人已关注
            </div>
            {courseData?.activityType == 7 ||
            courseData?.activityType == 8 ? null : (
              <div className="add-list-btn" onClick={addList}>
                加入清单
              </div>
            )}
            <div
              className={
                courseData?.activityType == 7 || courseData?.activityType == 8
                  ? "buy-btn-level"
                  : "buy-btn"
              }
              onClick={handleSettlement}
            >
              立即结算
            </div>
          </div>
        </div>
      ) : (
        <div className="list-box">
          <div className="list-title">清单明细</div>
          <div className="list-content">
            {selectList.map((item: skuOne, index: number) => {
              return (
                <div
                  key={index}
                  className={
                    index != selectList.length - 1
                      ? "list-card-one list-card-border"
                      : "list-card-one"
                  }
                >
                  <img className="list-card-img" src={item.thumbnail} />
                  <div className="list-card-name-box">
                    <div className="list-card-name">{item.name}</div>
                    <div className="list-card-sku-name">{item.skuName}</div>
                  </div>
                  <div className="list-card-price-box">
                    <div className="list-card-price">￥{item.price}</div>
                    <div className="list-card-linePrice">
                      ￥{item.linePrice}
                    </div>
                  </div>
                  <div className="list-card-number-box">
                    <div
                      className="list-card-reduce"
                      onClick={() => handleReduce(item)}
                    >
                      -
                    </div>
                    <div className="list-card-number">{item.number}</div>
                    <div
                      className={
                        item?.number == item?.stock || isLimit
                          ? "list-card-add-dis"
                          : "list-card-add"
                      }
                      onClick={() => handleAdd(item)}
                    >
                      +
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
          <div className="list-price">
            <div className="price-box-select">
              <div className="price-box-left-select">
                <div className="price-name-select">合计:</div>
                <div className="price-real-select">
                  <span className="price-icon-select">￥</span>
                  {selectPrice}
                </div>
                <div className="price-old-select">￥{selectLinePrice}</div>
                <div className="number-select-text">
                  已选<span className="number-select-val">{selectNum}</span>件
                </div>
              </div>
              <div className="price-box-right">
                <div className="interest-box">
                  <span style={{ color: "#FE4E47" }}>{studyNumber}</span>
                  人已关注
                </div>
                <div className="add-list-btn" onClick={addList}>
                  加入清单
                </div>
                <div className="buy-btn" onClick={handleSettlement}>
                  立即结算
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {contextHolder}
      <Modal
        title=""
        className="header-modal-wrap"
        centered
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={null}
      >
        <Login />
      </Modal>
    </div>
  );
}

export default SelectSkuList;
