import React, { useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import "../css/SignAgreement.scss";
import { Col, Row, Form, Input, Upload, message } from "antd";
import { postSignAgreement, idCardRecognize } from "@/apis/agreement";
import { useSearchParams } from "react-router-dom";

const SignAgreement = () => {
  const { state } = useLocation();
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const [isUploaded, setIsUploaded] = useState(false);
  const [isRecognized, setIsRecognized] = useState(false);
  const [idCardFile, setIdCardFile] = useState<any>(null);
  const [isSubmitDisabled, setIsSubmitDisabled] = useState(true); // 控制提交按钮的禁用状态
  const [searchParams] = useSearchParams();

  const orderContractId = searchParams.get("orderContractId");
  const goodsId = searchParams.get("goodsId");
  const isOrder = searchParams.get("isOrder");
  const redirectUrl = searchParams.get("redirectUrl");
  // 上传后更改图片显示
  const handleUploadChange = async (info: any) => {
    if (info.file.status === "uploading") {
      setIsUploaded(true);
      setIsRecognized(false);
    } else if (info.file.status === "done") {
      setIsUploaded(true);
      // 创建 FormData 对象
      const formData = new FormData();
      formData.append("file", info.file.originFileObj);
      try {
        const response = await idCardRecognize(formData);
        console.log(response, "@@@");
        if (response) {
          console.log("response=1000");
          // 从响应中提取 name 和 idNumber
          const { name, idNumber } = response;
          if (name && idNumber) {
            // 确保姓名和身份证号都存在
            form.setFieldsValue({
              userRealName: name,
              idCardNo: idNumber,
            });
            setIsSubmitDisabled(false);
            setIsRecognized(true); // 设置识别成功状态
          }
        }
      } catch (error) {
        console.error(error);
        console.log("上传失败");
        setIsRecognized(false); // 设置识别失败状态
      }
    } else if (info.file.status === "removed") {
      setIsUploaded(false);
      // 清除表单的值
      form.resetFields(["userRealName", "idCardNo"]);
      setIsSubmitDisabled(true);
      setIsRecognized(false); // 重置识别状态
    }
  };

  // 保存图片url
  const beforeUpload = (file: any) => {
    setIdCardFile(file);
  };

  const handleSubmit = async (values: any) => {
    const submitData = {
      orderContractIds: orderContractId,
      userRealName: values.userRealName,
      idCardNo: values.idCardNo,
      frontIdCard: idCardFile, // 使用保存的文件信息
    };

    try {
      const response = await postSignAgreement(submitData);
      console.log(response);
      message.success("提交成功");

      const url = `/signFinish/?orderContractId=${orderContractId}&goodsId=${goodsId}&isOrder=${isOrder}&redirectUrl=${redirectUrl}`;
      navigate(url, {
        state,
      });
    } catch (error) {
      message.error("提交失败");
    }
  };

  const handleFormChange = () => {
    const fields = form.getFieldsValue();
    if (fields.userRealName && fields.idCardNo) {
      setIsSubmitDisabled(false);
    } else {
      setIsSubmitDisabled(true);
    }
  };

  return (
    <Row className="background-container" align={"middle"}>
      <Col span={14}></Col>
      <Col span={8}>
        <Form
          form={form}
          className="form-container"
          onFinish={handleSubmit}
          onValuesChange={handleFormChange}
        >
          <Form.Item
            label="真实姓名"
            name="userRealName"
            rules={[
              {
                whitespace: true, // 禁止只输入空格
                message: "真实姓名不能只包含空格",
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="身份证号"
            name="idCardNo"
            rules={[
              { message: "请输入身份证号" },
              { pattern: /^\d{17}(\d|X|x)?$/, message: "请输入正确的身份证号" },
            ]}
          >
            <Input maxLength={18} />
          </Form.Item>
          <div className="secret-line">
            <img
              className="secret-icon"
              src="https://resource.wangxiao.net/images/tick1.png"
              alt=""
            />
            <span className="secret-text">
              &nbsp;仅用于签署商品协议，平台依据隐私政策保护您的信息安全
            </span>
          </div>
          <Upload
            name="idCard"
            accept="image/png, image/jpeg"
            action={process.env.REACT_APP_BASE_URL + "/api/upload/idCard"}
            className="upload-container"
            listType="picture-card"
            beforeUpload={beforeUpload} // 捕获上传的文件
            onChange={handleUploadChange}
          >
            {!isUploaded && (
              <img
                className="upload-img"
                src="https://resource.wangxiao.net/pc/images/idCardUpload.png"
                alt=""
              />
            )}
          </Upload>
          <div
            className="success-text"
            style={{ display: isUploaded && isRecognized ? "block" : "none" }}
          >
            <span className="iconfont icon-select_circular_1_2"></span>
            <span className="font_5">识别成功</span>
          </div>
          <span className="hint-text">支持选取图片</span>
          <button className="submit-button" disabled={isSubmitDisabled}>
            提交
          </button>
        </Form>
      </Col>
      <Col span={2}></Col>
    </Row>
  );
};
export default SignAgreement;
