import { useState, useEffect } from "react";
import { getInfoList } from "@/apis/information";
import BreadCrumb from "@/components/BreadCrumb";
import AllInfo from "@/components/AllInformation";
import { handleBread } from "@/utils/bread";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import "./css/index.scss";
function AllInformation() {
  const navigate = useNavigate();
  const {
    state: { tabList },
  } = useLocation();
  const [data, setData] = useState([]);
  const [listIndex, setListIndex] = useState(0);
  const [currentId, setCurrentId] = useState<any>();
  const [current, setcurrent] = useState(0);
  const [total, setTotal] = useState(0);
  const changeTab = (id: any) => {
    setData([]);
    setListIndex(0);
    getInfoListbyCategory(id, 0);
    setCurrentId(id);
  };
  const getInfoListbyCategory = async (id: any, index: number) => {
    const res = await getInfoList({
      classifyId: id,
      index,
      row: 10,
    });
    setData(res.body);
    // setData([]);
    setcurrent(res.page.current);
    setTotal(res.page.total);
  };
  const changePages = (current: number) => {
    getInfoListbyCategory(currentId, current - 1);
  };
  const goInfoDetail = (id: any) => {
    handleBread({ title: "资讯详情", href: "/infoDetail" });
    navigate("/infoDetail", { state: { id: id } });
  };
  useEffect(() => {
    const getInfoTab = async () => {
      // setCurrentId(res.body[0].key);
      getInfoListbyCategory(tabList[0].id, 0);
    };
    getInfoTab();
  }, []);
  return (
    <div className="information-content-outwrap">
      <BreadCrumb />
      {/* 内容 */}
      <div className="content">
        <AllInfo
          tabList={tabList}
          infoList={data}
          changeTab={changeTab}
          changePages={changePages}
          current={current}
          total={total}
          goInfoDetail={goInfoDetail}
        />
      </div>
    </div>
  );
}
export default AllInformation;
