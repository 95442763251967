
const enum sessionKey  {
    unit_productName='unit_productName',
    unit_productId='unit_productId',
    unit_name='unit_name',
    unit_id='unit_id',
}
// 当前播放单元本地存储
export const getPlayUnit = ()=>{
    const productName = window.sessionStorage.getItem(sessionKey.unit_productName);
    const productId = window.sessionStorage.getItem(sessionKey.unit_productId);
    const unitName = window.sessionStorage.getItem(sessionKey.unit_name);
    const unitId = window.sessionStorage.getItem(sessionKey.unit_id);
    return {productName,productId,unitName,unitId}
}

export const setPlayUnit = (productName:string,productId:string,unitName:string,unitId:string) =>{
    window.sessionStorage.setItem(sessionKey.unit_productName,productName)
    window.sessionStorage.setItem(sessionKey.unit_productId,productId)
    window.sessionStorage.setItem(sessionKey.unit_name,unitName)
    window.sessionStorage.setItem(sessionKey.unit_id,unitId)
}
